import React, { ChangeEvent } from "react";
import {
  EssentialsBlock,
  EssentialsWrapper,
  FullNameBlockWrapper,
  SwitchWrapper,
  SwitchText,
  GapFlexBlock,
} from "../styles";
import { FormikErrors, FormikTouched } from "formik";
import SwitchComponent from "../../../Inputs/CustomSwitch/Switch";
import { IProfile } from "../../../../models/Widgets";

type PermissionsProps = {
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleBlur: React.ChangeEventHandler;
  values: IProfile;
  errors: {
    [key: string]:
      | string
      | FormikErrors<any>
      | string[]
      | FormikErrors<any>[]
      | undefined;
  };
  touched: {
    [key: string]:
      | boolean
      | FormikTouched<any>
      | FormikTouched<any>[]
      | undefined;
  };
};

export const Permissions: React.FC<PermissionsProps> = ({
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
}) => {
  return (
    <EssentialsBlock>
      <EssentialsWrapper>
        <FullNameBlockWrapper>
          <GapFlexBlock>
            <SwitchWrapper>
              <SwitchText>Is this user an Administrator?</SwitchText>
              <SwitchComponent
                name={"isAdmin"}
                checked={values.isAdmin}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.isAdmin as string}
                touched={!!touched.isAdmin}
                labelSide="left"
                disableMargin
                label={values.isAdmin ? "Yes" : "No"}
              />
            </SwitchWrapper>
            <SwitchWrapper>
              <SwitchText>Can Add Projects?</SwitchText>
              <SwitchComponent
                name={"canAddProjects"}
                checked={values.canAddProjects}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.canAddProjects as string}
                touched={!!touched.canAddProjects}
                labelSide="left"
                disableMargin
                label={values.canAddProjects ? "Yes" : "No"}
              />
            </SwitchWrapper>
            <SwitchWrapper>
              <SwitchText>
                Give Automatic Access to AII Future Projects?
              </SwitchText>
              <SwitchComponent
                name={"autoAccessToFutureProjects"}
                checked={values.autoAccessToFutureProjects}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.autoAccessToFutureProjects as string}
                touched={!!touched.autoAccessToFutureProjects}
                labelSide="left"
                disableMargin
                label={values.autoAccessToFutureProjects ? "Yes" : "No"}
              />
            </SwitchWrapper>
          </GapFlexBlock>
        </FullNameBlockWrapper>
      </EssentialsWrapper>
    </EssentialsBlock>
  );
};
