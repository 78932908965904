// sagas/zillow.ts

import { put, call, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { ServerResponse } from "../../models";
import { PropertyQueryParams } from "../../models/RealEstate";
import { fetchProperty } from "../../api/realEstateZillow";
import { setPropertyDetails } from "../slices/realEstateSlice";

export function* handleFetchPropertyDetails(action: PayloadAction<PropertyQueryParams>) {
  const { response }: ServerResponse = yield call(
    fetchProperty,
    action.payload
  );
  
  if (response?.status === 200) {
    yield put(setPropertyDetails(response.data));
  } else {
    const errorMessage = "Failed to fetch property details";
    toast.error(errorMessage);
  }
}

export function* watchZillowActions() {
  yield takeLatest("realEstateZillow/requestProperty", handleFetchPropertyDetails);
}