import React from "react";

interface IColumnClick {
  header: string,
  event: React.MouseEvent,
  key: string,
  errorsDetected: boolean,
  subtype?: string,
  suggestedType?: string,
}

export const handleColumnClick = ({header,event,key,errorsDetected,subtype,suggestedType} : IColumnClick) => {
  const rect = event.currentTarget.getBoundingClientRect();
  const top = rect.bottom;
  const left = rect.left;
  subtype = subtype ? subtype : "integer"
  return { header, top, left, suggestedType, key, errorsDetected, subtype };
};
