import styled from "styled-components";
import { Images, GeoAlt, Trash3 } from "react-bootstrap-icons";

export const Container = styled.div`
  width: 100%;
  /* padding: 24px 20px 0 20px; */
`

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 24px 20px 0 20px;

`

export const HeaderText = styled.p`
  color: #000417;

  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
`

export const Buttons = styled.div`
  display: flex;
  gap: 8px;
`

export const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

export const DataList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const ImagesIcon = styled(Images)`
  width: 16px;
  height: 16px;
`

export const DataListHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 8px 0;
  color: #5F6877;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  padding: 0 20px 0 20px;
` 

export const DataListRowName = styled.p`
  color: #000417;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
`

export const DataListRowButtons = styled.div`
  display: flex;
  gap: 4px;
`

export const PlaceButton = styled.div`
  display: flex;
  gap: 8px;
  color: #5D63F6;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  cursor: pointer;
  user-select: none;
`

export const LocationIcon = styled(GeoAlt)`
  width: 16px;
  height: 16px;
  fill: #5D63F6;
`

export const TrashIcon = styled(Trash3)`
  width: 16px;
  height: 16px;
  display: none;
  cursor: pointer;
`

export const DataListRow = styled.div<{
  $active?: boolean;
}>`
  border-right: 2px solid transparent;
  position: relative;
  padding: 12px 20px 12px 44px;
  width: 100%;
  display: flex;
  align-items: center;
  transition: 0.3s;
  justify-content: space-between;
  &::after {
    content: '';
    position: absolute;
    left: 27px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 8px solid gray;
    transform: rotateZ(90deg)
  }

  &:hover {
    ${({$active}) => !$active && `
      background-color: #9FA4AD1F;
    `}
    ${TrashIcon} {
      display: block;
    }
  }

  ${({$active, theme}) => $active && `
    background: ${theme.primaryBackgroundActive};
    border-right: 2px solid ${theme.primaryBorder};
  `}
`