import React, { useEffect, useMemo, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import { ModalOverlay } from "../styles";
import {
  UpdateModalContentWrapper,
  UpdateModalHeaderWrapper,
  UpdateModalWrapper,
  AccountBlock,
  AccountAvatar,
  AccountFullName,
  UpdateTab,
  UpdateTabs,
  UpdateModalFooterWrapper,
  CloseProfileSettingsIcon,
} from "./styles";
import { AccountDto, ChangePasswordDTO } from "../../../models/Authentication";
import { getUser } from "../../../store/selectors/main";
import { Button } from "../../Button";
import { CheckCircle } from "react-bootstrap-icons";
import { Essentials } from "./Essentials";
import { ContactDetails } from "./ContactDetails";
import { Security } from "./Security";
import { Permissions } from "./Permissions";
import { Notifications } from "./Notifications";
import { getProfiles } from "../../../store/selectors/profiles";
import { IProfile } from "../../../models/Widgets";
import { requestUpdateProfile } from "../../../store/slices/profiles";
import _ from "lodash";
import { getModalFirstItem } from "../../../store/selectors/modals";
import { requestChangePassword } from "../../../store/slices/auth";

export const profileValidationSchema = () => {
  return Yup.object().shape({
    firstName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Please enter your First Name")
      .matches(/^[a-zA-Z]+$/, "First Name should only contain letters"),

    lastName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Please enter your Last Name")
      .matches(/^[a-zA-Z]+$/, "Last Name should only contain letters"),

    jobTitle: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Please enter your Job Title")
      .matches(/^[a-zA-Z]+$/, "Job Title should only contain letters"),

    organization: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Please enter your Organization"),

    location: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Please enter your Location"),
  });
};

const getInitialTab = (firstItem: number): string => {
  switch (firstItem) {
    case 3:
      return "security";
    case 5:
      return "notifications";
    default:
      return "essentials";
  }
};

export const UpdateProfileModal = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const currentProfile = useSelector(getProfiles);
  const firstItem = useSelector(getModalFirstItem);
  const [account, setAccount] = useState<AccountDto | undefined>();
  const [selectedTab, setTab] = useState<string>(getInitialTab(firstItem));
  const [changePassword, setChangePassword] = useState<ChangePasswordDTO>({
    currentPassword: "",
    newPassword: "",
  });
  const disabledPassChange = !(
    !!changePassword.currentPassword && !!changePassword.newPassword
  );

  useEffect(() => {
    if (user?.accessToken) {
      const account: AccountDto = jwtDecode(user?.accessToken);
      setAccount(account);
    }
  }, [user]);

  const fullName = useMemo(() => {
    const name = [currentProfile.firstName, currentProfile.lastName]
      ?.join(" ")
      ?.trim();
    if (name?.length) {
      return name;
    }
    return "Robert Robertson";
  }, [currentProfile?.firstName, currentProfile?.lastName]);

  const initials = useMemo(() => {
    let initials = currentProfile?.email?.charAt(0);
    if (currentProfile?.firstName?.length || currentProfile?.lastName?.length) {
      initials =
        (currentProfile?.firstName?.charAt(0) || "") +
        (currentProfile?.lastName?.charAt(0) || "");
    }
    return initials?.toUpperCase();
  }, [
    currentProfile?.email,
    currentProfile?.firstName,
    currentProfile?.lastName,
  ]);

  const [changePass, setChangePass] = useState<boolean>(false);

  const handleSubmitForm = (values: IProfile) => {
    dispatch(
      requestUpdateProfile({
        ...values,
        callbacks: {
          onSuccess: () => {
            onClose();
          },
        },
      })
    );
  };

  return (
    <ModalOverlay onClick={onClose}>
      <UpdateModalWrapper onClick={(e) => e.stopPropagation()}>
        <UpdateModalHeaderWrapper>
          <AccountBlock>
            <AccountAvatar>{initials}</AccountAvatar>
            <AccountFullName>{fullName}</AccountFullName>
            <CloseProfileSettingsIcon onClick={onClose} />
          </AccountBlock>
          <UpdateTabs>
            <UpdateTab
              $selected={selectedTab === "essentials"}
              onClick={() => setTab("essentials")}
            >
              Essentials
            </UpdateTab>
            <UpdateTab
              $selected={selectedTab === "contact_details"}
              onClick={() => setTab("contact_details")}
            >
              Contact details
            </UpdateTab>
            <UpdateTab
              $selected={selectedTab === "security"}
              onClick={() => setTab("security")}
            >
              Security
            </UpdateTab>
            <UpdateTab
              $selected={selectedTab === "permissions"}
              onClick={() => setTab("permissions")}
            >
              Permissions
            </UpdateTab>
            <UpdateTab
              $selected={selectedTab === "notifications"}
              onClick={() => setTab("notifications")}
            >
              Notifications
            </UpdateTab>
          </UpdateTabs>
        </UpdateModalHeaderWrapper>
        <Formik
          initialValues={currentProfile}
          validationSchema={profileValidationSchema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={handleSubmitForm}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => {
            return (
              <>
                <UpdateModalContentWrapper>
                  <Form onSubmit={handleSubmit}>
                    {selectedTab === "essentials" && (
                      <Essentials
                        values={values}
                        touched={touched}
                        errors={errors}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        initials={initials}
                        avatar={account?.avatar}
                      />
                    )}
                    {selectedTab === "contact_details" && (
                      <ContactDetails
                        values={values}
                        touched={touched}
                        errors={errors}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                      />
                    )}
                    {selectedTab === "security" && (
                      <Security
                        values={changePassword}
                        setValues={setChangePassword}
                        touched={touched}
                        errors={errors}
                        handleBlur={handleBlur}
                        changePass={changePass}
                      />
                    )}
                    {selectedTab === "permissions" && (
                      <Permissions
                        values={values}
                        touched={touched}
                        errors={errors}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />
                    )}
                    {selectedTab === "notifications" && (
                      <Notifications
                        setFieldValue={setFieldValue}
                        values={values}
                        touched={touched}
                        errors={errors}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />
                    )}
                  </Form>
                </UpdateModalContentWrapper>
                <UpdateModalFooterWrapper>
                  <Button
                    name="Cancel"
                    onClick={onClose}
                    variant="neutral"
                    size="medium"
                  />
                  {selectedTab === "security" ? (
                    <Button
                      name="Change Password"
                      onClick={() =>
                        dispatch(
                          requestChangePassword({
                            currentPassword: changePassword.currentPassword,
                            newPassword: changePassword.newPassword,
                            callbacks: {
                              onSuccess: (resp) => {
                                setChangePass(false);
                              },
                              onError() {
                                setChangePass(true);
                              },
                            },
                          })
                        )
                      }
                      disabled={disabledPassChange}
                      variant={disabledPassChange ? "disabled" : "secondary"}
                      size="medium"
                      icon={<CheckCircle />}
                    />
                  ) : (
                    <Button
                      name="Update"
                      onClick={handleSubmit}
                      disabled={_.isEqual(values, currentProfile)}
                      variant={
                        _.isEqual(values, currentProfile)
                          ? "disabled"
                          : "secondary"
                      }
                      size="medium"
                      icon={<CheckCircle />}
                    />
                  )}
                </UpdateModalFooterWrapper>
              </>
            );
          }}
        </Formik>
      </UpdateModalWrapper>
    </ModalOverlay>
  );
};
