import React, { SetStateAction, useRef, useState } from "react";
import { useLocation, useMatch, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Upload, Pencil, Copy, Search, PlusLg } from "react-bootstrap-icons";

import { Menu, Text } from "./styles";
import { Button } from "../Button";
import { setActiveModal } from "../../store/slices/modals";
import { getProjects } from "../../store/selectors/projects";
import { getAllDatasets } from "../../store/selectors/datasets";
import { SortDropDown } from "../SortDropDown";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import { ChevronCompactDown, ChevronCompactUp } from "react-bootstrap-icons";

interface PageMenuInterface {
  sortBy?: any;
  orderBy?: any;
  setSortBy?: SetStateAction<any>;
  setOrderBy?: SetStateAction<any>;
  handleSortChange?: SetStateAction<any>;
}

export const PageMenu = ({
  sortBy,
  setSortBy,
  handleSortChange,
  setOrderBy,
  orderBy,
}: PageMenuInterface) => {
  const { pathname } = useLocation();
  const { items: projects } = useSelector(getProjects);
  const { items: datasets } = useSelector(getAllDatasets);
  const navigate = useNavigate();
  const matchResources = useMatch("/resources/*");
  const dispatch = useDispatch();
  const [showDropDown, setShowDropdown] = useState(false);
  const ref = useRef(null);

  useOutsideClick(ref, () => {
    setShowDropdown(false);
  });

  if (pathname === "/projects")
    return (
      <>
        <Menu ref={ref}>
          <Text onClick={() => setShowDropdown(!showDropDown)}>
            {sortBy === "name" ? "Alphabetical" : "Date created"}
            {showDropDown ? <ChevronCompactUp /> : <ChevronCompactDown />}
          </Text>
          {showDropDown && (
            <SortDropDown
              orderBy={orderBy}
              setOrderBy={setOrderBy}
              setSortBy={setSortBy}
              sortBy={sortBy}
              handleSortChange={handleSortChange}
            />
          )}
          <Button
            name="Upload Dataset"
            onClick={() => {
              dispatch(setActiveModal({ id: "uploadDatasetModal" }));
            }}
            icon={<Upload />}
            variant="primary"
            size="medium"
          />
          {projects?.length ? (
            <Button
              name="Create Project"
              onClick={() => {
                dispatch(setActiveModal({ id: "createProjectModal" }));
              }}
              icon={<Copy />}
              variant="secondary"
              size="medium"
            />
          ) : null}
        </Menu>
      </>
    );

  if (matchResources)
    return (
      <>
        {pathname === "/resources/datasets" && datasets?.length ? (
          <Menu>
            <Button
              name="Upload Data"
              onClick={() =>
                dispatch(setActiveModal({ id: "uploadDatasetModal" }))
              }
              icon={<Upload />}
              variant="primary"
              size="medium"
            />
          </Menu>
        ) : null}
        {pathname === "/resources/public-resources" && datasets?.length ? (
          <Menu>
            <Button
              name="Search Public Data"
              onClick={() => {
                navigate("/resources/public-resources/search");
              }}
              icon={<Search />}
              variant="primary"
              size="medium"
            />
          </Menu>
        ) : null}
        {pathname === "/resources/apis" && datasets?.length ? (
          <Menu>
            <Button
              name="Connect API"
              onClick={() =>
                dispatch(setActiveModal({ id: "connectApiModal" }))
              }
              icon={<PlusLg />}
              variant="primary"
              size="medium"
            />
          </Menu>
        ) : null}
        {/* {pathname === "/resources/files" && datasets?.length ? (
          <Menu>
            <Button
              name="Add files"
              onClick={() => {
                dispatch(setActiveModal({ id: "uploadFilesModal" }));
              }}
              icon={<PlusLg />}
              variant="primary"
              size="medium"
            />
          </Menu>
        ) : null} */}
      </>
    );

  if (pathname === "/profile")
    return (
      <Menu>
        <Button
          name="Update profile"
          onClick={() => {
            dispatch(setActiveModal({ id: "uploadProfileModal" }));
          }}
          icon={<Pencil />}
          variant="secondary"
          size="medium"
        />
      </Menu>
    );
  else return null;
};
