import React from "react";
import {
  DotIcon,
  Dots,
  LoaderWrapper,
  LoadingSpinner,
  SpinnerContainer,
} from "./styles";

type Props = {
  disableAbsolute?: boolean
};

const LoaderDots = (props: Props) => {
  return (
    <LoaderWrapper $disableAbsolute={props.disableAbsolute} $fullScreen={false} $blur={false}>
      <SpinnerContainer  $disableAbsolute={props.disableAbsolute} >
        <LoadingSpinner />
        <Dots>
          <DotIcon $animation={1} />
          <DotIcon $animation={2} />
          <DotIcon $animation={3} />
        </Dots>
      </SpinnerContainer>
    </LoaderWrapper>
  );
};

export default LoaderDots;
