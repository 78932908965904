import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PagesQuery } from "../../api/pages";
import {
  PageSettingsDTO,
  PagesData,
  PagesResponseListDto,
} from "../../models/Pages";
import { ProjectDTO } from "../../models/Projects";
import { NavigateFunction } from "react-router/dist/lib/hooks";
import {
  ICreateKpi,
  IDeleteKpi,
  IUpdateKpi,
  KpiItemsDTO,
  QueryGetKpis,
  QueryGetWidgets,
  WidgetItem,
  WidgetsItemsDTO,
} from "../../models/Widgets";
import {
  ICreateSection,
  ISection,
  IUpdateSection,
  QueryGetSections,
} from "../../api/sections";
import { RequestCallbacks } from "../../models";
import { defaultHeader } from "../../components/AddStorytellingSection";

export const initialSettings = {
  name: "",
  dashType: "",
  projectId: "",
  templateId: "",
  interactivity: [],
  styleId: "",
  showFilters: false,
  showSearch: false,
  showTooltip: false,
  header: defaultHeader,
};

const initialState: PagesResponseListDto = {
  pagesData: {
    items: [],
    isLoading: false,
    count: 0,
    currentPage: {
      settings: initialSettings,
      draftSettings: initialSettings,
      widgets: {
        items: [],
        count: 0,
      },
      kpis: { items: [], count: 0 },
    },
  },
  id: "",
  projectData: { id: "", name: "", description: "", datasets: [], pages: [], projectType: 'basic' },
  widget: undefined,
  yearFilterSelection: []
};

const projectPages = createSlice({
  name: "currentProject",
  initialState,
  reducers: {
    requestAllPages: (
      state: PagesResponseListDto,
      action: PayloadAction<Partial<PagesQuery>>
    ) => {},
    requestAllPublicPages: (
      state: PagesResponseListDto,
      action: PayloadAction<Partial<PagesQuery>>
    ) => {},

    requestCreatePage: (
      state: PagesResponseListDto,
      action: PayloadAction<PageSettingsDTO>
    ) => {},

    requestPageWidgets: (state, action: PayloadAction<QueryGetWidgets>) => {},

    requestPublicPageWidgets: (
      state,
      action: PayloadAction<QueryGetWidgets>
    ) => {},

    updatePagesItems: (
      state: PagesResponseListDto,
      action: PayloadAction<PageSettingsDTO>
    ): PagesResponseListDto => {
      return {
        ...state,
        pagesData: {
          ...state.pagesData,
          items: state.pagesData.items
            ? [...state.pagesData.items, action.payload]
            : [action.payload],
          count: state.pagesData.count + 1,
        },
      };
    },

    resetCurrentPageData: (state) => ({
      ...state,
      pagesData: {
        ...state.pagesData,
        currentPage: initialState.pagesData.currentPage,
      },
    }),

    resetPagesDataItems: (state) => ({
      ...state,
      pagesData: {
        ...state.pagesData,
        items: initialState.pagesData.items,
      },
    }),

    setCurrentProjectId: (
      state: PagesResponseListDto,
      action: PayloadAction<string>
    ): PagesResponseListDto => {
      return {
        ...state,
        id: action.payload,
      };
    },

    setCurrentProjectData: (
      state: PagesResponseListDto,
      action: PayloadAction<ProjectDTO>
    ) => {
      return {
        ...state,
        projectData: action.payload,
      };
    },

    requestPageById: (
      state: PagesResponseListDto,
      action: PayloadAction<{ id: string; callbacks?: RequestCallbacks<any> }>
    ) => {},

    requestPublicPageById: (
      state: PagesResponseListDto,
      action: PayloadAction<{ id: string; callbacks?: RequestCallbacks<any> }>
    ) => {},

    requestRemovePage: (
      state: PagesResponseListDto,
      action: PayloadAction<{ id: string; navigate: NavigateFunction }>
    ) => {},

    requestUpdatePage: (
      state: PagesResponseListDto,
      action: PayloadAction<PageSettingsDTO>
    ) => {},

    setIsLoading: (
      state: PagesResponseListDto,
      action: PayloadAction<boolean>
    ): PagesResponseListDto => {
      return {
        ...state,
        pagesData: {
          ...state.pagesData,
          isLoading: action.payload,
        },
      };
    },

    setAllPages: (
      state: PagesResponseListDto,
      action: PayloadAction<PagesData>
    ): PagesResponseListDto => {
      return {
        ...state,
        pagesData: {
          ...state.pagesData,
          count: action.payload.count,
          items: action.payload.items,
        },
      };
    },

    setPageSettings: (
      state: PagesResponseListDto,
      action: PayloadAction<PageSettingsDTO>
    ): PagesResponseListDto => {
      return {
        ...state,
        pagesData: {
          ...state.pagesData,
          currentPage: {
            ...state.pagesData.currentPage,
            settings: action.payload,
          },
        },
      };
    },

    setDraftPageSettings: (
      state: PagesResponseListDto,
      action: PayloadAction<PageSettingsDTO>
    ): PagesResponseListDto => {
      return {
        ...state,
        pagesData: {
          ...state.pagesData,
          currentPage: {
            ...state.pagesData.currentPage,
            draftSettings: action.payload,
          },
        },
      };
    },

    setPageWidgets: (
      state: PagesResponseListDto,
      action: PayloadAction<WidgetsItemsDTO>
    ): PagesResponseListDto => {
      return {
        ...state,
        pagesData: {
          ...state.pagesData,
          currentPage: {
            ...state.pagesData.currentPage,
            widgets: {
              ...state.pagesData.currentPage.widgets,
              ...action.payload,
            },
          },
        },
      };
    },

    setKpiWidgets: (
      state: PagesResponseListDto,
      action: PayloadAction<KpiItemsDTO>
    ): PagesResponseListDto => {
      return {
        ...state,
        pagesData: {
          ...state.pagesData,
          currentPage: {
            ...state.pagesData.currentPage,
            kpis: {
              ...state.pagesData.currentPage.kpis,
              ...action.payload,
            },
          },
        },
      };
    },

    removePage: (
      state: PagesResponseListDto,
      action: PayloadAction<string>
    ): PagesResponseListDto => {
      return {
        ...state,
        pagesData: {
          ...state.pagesData,
          items: state.pagesData.items?.filter(
            (item) => item.id !== action.payload
          ),
          count: state.pagesData.count - 1,
        },
      };
    },

    resetPages: (state: PagesResponseListDto): PagesResponseListDto => {
      return {
        ...state,
        pagesData: initialState.pagesData,
      };
    },

    resetDraftSettings: (state: PagesResponseListDto): PagesResponseListDto => {
      return {
        ...state,
        pagesData: {
          ...state.pagesData,
          currentPage: {
            ...state.pagesData.currentPage,
            draftSettings: initialSettings,
          },
        },
      };
    },

    updatePageName: (
      state: PagesResponseListDto,
      action: PayloadAction<{ id: string; name: string }>
    ): PagesResponseListDto => {
      return {
        ...state,
        pagesData: {
          ...state.pagesData,
          items: state.pagesData.items.map((item) =>
            item.id === action.payload.id
              ? { ...item, name: action.payload.name }
              : item
          ),
        },
      };
    },

    requestPublicProjectPagesId: (
      state: PagesResponseListDto,
      action: PayloadAction<string>
    ) => {},

    requestPublicProjectPageById: (
      state: PagesResponseListDto,
      action: PayloadAction<{
        id: string;
        pageId: string;
      }>
    ) => {},

    setCurrentWidget: (
      state: PagesResponseListDto,
      action: PayloadAction<WidgetItem | undefined>
    ) => {
      return {
        ...state,
        widget: action.payload,
      };
    },
    requestPageSections: (state, action: PayloadAction<QueryGetSections>) => {},
    requestPublicPageSections: (state, action: PayloadAction<QueryGetSections>) => {},
    requestCreatePageSections: (
      state,
      action: PayloadAction<ICreateSection>
    ) => {},
    requestUpdatePageSections: (
      state,
      action: PayloadAction<IUpdateSection>
    ) => {},
    requestDeletePageSections: (
      state,
      action: PayloadAction<{
        id: string;
        callbacks?: RequestCallbacks<ISection>;
      }>
    ) => {},
    resetPageSettings: (state: PagesResponseListDto): PagesResponseListDto => {
      return {
        ...state,
        pagesData: {
          ...state.pagesData,
          currentPage: {
            ...state.pagesData.currentPage,
            settings: initialSettings,
          },
        },
      };
    },
    requestPageKpis: (state, action: PayloadAction<QueryGetKpis>) => {},
    requestCreateKpis: (state, action: PayloadAction<ICreateKpi>) => {},
    requestUpdateKpis: (state, action: PayloadAction<IUpdateKpi>) => {},
    requestDeleteKpis: (state, action: PayloadAction<IDeleteKpi>) => {},
    setYearFilterSelection: (state, action) => {
      state.yearFilterSelection = action.payload;
    },
    requestUpdatePageInBackgound: (
      state: PagesResponseListDto,
      action: PayloadAction<PageSettingsDTO>
    ) => {},
  },
});

export const {
  requestAllPages,
  requestCreatePage,
  requestPageById,
  requestRemovePage,
  requestPageWidgets,
  requestPublicPageById,
  requestPublicPageWidgets,
  requestAllPublicPages,
  setIsLoading,
  setAllPages,
  setPageSettings,
  resetPages,
  removePage,
  requestUpdatePage,
  updatePageName,
  setCurrentProjectId,
  setCurrentProjectData,
  resetDraftSettings,
  setDraftPageSettings,
  updatePagesItems,
  setPageWidgets,
  setKpiWidgets,
  requestPublicProjectPagesId,
  requestPublicProjectPageById,
  resetCurrentPageData,
  resetPagesDataItems,
  setCurrentWidget,
  requestPageSections,
  requestPublicPageSections,
  requestCreatePageSections,
  requestUpdatePageSections,
  requestDeletePageSections,
  resetPageSettings,
  requestPageKpis,
  requestCreateKpis,
  requestUpdateKpis,
  requestDeleteKpis,
  setYearFilterSelection,
  requestUpdatePageInBackgound,
} = projectPages.actions;
export default projectPages.reducer;
