import { ReactNode, useCallback, useEffect, useState } from "react";
import { ArrowLeft, ArrowRight } from "react-bootstrap-icons";
import {
  ArrowButton,
  CarouselContainer,
  MainImage,
  MainSelectionContainer,
  Thumbnail,
  ThumbnailsContainer,
} from "./styles";

interface Item {
  image: string;
  component: ReactNode;
}

interface Props {
  items: Item[];
}

const Carousel = ({ items }: Props) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleThumbnailClick = (index: number): void => {
    setCurrentIndex(index);
  };

  const handlePrevClick = useCallback((): void => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? items.length - 1 : prevIndex - 1
    );
  }, [items.length]);

  const handleNextClick = useCallback((): void => {
    setCurrentIndex((prevIndex) =>
      prevIndex === items.length - 1 ? 0 : prevIndex + 1
    );
  }, [items.length]);

  // Navigation with arrow keys
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent): void => {
      if (event.key === "ArrowLeft") {
        handlePrevClick();
      } else if (event.key === "ArrowRight") {
        handleNextClick();
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handlePrevClick, handleNextClick]);

  return (
    <CarouselContainer>
      <MainSelectionContainer>
        <MainImage>{items[currentIndex]?.["component"]}</MainImage>
        <ArrowButton direction="left" onClick={handlePrevClick}>
          <ArrowLeft />
        </ArrowButton>
        <ArrowButton direction="right" onClick={handleNextClick}>
          <ArrowRight />
        </ArrowButton>
      </MainSelectionContainer>
      <ThumbnailsContainer>
        {items.map((item, index) => (
          <Thumbnail
            key={index}
            src={item.image}
            alt={`Thumbnail ${index + 1}`}
            selected={index === currentIndex}
            onClick={() => handleThumbnailClick(index)}
          />
        ))}
      </ThumbnailsContainer>
    </CarouselContainer>
  );
};

export default Carousel;
