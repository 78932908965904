import styled, { css } from "styled-components";
import { ReactComponent as radioButtonActive } from "../../assets/radioButtonActive.svg";
import { ReactComponent as radioButtonInactive } from "../../assets/radioButtonInactive.svg";
import { ReactComponent as hoveredRadioButtonInactive } from "../../assets/hoveredRadioButton.svg";

const labelStyles = css`
  font-size: 12px;
  font-weight: 400;
  color: #2E3849;
`;

export const DimensionSettingsWrapper = styled.div<{
  $top: number;
  $left: number;
}>`
  position: absolute;
  top: ${({ $top }) => `${$top - 50}px `};
  left: ${({ $left }) => `${$left- 180}px`};
  width: 207px;
  background: white;
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  border-radius: 6px;
  box-shadow: rgba(99, 99, 99, 0.2) 0 0 10px 0;
  padding: 8px 0;
  z-index: 1;
`;

export const ActionsPage = styled.div`
  display: flex;
  flex-direction: column;
`

export const EditPage = styled.div`
  display: flex;
  flex-direction: column;
`

export const BackButton = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 4px 12px;
  gap:4px;
  color: #5D63F6;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  svg {
    fill: #5D63F6;
  }
`

export const ActionButton = styled.div<{
  $active?: boolean;
}>`
  padding: 4px 12px;
  color: ${({ theme }) => theme.neutralTextWeak};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  transition: 0.3s;
  cursor: pointer;
  svg {
    width: 16px;
    height:16px;
  }

  ${({$active, theme}) => $active && `
    background: #6F6FE929;
    color: ${theme.neutralText};
  ` }

  &:active {
    background: #6F6FE929;
    color: ${({theme}) => theme.neutralText};
  }

  &:hover {
      color: ${({ theme }) => theme.neutralText};

      svg {
        filter: brightness(0) saturate(100%) invert(5%) sepia(16%)
          saturate(5773%) hue-rotate(203deg) brightness(92%) contrast(89%);
      }
    }
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: #DFE1E4;
  margin: 5px 0;
`



export const DimensionSettingsHeading = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.neutralText};
`;

export const DimensionSettingsInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 4px 12px;
`;

export const DimensionSettingsInputLabel = styled.div`
  ${labelStyles}
`;

export const DimensionSettingsInput = styled.input`
  width: 100%;
  height: 32px;
  padding: 5px 12px;
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  border-radius: 50px;
  outline: none;
  font-size: 12px;
  background-color: rgba(255, 255, 255, 1);
`;

export const DimensionSettingsMLWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 183px;
  padding: 8px;
  background-color: ${({ theme }) => theme.neutralBackground};
  border-radius: 6px;
`;

export const DimensionSettingsMLHeading = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.neutralText};
`;

export const DimensionSettingsTypeWrapper = styled.div`
  padding: 4px 12px ;
`;

export const DimensionSettingsTypeLabel = styled.div`
  ${labelStyles}
`;

export const DimensionSettingsIconsItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 8px;
`;

export const ActiveRadioButton = styled(radioButtonActive)``;
export const InactiveRadioButton = styled(radioButtonInactive)`
  display: block;
`;
export const HoveredRadioButton = styled(hoveredRadioButtonInactive)`
  display: none;
`;
export const RadioButtons = styled.div``;

export const DimensionSettingsIconsItemWrapper = styled.div<{
  $selected: boolean;
}>`
  font-size: 12px;
  font-weight: ${({ $selected }) => ($selected ? 600 : 400)};
  color: ${({ theme, $selected }) =>
    $selected ? theme.neutralText : theme.neutralTextDisabled};
  display: flex;
  gap: 8px;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.neutralText};
    ${InactiveRadioButton} {
      display: none;
    }
    ${HoveredRadioButton} {
      display: block;
    }
  }
`;

export const Label = styled.div`
  flex: auto;
`;
export const IconWrapper = styled.div``;

export const DimensionSettingsFooterWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 12px;
`;
