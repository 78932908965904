import { useState } from 'react';
import HouseFeature from "./components/Feature";
import { FFContainer, FFContent, FFLine, FFSubtitle, FFTitle } from "./styles";
import { Property } from '../../../../models/RealEstate';

interface FeatureItem {
  label: string;
  number?: number;
}

interface FeatureGroup {
  title: string;
  items: FeatureItem[];
}

interface Section {
  title: string;
  features: FeatureGroup[];
}

interface Props {
  data: Property | null;
  className?: string;
  collapsible?: boolean;
  initiallyExpanded?: boolean;
}

const FactsAndFeatures = ({ 
  data,
  className,
  collapsible = false,
  initiallyExpanded = true
}: Props) => { 
  const sections: Section[] = data ? [
    {
      title: "Interior",
      features: [
        {
          title: "Spaces",
          items: [
            {
              label: "Bedrooms",
              number: data.bedrooms || 0
            },
            {
              label: "Bathrooms",
              number: data.bathrooms || 0
            },
            {
              label: "Living Area",
              number: data.livingArea || 0
            }
          ].filter(item => item.number > 0) // Only show if has value
        },
        {
          title: "Other",
          items: [
            ...(data.resoFacts?.hasFireplace ? [{ label: "Has a fireplace" }] : []),
            ...(data.resoFacts?.hasPrivatePool ? [{ label: "Has a pool" }] : []),
            ...(data.resoFacts?.cooling?.length ? [{ label: `Cooling: ${data.resoFacts.cooling.join(", ")}` }] : []),
            ...(data.resoFacts?.heating?.length ? [{ label: `Heating: ${data.resoFacts.heating.join(", ")}` }] : []),
            ...(data.resoFacts?.flooring?.length ? [{ label: `Flooring: ${data.resoFacts.flooring.join(", ")}` }] : [])
          ]
        }
      ].filter(feature => feature.items.length > 0) // Only show groups with items
    },
    {
      title: "Exterior",
      features: [
        {
          title: "Building",
          items: [
            {
              label: "Year Built",
              number: data.yearBuilt
            },
            {
              label: "Lot Size",
              number: data.lotSize
            },
            ...(data.resoFacts?.exteriorFeatures?.map((feature: string) => ({
              label: feature
            })) || [])
          ].filter(item => item.label)
        }
      ].filter(feature => feature.items.length > 0)
    },
    {
      title: "Parking",
      features: [
        {
          title: "Parking Features",
          items: [
            ...(data.resoFacts?.parkingFeatures?.map((feature: string) => ({
              label: feature
            })) || [])
          ]
        }
      ].filter(feature => feature.items.length > 0)
    }
  ].filter(section => section.features.length > 0) : []

  const [expandedSections, setExpandedSections] = useState<Record<string, boolean>>(
    sections.reduce((acc, section) => ({
      ...acc,
      [section.title]: initiallyExpanded
    }), {})
  );

  const toggleSection = (sectionTitle: string) => {
    if (!collapsible) return;
    
    setExpandedSections(prev => ({
      ...prev,
      [sectionTitle]: !prev[sectionTitle]
    }));
  };

  if (sections.length === 0) {
    return null;
  }

  return (
    <FFContainer className={className}>
      <FFTitle>Facts and features</FFTitle>
      
      {sections.map((section, index) => {
        const isExpanded = expandedSections[section.title];
        const isLastSection = index === sections.length - 1;

        return (
          <div key={`section-${section.title}-${index}`}>
            <FFSubtitle 
              onClick={() => toggleSection(section.title)}
              style={{ 
                cursor: collapsible ? 'pointer' : 'default',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              {section.title}
              {collapsible && (
                <span className="ml-2 text-gray-500">
                  {isExpanded ? '−' : '+'}
                </span>
              )}
            </FFSubtitle>

            {isExpanded && (
              <FFContent>
                {section.features.map((feature, featureIndex) => (
                  <HouseFeature
                    key={`feature-${feature.title}-${featureIndex}`}
                    header={feature.title}
                    items={feature.items}
                  />
                ))}
              </FFContent>
            )}

            {!isLastSection && <FFLine />}
          </div>
        );
      })}
    </FFContainer>
  );
};

export default FactsAndFeatures;