import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { DatasetsList } from "../../../../components/ResourcesPagesComponents/Datasets/DatasetsList";
import { requestGetAllDatasets } from "../../../../store/slices/datasets";
import { Loader } from "../../../../components/Loader";
import { getAllDatasets } from "../../../../store/selectors/datasets";
import { ReactComponent as EmptyIcon } from "../../../../assets/resourcesPagesIcons/DeskLamp.svg";
import { ResourcesNoData } from "../NoData";
import { Upload } from "react-bootstrap-icons";
import { setActiveModal } from "../../../../store/slices/modals";
import { DatasetPreviewPage } from "./pages/DatasetPreview";
import InfiniteScroll from "react-infinite-scroll-component";
import { FileLibraryItem } from "../../../../models/Files";

interface Props {}

export const Datasets: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const allDatasets = useSelector(getAllDatasets);
  const [hasMore, setHasMore] = useState(true);
  const [datasets, setDatasets] = useState<FileLibraryItem[]>([]);
  const [refresh, setRefresh] = useState<number>(0);

  useEffect(() => {
    dispatch(
      requestGetAllDatasets({
        limit,
        skip,
        orderBy: { createdAt: "DESC" },
        callbacks: {
          onSuccess: (data) => {
            const newItems = [...datasets, ...data.items];
            setDatasets(newItems);
            setHasMore(data.count > newItems?.length);
          },
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, refresh]);

  const fetchData = () => {
    setLimit(10);
    setSkip(skip + 10);
    setRefresh(refresh + 1);
  };

  return (
    <>
      {allDatasets.isLoading ? <Loader /> : null}
      {!allDatasets.items.length ? (
        <ResourcesNoData
          Icon={EmptyIcon}
          title="No datasets were discovered"
          subTitle={`Feel free to add data by uploading CSV or XLS files. Just click the "Upload Dataset" button below! `}
          IconButton={Upload}
          name={"Upload Data"}
          onClick={() => {
            dispatch(setActiveModal({ id: "uploadDatasetModal" }));
          }}
        />
      ) : (
        <InfiniteScroll
          dataLength={datasets.length}
          next={fetchData}
          hasMore={hasMore}
          loader={allDatasets.isLoading && <h4>Loading...</h4>}
          endMessage={null}
        >
          <DatasetsList items={datasets} />
        </InfiniteScroll>
      )}
    </>
  );
};

export { Datasets as Index, DatasetPreviewPage };
