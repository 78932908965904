// TODO: Remove this file when the API is integrated
export const CAROUSEL_MOCK = [
  {
    image:
      "https://ralfvanveen.com/wp-content/uploads/2021/06/Placeholder-_-Glossary.svg",
    component: <div>Item 1</div>,
  },
  {
    image:
      "https://contessa-project.eu/wp-content/uploads/2022/05/placeholder.gif",
    component: (
      <img
        style={{ width: "100%" }}
        src="https://images8.alphacoders.com/133/1336966.jpeg"
        alt="placeholder"
      />
    ),
  },
  {
    image:
      "https://contessa-project.eu/wp-content/uploads/2022/05/placeholder.gif",
    component: <div>Item 3</div>,
  },
];

export const FACTS_FEATURES_MOCK_DATA = [
  {
    title: "Interior",
    features: [
      {
        title: "Spaces",
        items: [
          { label: "Number of rooms", number: 4 },
          { label: "Number of bathrooms", number: 2 },
          { label: "Kitchen", number: 1 },
          { label: "Living room", number: 1 },
        ],
      },
      {
        title: "Other",
        items: [
          { label: "Has a fireplace" },
          { label: "Has a pool" },
          { label: "Has a garden" },
        ],
      },
    ],
  },
  {
    title: "Parking",
    features: [
      {
        title: "Parking",
        items: [
          { label: "Number of garages", number: 2 },
          { label: "Number of parking spots", number: 2 },
        ],
      },
    ],
  },
];

export const MOCK_FINANCIAL = [
  {
    label: "Price pee square foot",
    details: "$2,548",
  },
];

export const MOCK_LISTING = [
  {
    label: "Lising Agreement",
    details: "Exclusive Right to Sell",
  },
  {
    label: "Listing terms",
    details: "Cash, Conventional",
  },
];
