import { AxiosError, AxiosResponse } from "axios";
import { ServerResponse } from "../models";
import { PropertyQueryParams } from "../models/RealEstate";
import axios from "./axios";
import { GET_PROPERTY } from "./constants";

export const fetchProperty = async (
  params: PropertyQueryParams
): Promise<ServerResponse> => {
  return axios
    .get(`${GET_PROPERTY}/property`, { params })
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));
};
