import { styled } from "styled-components";

export const HouseDetailsZipCode = styled.span`
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: ${({ theme }) => theme.neutralTextWeeker};
`;

export const HouseDetailsStatus = styled.span`
      font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    padding: 0 4px;
    background-color: #1386601F;
    border-radius: 32px;
    margin-left: 8px;
    color: #003E1B;
`;