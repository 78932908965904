export const paths = [
  {
    title: 'My Datasets',
    path: '/resources/datasets'
  },
  {
    title: 'Public Resources',
    path: '/resources/public-resources'
  },
  {
    title: 'API’s',
    path: '/resources/apis'
  },
  // {
  //   title: 'Files',
  //   path: '/resources/files'
  // }
]
