import React, { ReactNode, useEffect, useState } from "react";
import { Search as SearchIcon } from "react-bootstrap-icons";

import { InputField, SearchBarContainer, IconWrapper } from "./styles";

interface SearchInterface {
  width?: "small" | "medium" | "large" | "xs";
  onChange?: (value: string) => void;
  placeholder?: string;
  icon?: ReactNode;
  onClick?: () => void;
  value?: string;
}

export const Search = ({
  width,
  onChange,
  placeholder,
  icon = <SearchIcon />,
  onClick,
  value,
}: SearchInterface) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setSearchValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  useEffect(() => {
    if (value) {
      setSearchValue(value);
    }
  }, [value]);

  return (
    <SearchBarContainer
      onClick={onClick}
      $width={width}
      $isValue={searchValue?.length > 0}
    >
      <IconWrapper>{icon}</IconWrapper>
      <InputField
        autoComplete="off"
        type="search"
        name="search"
        value={searchValue}
        placeholder={placeholder ? placeholder : "Search..."}
        onChange={handleChange}
      ></InputField>
    </SearchBarContainer>
  );
};
