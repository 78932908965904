import React from "react";
import { NavLinkButton, NavLinksWrapper } from "./styles";
import { paths } from "./constants";

interface Props {}

export const ResourcesTabs: React.FC<Props> = () => {
  return (
    <NavLinksWrapper>
      {paths.map(({ title, path }, index) => {
        return (
          <NavLinkButton key={`${index}_${path}_${title}`} to={path}>
            {title}
          </NavLinkButton>
        );
      })}
    </NavLinksWrapper>
  );
};
