import { put, call, takeLatest } from "redux-saga/effects";
import { ServerResponse } from "../../models";
import { getDataProvider, getDataProviders } from "../../api/dataProviders";
import { setDataProvider, setDataProvidersList } from "../slices/dataProviders";
import { PayloadAction } from "@reduxjs/toolkit";


export function* handleGetDataProviders() {
  const { response }: ServerResponse = yield call(getDataProviders);
  if (response?.status === 200) {
    yield put(setDataProvidersList(response.data));
  }
}
export function* handleGetDataProvider(action: PayloadAction<string>) {
  const { response }: ServerResponse = yield call(getDataProvider, action.payload);
  if (response?.status === 200) {
    yield put(setDataProvider(response.data));
  }
}

export function* watchDataProvidersActions() {
  yield takeLatest("dataProviders/requestGetDataProviders", handleGetDataProviders);
  yield takeLatest("dataProviders/requestGetDataProvider", handleGetDataProvider);
}
