import React, { useEffect, useMemo, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { StoryTelling } from "../../components/Storytelling";
import {
  requestAllPages,
  requestPageById,
  requestPageWidgets,
  requestPublicPageById,
  requestPublicPageWidgets,
  requestAllPublicPages,
} from "../../store/slices/projectPages";
import {
  getAllPages,
  getCurrentPageWidgetsItems,
  getCurrentProjectData,
  getLayout,
  getPageSettings,
} from "../../store/selectors/projects";
import {
  requestCreateWidgets,
  requestWidgetsSuggestions,
} from "../../store/slices/widgets";
import { getIsPublicMode } from "../../store/selectors/main";
import { Layout } from "../../components/ProjectPageLayouts/Layout";
import { LAYOUTS } from "../../components/ProjectPageLayouts/config";
import { findFirstCommonElement } from "../../helpers/firstCommonElement";
import { adjustArray } from "../../helpers/ajustArray";
import {
  getLineChartColors,
  getLineChartMarkers,
} from "../../components/Widgets/LineChart/utils/getLineChartMarkers";
import {
  getLollipopChartColors,
  getLollipopChartMarkers,
} from "../../components/Widgets/Lollipop/utils/getLollipopChartMarkers";
import { getBarChartFormatting } from "../../components/Widgets/GroupedBarChart/utils/getKeys";
import {
  AreaChartDefaultMarkers,
  getAreaChartDefaultColors,
} from "../../components/Widgets/AreaChart/utils/getGroupData";
import { defaultApparitionConfig, defaultNewWidget } from "./constants";
import { extractBlockIds, LayoutI } from "../../helpers/extractBlockIds";
import { Layout5 } from "../../components/ProjectPageLayouts/Layout5";
import { PageSettingsDTO } from "../../models/Pages";
import TablePage from "../../components/TypePages/TablePage";
import {
  CreateAreaChartWidgetDto,
  CreateBarWidgetDto,
  CreateBubbleWidgetDto,
  CreateLineChartWidgetDto,
  CreateLollipopChartWidgetDto,
  CreateMapWidgetDto,
  CreateMatrixChartWidgetDto,
  CreatePolarAreaWidgetDto,
  CreatePunchcardWidgetDto,
  CreateRadarWidgetDto,
  CreateSankeyChartWidgetDto,
  CreateScatterplotWidgetDto,
} from "../../models/Widgets";
import LayoutRealEstate from "../../components/ProjectPageLayouts/LayoutRealEstate";

interface IProjectPage {
  downloadScreenshot?: (ref: React.RefObject<HTMLDivElement>) => void;
}

export const ProjectPage = ({
  downloadScreenshot = () => {},
}: IProjectPage) => {
  const isPublicRoute = useSelector(getIsPublicMode);
  const { id: queryId, pageId: queryPageId } = useParams();

  const dispatch = useDispatch();
  const pages = useSelector(getAllPages);
  const templateId = useSelector(getLayout);
  const widgets = useSelector(getCurrentPageWidgetsItems);
  const navigate = useNavigate();
  const currentPage = useSelector(getPageSettings);
  const { datasets, projectType } = useSelector(getCurrentProjectData);

  const pageItems = useMemo(() => pages?.items, [pages?.items]);
  const pageId = useMemo(() => queryPageId, [queryPageId]);
  const id = useMemo(() => queryId, [queryId]);

  useEffect(() => {
    if (isPublicRoute) {
      dispatch(requestAllPublicPages({ projectId: id }));
    } else {
      dispatch(requestAllPages({ projectId: id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (typeof isPublicRoute === "undefined" || !pageId) {
      return;
    }

    if (isPublicRoute) {
      if (!!pageItems?.find((page) => page.id === pageId)) {
        dispatch(requestPublicPageById({ id: pageId }));
        dispatch(
          requestPublicPageWidgets({ pageId: pageId, includeData: true })
        );
      } else if (pageItems?.length) {
        navigate("/projects/public/" + id + "/" + pageItems[0].id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, pageId, isPublicRoute, pageItems]);

  useEffect(() => {
    if (!isPublicRoute && pageId) {
      dispatch(requestPageById({ id: pageId }));
      dispatch(requestPageWidgets({ pageId, includeData: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, pageId, isPublicRoute]);

  const getPageWidgetsRequest = (page: PageSettingsDTO, suggestions: any) => {
    dispatch(
      requestPageWidgets({
        pageId: page.id!,
        includeData: true,
        callbacks: {
          onSuccess: (widgetItems) => {
            try {
              if (!widgetItems?.count && page.dashType === "storytelling") {
                let barChartRequestData: any[] = [];
                let lineChartRequestData = [];
                let lollipopChartRequestData = [];
                let sankeyChartRequestData = [];
                let mapChartRequestData = [];
                let areaChartRequestData = [];
                let matrixChartRequestData = [];
                let polarAreaChartData = [];
                let punchcardChartData = [];
                let radarChartData = [];
                let bubbleChartData = [];
                let scatterplotChartData = [];

                let suggestionsCopy = [...suggestions];
                let chartTypes = suggestionsCopy.map((r: any) => r.chartType);
                for (
                  let i = 2;
                  i < (suggestions.length > 8 ? 10 : suggestions.length + 2);
                  i++
                ) {
                  const chartType =
                    suggestions[i - 2] && suggestions[i - 2].chartType;
                  if (chartType) {
                    const index = chartTypes.findIndex(
                      (c: string) => c === chartType
                    );
                    if (index !== -1) {
                      chartTypes.splice(index, 1);
                    }
                    const chart = suggestionsCopy?.find(
                      (widget: any) => widget.chartType === chartType
                    );
                    if (chart) {
                      const indexSuggestion = suggestionsCopy?.findIndex(
                        (widget: any) => widget.chartType === chartType
                      );
                      if (indexSuggestion !== -1) {
                        suggestionsCopy?.splice(indexSuggestion, 1);
                      }
                      switch (chart?.chartType) {
                        case "mapChart":
                          mapChartRequestData.push({
                            ...chart,
                            ...{
                              ...defaultNewWidget,
                              apparitionConfig: {
                                ...defaultApparitionConfig,
                                textAlignment: i % 2 !== 0 ? "right" : "left",
                              },
                            },
                            description:
                              chart?.description ||
                              defaultNewWidget.description,
                            insights:
                              chart?.insights || defaultNewWidget.insights,
                            blockId: i.toString(),
                            display: [],
                            arrangeBy: [],
                            datasetId: chart?.layers[0]?.datasetId,
                            styleId: chart.styleId || "default",
                            dataFormat: {},
                            uniqueValues: chart?.uniqueValues ?? {},
                          });
                          break;
                        case "areaChart":
                          areaChartRequestData.push({
                            ...chart,
                            ...{
                              ...defaultNewWidget,
                              apparitionConfig: {
                                ...defaultApparitionConfig,
                                textAlignment: i % 2 !== 0 ? "right" : "left",
                              },
                            },
                            description:
                              chart?.description ||
                              defaultNewWidget.description,
                            insights:
                              chart?.insights || defaultNewWidget.insights,
                            blockId: i?.toString(),
                          });
                          break;
                        case "lineChart":
                          lineChartRequestData.push({
                            ...chart,
                            ...{
                              ...defaultNewWidget,
                              apparitionConfig: {
                                ...defaultApparitionConfig,
                                textAlignment: i % 2 !== 0 ? "right" : "left",
                              },
                            },
                            description:
                              chart?.description ||
                              defaultNewWidget.description,
                            insights:
                              chart?.insights || defaultNewWidget.insights,
                            blockId: i?.toString(),
                          });
                          break;
                        case "barChart":
                          barChartRequestData.push({
                            ...chart,
                            ...{
                              ...defaultNewWidget,
                              apparitionConfig: {
                                ...defaultApparitionConfig,
                                textAlignment: i % 2 !== 0 ? "right" : "left",
                              },
                            },
                            description:
                              chart?.description ||
                              defaultNewWidget.description,
                            insights:
                              chart?.insights || defaultNewWidget.insights,
                            blockId: i?.toString(),
                          });
                          break;
                        case "lollipopChart":
                          lollipopChartRequestData.push({
                            ...chart,
                            ...{
                              ...defaultNewWidget,
                              apparitionConfig: {
                                ...defaultApparitionConfig,
                                textAlignment: i % 2 !== 0 ? "right" : "left",
                              },
                            },
                            description:
                              chart?.description ||
                              defaultNewWidget.description,
                            insights:
                              chart?.insights || defaultNewWidget.insights,
                            blockId: i?.toString(),
                          });
                          break;
                        case "sankeyChart":
                          const arrangeBy = [
                            ...(chart?.groupBy || []),
                            ...(chart?.xAxe || []),
                          ];
                          sankeyChartRequestData.push({
                            ...chart,
                            ...{
                              ...defaultNewWidget,
                              apparitionConfig: {
                                ...defaultApparitionConfig,
                                textAlignment: i % 2 !== 0 ? "right" : "left",
                              },
                            },
                            description:
                              chart?.description ||
                              defaultNewWidget.description,
                            insights:
                              chart?.insights || defaultNewWidget.insights,
                            blockId: i?.toString(),
                            display: chart.display || chart.yAxe || [],
                            arrangeBy:
                              chart?.arrangeBy && chart?.arrangeBy?.length
                                ? chart.arrangeBy
                                : arrangeBy?.filter((r) => !!r),
                            palette: {
                              type: "sequential",
                              colorType: "discrete",
                              domain: ["Year", "Value"],
                              range: ["red", "blue"],
                              reverse: false,
                            },
                          });
                          break;
                        case "matrixChart":
                          matrixChartRequestData.push({
                            ...chart,
                            ...{
                              ...defaultNewWidget,
                              apparitionConfig: {
                                ...defaultApparitionConfig,
                                textAlignment: i % 2 !== 0 ? "right" : "left",
                              },
                            },
                            description:
                              chart?.description ||
                              defaultNewWidget.description,
                            insights:
                              chart?.insights || defaultNewWidget.insights,
                            blockId: i?.toString(),
                            dataFormat: {},
                            palette: {
                              type: "sequential",
                              colorType: "discrete",
                              domain: ["Year", "Value"],
                              range: ["red", "blue"],
                              reverse: false,
                            },
                            xAxe: chart?.xAxe || chart?.arrangeBy || [],
                            yAxe: chart?.yAxe || chart?.display || [],
                          });
                          break;
                        case "polarAreaChart":
                          polarAreaChartData.push({
                            ...chart,
                            ...{
                              ...defaultNewWidget,
                              apparitionConfig: {
                                ...defaultApparitionConfig,
                                textAlignment: i % 2 !== 0 ? "right" : "left",
                              },
                            },
                            description:
                              chart?.description ||
                              defaultNewWidget.description,
                            insights:
                              chart?.insights || defaultNewWidget.insights,
                            blockId: i?.toString(),
                            dataFormat: {},
                            apparitionConfig: {},
                            uniqueValues: chart?.uniqueValues ?? {},
                            xAxe: chart?.xAxe || [],
                            yAxe: chart?.yAxe || [],
                          });
                          break;
                        case "radarChart":
                          radarChartData.push({
                            ...chart,
                            ...{
                              ...defaultNewWidget,
                              apparitionConfig: {
                                ...defaultApparitionConfig,
                                textAlignment: i % 2 !== 0 ? "right" : "left",
                              },
                            },
                            description:
                              chart?.description ||
                              defaultNewWidget.description,
                            insights:
                              chart?.insights || defaultNewWidget.insights,
                            blockId: i?.toString(),
                            dataFormat: {},
                            apparitionConfig: {},
                            uniqueValues: chart?.uniqueValues ?? {},
                            xAxe: chart?.xAxe || [],
                            yAxe: chart?.yAxe || [],
                          });
                          break;
                        case "punchcardChart":
                          punchcardChartData.push({
                            ...chart,
                            ...{
                              ...defaultNewWidget,
                              apparitionConfig: {
                                ...defaultApparitionConfig,
                                textAlignment: i % 2 !== 0 ? "right" : "left",
                              },
                            },
                            description:
                              chart?.description ||
                              defaultNewWidget.description,
                            insights:
                              chart?.insights || defaultNewWidget.insights,
                            blockId: i?.toString(),
                            dataFormat: {},
                            apparitionConfig: {},
                            uniqueValues: chart?.uniqueValues ?? {},
                            xAxe: chart?.xAxe || [],
                            yAxe: chart?.yAxe || [],
                          });
                          break;
                        case "scatterplotChart":
                          scatterplotChartData.push({
                            ...chart,
                            ...{
                              ...defaultNewWidget,
                              apparitionConfig: {
                                ...defaultApparitionConfig,
                                textAlignment: i % 2 !== 0 ? "right" : "left",
                              },
                            },
                            description:
                              chart?.description ||
                              defaultNewWidget.description,
                            insights:
                              chart?.insights || defaultNewWidget.insights,
                            blockId: i?.toString(),
                            dataFormat: {},
                            apparitionConfig: {},
                            uniqueValues: chart?.uniqueValues ?? {},
                            xAxe: chart?.xAxe || [],
                            yAxe: chart?.yAxe || [],
                          });
                          break;
                        case "bubbleChart":
                          bubbleChartData.push({
                            ...chart,
                            ...{
                              ...defaultNewWidget,
                              apparitionConfig: {
                                ...defaultApparitionConfig,
                                textAlignment: i % 2 !== 0 ? "right" : "left",
                              },
                            },
                            description:
                              chart?.description ||
                              defaultNewWidget.description,
                            insights:
                              chart?.insights || defaultNewWidget.insights,
                            blockId: i?.toString(),
                            dataFormat: {},
                            apparitionConfig: {},
                            uniqueValues: chart?.uniqueValues ?? {},
                            xAxe: chart?.xAxe || chart?.arrangeBy || [],
                            yAxe: chart?.yAxe || chart?.display || [],
                          });
                          break;
                        default:
                      }
                    }
                  }
                }

                const existCharts =
                  barChartRequestData?.length +
                  lineChartRequestData?.length +
                  lollipopChartRequestData?.length +
                  mapChartRequestData?.length +
                  sankeyChartRequestData?.length +
                  areaChartRequestData?.length +
                  matrixChartRequestData?.length +
                  polarAreaChartData?.length +
                  radarChartData?.length +
                  scatterplotChartData?.length +
                  punchcardChartData?.length +
                  bubbleChartData?.length;

                if (existCharts > 0) {
                  dispatch(
                    requestCreateWidgets({
                      barChart: barChartRequestData,
                      lineChart: lineChartRequestData,
                      lollipopChart: lollipopChartRequestData,
                      mapChart: mapChartRequestData,
                      sankeyChart: sankeyChartRequestData,
                      areaChart: areaChartRequestData,
                      matrixChart: matrixChartRequestData,
                      radarChart: radarChartData,
                      scatterplotChart: scatterplotChartData,
                      polarAreaChart: polarAreaChartData,
                      punchcardChart: punchcardChartData,
                      bubbleChart: bubbleChartData,
                      pageId,
                    })
                  );
                }
              }
              if (!widgetItems?.count && page?.dashType === "dashboard") {
                const layout = LAYOUTS.find((l) => l.id === templateId);
                let blocks: any = extractBlockIds(layout?.arranging as LayoutI);

                blocks = adjustArray(blocks, layout?.numWidgets || 1);
                let suggestionsCopy = [...suggestions];

                let barChartRequestData: CreateBarWidgetDto[] = [];
                let lineChartRequestData: CreateLineChartWidgetDto[] = [];
                let lollipopChartRequestData: CreateLollipopChartWidgetDto[] =
                  [];
                let sankeyChartRequestData: CreateSankeyChartWidgetDto[] = [];
                let areaChartRequestData: CreateAreaChartWidgetDto[] = [];
                let matrixChartRequestData: CreateMatrixChartWidgetDto[] = [];
                let mapChartRequestData: CreateMapWidgetDto[] = [];
                let scatterplotChartRequestData: CreateScatterplotWidgetDto[] =
                  [];
                let radarChartRequestData: CreateRadarWidgetDto[] = [];
                let punchcardChartRequestData: CreatePunchcardWidgetDto[] = [];
                let polarAreaChartRequestData: CreatePolarAreaWidgetDto[] = [];
                let bubbleChartRequestData: CreateBubbleWidgetDto[] = [];

                let chartTypes = suggestions.map((r: any) => r.chartType);
                for (let i = 0; i < blocks?.length; i++) {
                  const block = blocks[i];
                  const chartType = findFirstCommonElement(
                    chartTypes,
                    block.widgets
                  );
                  const index = chartTypes.findIndex(
                    (c: string) => c === chartType
                  );
                  if (index !== -1) {
                    chartTypes.splice(index, 1);
                  }
                  const chart = suggestionsCopy?.find(
                    (widget: any) => widget.chartType === chartType
                  );
                  if (chart) {
                    const indexSuggestion = suggestionsCopy?.findIndex(
                      (widget: any) => widget.chartType === chartType
                    );
                    if (indexSuggestion !== -1) {
                      suggestionsCopy?.splice(indexSuggestion, 1);
                    }
                    switch (chart?.chartType) {
                      case "mapChart":
                        mapChartRequestData.push({
                          ...chart,
                          blockId: block?.blockId?.toString(),
                          display: [],
                          arrangeBy: [],
                          palette: {},
                          datasetId: chart?.layers[0]?.datasetId,
                          apparitionConfig: {},
                          styleId: chart.styleId || "default",
                          dataFormat: {},
                          uniqueValues: chart?.uniqueValues ?? {},
                        });
                        break;
                      case "areaChart":
                        areaChartRequestData.push({
                          ...chart,
                          blockId: block?.blockId?.toString(),
                          apparitionConfig: {},
                          markers: AreaChartDefaultMarkers(chart),
                          formatting: getAreaChartDefaultColors(chart),
                          uniqueValues: chart?.uniqueValues ?? {},
                        });
                        break;
                      case "lineChart":
                        lineChartRequestData.push({
                          ...chart,
                          blockId: block?.blockId?.toString(),
                          apparitionConfig: {},
                          markers: getLineChartMarkers(chart),
                          formatting: getLineChartColors(chart),
                          uniqueValues: chart?.uniqueValues ?? {},
                        });
                        break;
                      case "barChart":
                        barChartRequestData.push({
                          ...chart,
                          blockId: block?.blockId?.toString(),
                          apparitionConfig: {},
                          formatting: getBarChartFormatting(chart),
                          uniqueValues: chart?.uniqueValues ?? {},
                        });
                        break;
                      case "lollipopChart":
                        lollipopChartRequestData.push({
                          ...chart,
                          blockId: block?.blockId?.toString(),
                          apparitionConfig: {},
                          markers: getLollipopChartMarkers(chart),
                          formatting: getLollipopChartColors(chart),
                          uniqueValues: chart?.uniqueValues ?? {},
                        });
                        break;
                      case "sankeyChart":
                        const arrangeBy = [
                          ...(chart?.groupBy || []),
                          ...(chart?.xAxe || []),
                        ];

                        sankeyChartRequestData.push({
                          ...chart,
                          blockId: block?.blockId?.toString(),
                          display: chart?.display || chart.yAxe || [],
                          arrangeBy:
                            chart?.arrangeBy && chart?.arrangeBy?.length
                              ? chart.arrangeBy
                              : arrangeBy?.filter((r) => !!r),
                          apparitionConfig: {},
                          uniqueValues: chart?.uniqueValues ?? {},
                        });
                        break;
                      case "matrixChart":
                        matrixChartRequestData.push({
                          ...chart,
                          blockId: block?.blockId?.toString(),
                          dataFormat: {},
                          apparitionConfig: {},
                          uniqueValues: chart?.uniqueValues ?? {},
                          xAxe: chart?.xAxe || chart?.arrangeBy || [],
                          yAxe: chart?.yAxe || chart?.display || [],
                        });
                        break;
                      case "polarAreaChart":
                        polarAreaChartRequestData.push({
                          ...chart,
                          blockId: block?.blockId?.toString(),
                          dataFormat: {},
                          apparitionConfig: {},
                          uniqueValues: chart?.uniqueValues ?? {},
                          xAxe: chart?.xAxe || [],
                          yAxe: chart?.yAxe || [],
                        });
                        break;
                      case "radarChart":
                        radarChartRequestData.push({
                          ...chart,
                          blockId: block?.blockId?.toString(),
                          dataFormat: {},
                          apparitionConfig: {},
                          uniqueValues: chart?.uniqueValues ?? {},
                          xAxe: chart?.xAxe || [],
                          yAxe: chart?.yAxe || [],
                        });
                        break;
                      case "punchcardChart":
                        punchcardChartRequestData.push({
                          ...chart,
                          blockId: block?.blockId?.toString(),
                          dataFormat: {},
                          apparitionConfig: {},
                          uniqueValues: chart?.uniqueValues ?? {},
                          xAxe: chart?.xAxe || [],
                          yAxe: chart?.yAxe || [],
                        });
                        break;
                      case "scatterplotChart":
                        scatterplotChartRequestData.push({
                          ...chart,
                          blockId: block?.blockId?.toString(),
                          dataFormat: {},
                          apparitionConfig: {},
                          uniqueValues: chart?.uniqueValues ?? {},
                          xAxe: chart?.xAxe || [],
                          yAxe: chart?.yAxe || [],
                        });
                        break;
                      case "bubbleChart":
                        bubbleChartRequestData.push({
                          ...chart,
                          blockId: block?.blockId?.toString(),
                          dataFormat: {},
                          apparitionConfig: {},
                          uniqueValues: chart?.uniqueValues ?? {},
                          xAxe: chart?.xAxe || chart?.arrangeBy || [],
                          yAxe: chart?.yAxe || chart?.display || [],
                        });
                        break;
                      default:
                    }
                  }
                }

                const existCharts =
                  barChartRequestData?.length +
                  lineChartRequestData?.length +
                  lollipopChartRequestData?.length +
                  mapChartRequestData?.length +
                  sankeyChartRequestData?.length +
                  areaChartRequestData?.length +
                  matrixChartRequestData?.length +
                  polarAreaChartRequestData?.length +
                  radarChartRequestData?.length +
                  scatterplotChartRequestData?.length +
                  punchcardChartRequestData?.length +
                  bubbleChartRequestData?.length;

                if (existCharts > 0) {
                  dispatch(
                    requestCreateWidgets({
                      barChart: barChartRequestData,
                      lineChart: lineChartRequestData,
                      lollipopChart: lollipopChartRequestData,
                      mapChart: mapChartRequestData,
                      sankeyChart: sankeyChartRequestData,
                      areaChart: areaChartRequestData,
                      matrixChart: matrixChartRequestData,
                      radarChart: radarChartRequestData,
                      scatterplotChart: scatterplotChartRequestData,
                      polarAreaChart: polarAreaChartRequestData,
                      punchcardChart: punchcardChartRequestData,
                      bubbleChart: bubbleChartRequestData,
                      pageId,
                    })
                  );
                }
              }
            } catch (e) {
              console.error(e);
            }
          },
        },
      })
    );
  };

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (id && pageId && !isPublicRoute) {
      dispatch(
        requestPageById({
          id: pageId,
          callbacks: {
            onSuccess: (data) => {
              dispatch(
                requestWidgetsSuggestions({
                  pageId,
                  projectId: id,
                  includeData: true,
                  withStorytelling: false,
                  withKpi: true,
                  callbacks: {
                    onSuccess: (suggestions) => {
                      getPageWidgetsRequest(
                        data,
                        suggestions?.suggested_charts
                      );
                    },
                  },
                })
              );
            },
          },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId]);

  useEffect(() => {
    if (pageId) {
      if (
        widgets.length > 0 ||
        (datasets?.length > 0 && currentPage.dashType === "table")
      ) {
        const delayedScreenshot = async () => {
          await new Promise((resolve) => setTimeout(resolve, 2000));
          downloadScreenshot(ref);
        };

        delayedScreenshot();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgets?.length, pageId, datasets?.length]);

  const componentToRender = useMemo(() => {
    if (pages.count && projectType === "real_estate") {
      // return  <RealEstateExplore key={currentPage.id} />
      // Bellow is the Layout where the map is on the right side
      // Need a solution to show it after user create a page
      return <LayoutRealEstate key={currentPage.id} />;
    }
    if (
      pages.count &&
      currentPage.dashType === "dashboard" &&
      currentPage.templateId === "2_3_b"
    ) {
      return <Layout5 />;
    } else if (pages.count && currentPage.dashType === "dashboard") {
      return <Layout key={currentPage.id} />;
    } else if (pages.count && currentPage.dashType === "storytelling") {
      return <StoryTelling key={currentPage.id} />;
    } else if (pages.count && currentPage.dashType === "table") {
      return <TablePage key={currentPage.id} />;
    } else {
      return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pages.count]);

  return (
    <>
      {/* {loading ? <Loader blur={false} /> : null} */}
      <div className="test" ref={ref}>
        {componentToRender}
      </div>
    </>
  );
};
