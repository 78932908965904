import { useRef } from "react";

import { SubMenuWrapper } from "./styles";
import { useOutsideClick } from "../../hooks/useOutsideClick";

import {
  Copy,
  Pencil,
  Trash,
  ClockHistory,
  Share,
  Download,
  CodeSquare,
} from "react-bootstrap-icons";

type SubmenuProps = {
  projectMenu?: boolean;
  datasetItemMenu?: boolean;
  menuOpen: boolean;
  pageTabMenu?: boolean;
  edit?: boolean;
  preview?: boolean;
  share?: boolean;
  duplicate?: boolean;
  rename?: boolean;
  download?: boolean;
  setMenuOpen: (menuOpen: boolean) => void;
  onRemove?: () => void;
  onEdit?: () => void;
  onClose?: () => void;
  onDuplicate?: () => void;
  onDownload?: () => void;
  onPreview?: () => void;
  onShare?: () => void;
};

export const Submenu = ({
  projectMenu,
  menuOpen,
  setMenuOpen,
  datasetItemMenu,
  pageTabMenu,
  edit,
  rename,
  preview,
  share,
  duplicate,
  download,
  onRemove,
  onEdit,
  onDuplicate,
  onClose,
  onDownload,
  onPreview,
  onShare,
}: SubmenuProps) => {
  const menuRef = useRef<any>(null);

  useOutsideClick(menuRef, () => {
    setMenuOpen(false);
  });

  return (
    <SubMenuWrapper
      $pageTab={pageTabMenu}
      $projectPage={projectMenu}
      $datasetItemMenu={datasetItemMenu}
      $open={menuOpen}
      ref={menuRef}
    >
      {preview && (
        <li
          onClick={() => {
            onPreview && onPreview();
            setMenuOpen(false);
          }}
        >
          <CodeSquare />
          Preview
        </li>
      )}

      {edit && (
        <li
          onClick={() => {
            onEdit && onEdit();
            setMenuOpen(false);
          }}
        >
          <Pencil />
          Edit
        </li>
      )}

      {rename && (
        <li
          onClick={() => {
            onEdit && onEdit();
            setMenuOpen(false);
          }}
        >
          <Pencil />
          Rename
        </li>
      )}

      {share && (
        <li
          onClick={() => {
            onShare && onShare();
            setMenuOpen(false);
          }}
        >
          <Share />
          Share
        </li>
      )}

      {duplicate && (
        <li
          onClick={() => {
            onDuplicate && onDuplicate();
            setMenuOpen(false);
          }}
        >
          <Copy />
          Duplicate
        </li>
      )}
      {download && (
        <li
          onClick={() => {
            onDownload && onDownload();
            setMenuOpen(false);
          }}
        >
          <Download />
          Download
        </li>
      )}
      {projectMenu && (
        <li
          onClick={() => {
            setMenuOpen(false);
          }}
        >
          <ClockHistory />
          Version history
        </li>
      )}
      <li
        onClick={() => {
          onRemove!();
          setMenuOpen(false);
        }}
      >
        <Trash />
        Remove
      </li>
    </SubMenuWrapper>
  );
};
