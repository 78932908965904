import { StyledMapContainer, StyledPopup } from "./styles";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Map, {
  NavigationControl,
  MapRef,
  MapboxEvent,
  Marker,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { RealEstateMapLegend } from "./components/Legend";
import { Threebox } from "threebox-plugin";
import {
  REAL_ESTATE_LEGEND_IDS_ARR,
  REAL_ESTATE_LEGEND_MAPP_IDS,
} from "./components/Legend/options";
import { ReactComponent as MapPin } from "../../assets/realEstateIcons/mapPin.svg";
import { Button } from "../Button";
import { ArrowRight } from "react-bootstrap-icons";
import { HouseSettingsProps } from "../Modals/PositionHouseModal/components/HouseSettings";
import { useDispatch } from "react-redux";
import { setActiveModal } from "../../store/slices/modals";
import { requestProperty } from "../../store/slices/realEstateSlice";

declare global {
  interface Window {
    tb: any;
  }
}

const mapStyles = {
  DEFAULT: "mapbox://styles/andyk1987/clv3kwhbo00ei01qp815c1w24",
};

export interface IMarker {
  latitude: number;
  longitude: number;
  imgSrc: string;
  price: number;
  streetAddress: string;
  zpid: number;
}

interface HouseSettings extends Omit<HouseSettingsProps, "zpId" | "address"> {}

interface Props {
  markers?: IMarker[];
  withLegend?: boolean;
  withZoomAction?: boolean;
  rounded?: boolean;
  bordered?: boolean;
  threeDModelPath?: string;
  houseSettings?: HouseSettings;
}

interface PopupInfo extends IMarker {}

const DEFAULT_LONGITUDE = -77.10189;
const DEFAULT_LATITUDE = 38.92582;
const DEFAULT_ZOOM = 15;
const DEFAULT_X = 0;
const DEFAULT_Y = 0;
const DEFAULT_Z = 241;

export const RealEstateMap = ({
  markers,
  withLegend = true,
  withZoomAction = true,
  rounded = true,
  bordered = true,
  houseSettings,
  threeDModelPath,
}: Props) => {
  const mapRef = useRef<MapRef | null>(null);
  const modelRef = useRef<any>(null);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [popupInfo, setPopupInfo] = useState<PopupInfo | null>(null);
  const dispatch = useDispatch();

  const updateModelPosition = useCallback((model: any, long: number, lat: number, rotateX: number, rotateY: number, rotateZ: number) => {
    if (!model) return;
    model.setCoords([long, lat]);
    model.setRotation({ x: rotateX, y: rotateY, z: rotateZ });
    window.tb.update();
  }, []);

  const handleMapMove = useCallback(() => {
    if (!mapRef.current || !modelRef.current || !houseSettings) return;
    
    const { latitude, longitude, rotateX = DEFAULT_X, rotateY = DEFAULT_Y, rotateZ = DEFAULT_Z } = houseSettings;
    
    updateModelPosition(
      modelRef.current,
      longitude || DEFAULT_LONGITUDE,
      latitude || DEFAULT_LATITUDE,
      rotateX,
      rotateY,
      rotateZ
    );
  }, [houseSettings, updateModelPosition]);

  const add3DModel = useCallback((modelPath: string, long?: number, lat?: number) => {
    if (modelRef.current) {
      window.tb.remove(modelRef.current);
      modelRef.current = null;
    }
    
    const scale = 3.2;
    const options = {
      obj: modelPath || threeDModelPath,
      type: "gltf",
      scale: { x: scale, y: scale, z: 2.7 },
      units: "meters",
      rotation: { x: 90, y: -10, z: 0 },
    };

    window.tb.loadObj(options, (model: any) => {
      updateModelPosition(
        model,
        long || DEFAULT_LONGITUDE,
        lat || DEFAULT_LATITUDE,
        DEFAULT_X,
        DEFAULT_Y,
        DEFAULT_Z
      );
      window.tb.add(model);
      modelRef.current = model;
    });
  }, [threeDModelPath, updateModelPosition]);

  useEffect(() => {
    if (!mapRef.current || !isMapLoaded) return;
    
    const map = mapRef.current.getMap();
    map.on('move', handleMapMove);
    map.on('zoom', handleMapMove);
    
    return () => {
      map.off('move', handleMapMove);
      map.off('zoom', handleMapMove);
    };
  }, [isMapLoaded, handleMapMove]);

  const onMapLoad = useCallback((event: MapboxEvent) => {
    if (!mapRef.current) return;
    
    const map = mapRef.current.getMap();
    setIsMapLoaded(true);

    map.addLayer({
      id: "custom-threebox-model",
      type: "custom",
      renderingMode: "3d",
      onAdd: function () {
        window.tb = new Threebox(map, map.getCanvas().getContext("webgl"), {
          defaultLights: true,
        });
        add3DModel(threeDModelPath || "https://docs.mapbox.com/mapbox-gl-js/assets/metlife-building.gltf");
      },
      render: function () {
        window.tb.update();
      },
    });
  }, [add3DModel, threeDModelPath]);

  useEffect(() => {
    if (!modelRef.current || !houseSettings || !isMapLoaded) return;
    
    const { rotateX, rotateY, rotateZ, latitude, longitude } = houseSettings;
    
    updateModelPosition(
      modelRef.current,
      longitude || DEFAULT_LONGITUDE,
      latitude || DEFAULT_LATITUDE,
      rotateX || DEFAULT_X,
      rotateY || DEFAULT_Y,
      rotateZ || DEFAULT_Z
    );
  }, [houseSettings, isMapLoaded, updateModelPosition]);

  const initializeMap = useCallback(() => {
    if (!mapRef.current || !isMapLoaded || !houseSettings) return;
    
    const { latitude, longitude } = houseSettings;

    mapRef.current.getMap().flyTo({
      center: [
        longitude || DEFAULT_LONGITUDE,
        latitude || DEFAULT_LATITUDE,
      ],
      zoom: DEFAULT_ZOOM,
      essential: true
    });
  }, [houseSettings, isMapLoaded]);

  useEffect(() => {
    if (isMapLoaded) {
      initializeMap();
    }
  }, [isMapLoaded, initializeMap]);

  const handleClickDetailsView = useCallback((info: PopupInfo | null) => {
    if (!info) return;
    dispatch(requestProperty({ zpid: info.zpid }));
    dispatch(setActiveModal({ id: "houseDetailsModal" }));
  }, [dispatch]);

  const applyFilter = useCallback((selectedItems: string[]) => {
    if (!mapRef.current) return;

    const map = mapRef.current.getMap();
    const allBuildingFeatures = map.querySourceFeatures("composite", { 
      sourceLayer: "building" 
    }) || [];

    const uniqueBuildingTypes = [
      ...new Set(
        allBuildingFeatures
          .map((feature) => feature.properties?.type)
          .filter((type) => type !== undefined)
      ),
    ];

    const involvedTypes = [
      "school",
      "college",
      "university",
      "hospital",
      "supermarket",
      "shop",
      "warehouse",
      "commercial",
      "office",
    ];

    const defaultBuildingTypes = uniqueBuildingTypes.filter(
      (type) => !involvedTypes.includes(type)
    );

    const buildingTypes: string[] = [...defaultBuildingTypes];
    const poiTypes: string[] = [];

    if (selectedItems.includes(REAL_ESTATE_LEGEND_MAPP_IDS.school)) {
      buildingTypes.push("school", "college", "university");
      poiTypes.push("School", "College");
    }
    if (selectedItems.includes(REAL_ESTATE_LEGEND_MAPP_IDS.hospital)) {
      buildingTypes.push("hospital");
      poiTypes.push("Hospital");
    }
    if (selectedItems.includes(REAL_ESTATE_LEGEND_MAPP_IDS.store)) {
      buildingTypes.push("supermarket", "shop", "warehouse", "commercial");
      poiTypes.push("Supermarket", "Marketplace");
    }
    if (selectedItems.includes(REAL_ESTATE_LEGEND_MAPP_IDS.office)) {
      buildingTypes.push("office");
      poiTypes.push("Office");
    }

    const buildingFilter = buildingTypes.length > 0
      ? ["match", ["get", "type"], buildingTypes, true, false]
      : null;

    const poiFilter = poiTypes.length > 0
      ? ["match", ["get", "type"], poiTypes, true, false]
      : null;

    map.setFilter("building (1)", buildingFilter);
    map.setFilter("poi-label", poiFilter);
  }, []);

  const renderMarkers = useMemo(() => {
    if (!markers?.length) return null;

    return markers.map((marker, index) => (
      <Marker
        style={{ zIndex: 10 }}
        key={`marker-${index}`}
        longitude={marker.longitude}
        latitude={marker.latitude}
        anchor="bottom"
      >
        <MapPin
          onMouseOver={() => {
            setPopupInfo({
              latitude: marker.latitude,
              longitude: marker.longitude,
              imgSrc: marker.imgSrc,
              price: marker.price,
              streetAddress: marker.streetAddress,
              zpid: marker.zpid,
            });
          }}
          onMouseLeave={() => {
            setTimeout(() => {
              setPopupInfo(null);
            }, 3000);
          }}
        />
      </Marker>
    ));
  }, [markers]);

  const renderPopup = useCallback((latitude: number, longitude: number) => {
    if (!popupInfo) return null;

    return (
      <StyledPopup
        latitude={latitude}
        longitude={longitude}
        closeButton={false}
        onClose={() => setPopupInfo(null)}
        anchor="left"
      >
        <div>
          <p className="house-price">${popupInfo.price}</p>
          <p className="house-address">{popupInfo.streetAddress}</p>
          <img src={popupInfo.imgSrc} alt="Popup" />
          <Button
            icon={<ArrowRight />}
            name="Explore"
            variant="neutral"
            onClick={() => handleClickDetailsView(popupInfo)}
            size="small"
          />
        </div>
      </StyledPopup>
    );
  }, [popupInfo, handleClickDetailsView]);

  const onSelectionChange = useCallback((selectedItems: string[]) => {
    applyFilter(selectedItems);
  }, [applyFilter]);

  return (
    <StyledMapContainer bordered={bordered} rounded={rounded}>
      <Map
        ref={mapRef}
        initialViewState={{
          latitude: houseSettings?.latitude || DEFAULT_LATITUDE,
          longitude: houseSettings?.longitude || DEFAULT_LONGITUDE,
          zoom: DEFAULT_ZOOM,
          pitch: 37.9,
          bearing: 172.5,
        }}
        mapStyle={mapStyles.DEFAULT}
        styleDiffing
        onLoad={onMapLoad}
        onMove={handleMapMove}
        onZoom={handleMapMove}
        style={{
          display: "flex",
          width: "100%",
          height: "unset",
          borderRadius: "8px",
        }}
        mapboxAccessToken="pk.eyJ1IjoiYW5keWsxOTg3IiwiYSI6ImNqeHJtdGJjNTA5bWwzbW1mcXA0cTZuMmkifQ.qAhmQzT0m6-KuvZt7-C83A"
      >
        {renderMarkers}
        {popupInfo && renderPopup(popupInfo.latitude, popupInfo.longitude)}
        {withZoomAction && (
          <NavigationControl showZoom={withZoomAction} position="top-right" />
        )}
      </Map>
      {isMapLoaded && withLegend && (
        <RealEstateMapLegend
          defaultSelectedItems={REAL_ESTATE_LEGEND_IDS_ARR}
          onSelectionChange={onSelectionChange}
        />
      )}
    </StyledMapContainer>
  );
};