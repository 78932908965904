import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CompaniesI } from "../../pages/Resources/pages/PublicResources/pages/SearchDataById/interfaces/companies.interface";

interface ProviderListItem {
  id: string,
  name: string
}

export interface DataProviders {
  dataProvidersList: ProviderListItem[];
  dataProvider?: CompaniesI;
}

const initialState: DataProviders = {
  dataProvidersList: [],
  dataProvider: undefined,
};

const appConfigSlice = createSlice({
  name: "dataProviders",
  initialState,
  reducers: {
    requestGetDataProviders: (state, action) => {},
    requestGetDataProvider: (state, action: PayloadAction<ProviderListItem['id']>) => { },
    setDataProvidersList: (state, action: PayloadAction<ProviderListItem[]>) => {
      return {
        ...state,
        dataProvidersList: action.payload
      }
     },
     setDataProvider: (state, action: PayloadAction<CompaniesI>) => {
      return {
        ...state,
        dataProvider: action.payload
      }
     },
  }
});

export const {
  requestGetDataProviders,
  requestGetDataProvider,
  setDataProvidersList,
  setDataProvider,
} = appConfigSlice.actions;

export default appConfigSlice.reducer;
