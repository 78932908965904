import { MarkersVisualisationDataDto } from "../../../../models/Widgets";

export function calculateValuesAndSum(
  layerData: MarkersVisualisationDataDto[] | undefined,
  valueKey = "value"
) {
  const values: number[] = [];
  const sum =
    layerData?.reduce((acc, curr: any) => {
      const value = parseFloat(curr[valueKey]);
      values.push(value);
      return acc + value;
    }, 0) || 0;
  return { values, sum };
}
