import { Search } from "../SearchBar";
import {
  LocationIcon,
  SearchContainer,
  SearchResultContainer,
  SearchResultItem,
} from "./styles";
import { useEffect, useRef, useState } from "react";
import { useDebounceCallback } from "usehooks-ts";
import { useParams } from "react-router-dom";
import { setActiveModal } from "../../store/slices/modals";
import { useDispatch, useSelector } from "react-redux";
import { setDraftPageSettings } from "../../store/slices/projectPages";
import { getDraftPageSettings } from "../../store/selectors/projects";

const preventScrolling = (isPreventing: boolean) => {
  if (isPreventing) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "auto";
  }
};

const highlightMatch = (option: string, inputValue: string) => {
  const index = option.toLowerCase().indexOf(inputValue.toLowerCase());
  if (index === -1) return option;

  const beforeMatch = option.slice(0, index);
  const match = option.slice(index, index + inputValue.length);
  const afterMatch = option.slice(index + inputValue.length);

  return (
    <span>
      {beforeMatch}
      <strong>{match}</strong>
      {afterMatch}
    </span>
  );
};

interface GeocodeFeature {
  id: string;
  type: string;
  place_type: string[];
  relevance: number;
  text: string;
  place_name: string;
  center: [number, number];
  geometry: {
    type: string;
    coordinates: [number, number];
  };
  context: Array<{
    id: string;
    text: string;
  }>;
}

interface GeocodeResponse {
  type: string;
  query: string[];
  features: GeocodeFeature[];
}

const SearchRealEstate = () => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState("");
  const [results, setResults] = useState<GeocodeResponse["features"]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const { id: projectId } = useParams();

  // get page id fro store
  const pageDraftSettings = useSelector(getDraftPageSettings);

  const fetchAddress = async (
    address: string
  ): Promise<GeocodeResponse | undefined> => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${address}.json?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`
      );
      const data: GeocodeResponse = await response.json();
      return data;
    } catch (error) {
      console.error(`Address fetch error: ${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  const onAddressChange = async (address: string) => {
    setIsOpen(Boolean(address));
    setValue(address);

    const response = await fetchAddress(address);
    const options = response?.features || [];
    setResults(options);
  };

  const debouncedAddressChange = useDebounceCallback(onAddressChange, 300);

  // close dropdown when clicked outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelect = (address: GeocodeFeature) => {
    const zipCode = address.text;
    dispatch(
      setActiveModal({ id: "projectSettingsModal", createOptions: true })
    );
    dispatch(
      setDraftPageSettings({
        ...pageDraftSettings,
        name: address.place_name,
        dashType: "dashboard",
        projectId: projectId ?? "",
        templateId: "3_8_c",
        location: zipCode,
      })
    );
    setValue(address.place_name);
    setIsOpen(false);
  };

  const renderOptions = () => {
    return (
      <SearchResultContainer>
        {isLoading && <SearchResultItem>Loading...</SearchResultItem>}
        {results.map((result) => (
          <SearchResultItem
            onClick={() => handleSelect(result)}
            key={result.place_name}
          >
            <LocationIcon />
            {highlightMatch(result.place_name, value)}
          </SearchResultItem>
        ))}
      </SearchResultContainer>
    );
  };

  useEffect(() => {
    preventScrolling(isOpen);
  }, [isOpen]);

  return (
    <SearchContainer ref={dropdownRef}>
      <Search
        value={value}
        onClick={() => {
          setIsOpen(Boolean(results.length));
        }}
        onChange={(value) => debouncedAddressChange(value)}
        icon={<LocationIcon />}
        placeholder="Enter an address, neighborhood, city, or ZIP code"
      />
      {isOpen && results.length ? renderOptions() : null}
    </SearchContainer>
  );
};

export default SearchRealEstate;
