import React from "react";
import { Container } from "./styles";
import { FormConnectAPIGroup } from "../FormGroup";

type Props = {
  handleChange: any;
  handleBlur: any;
  values: any;
};

const BasicAuth = ({ handleChange, handleBlur, values }: Props) => {
  return (
    <Container>
      <FormConnectAPIGroup
        handleChange={handleChange}
        handleBlur={handleBlur}
        placeholder={"Enter username"}
        type={"text"}
        name={"username"}
        values={values.username}
        label={"Username"}
        id={"username"}
      ></FormConnectAPIGroup>
      <FormConnectAPIGroup
        handleChange={handleChange}
        handleBlur={handleBlur}
        placeholder={"Enter password"}
        type={"password"}
        name={"password"}
        values={values.password}
        label={"Password"}
        id={"password"}
      ></FormConnectAPIGroup>
    </Container>
  );
};

export default BasicAuth;
