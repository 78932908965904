import React, { useState } from "react";
import { useDispatch } from "react-redux";

import {
  DatasetDimensionsCount,
  DatasetNameSeparator,
  Dimension,
  Dimensions,
  FileName,
  LabelText,
  LastExecution,
  More,
  ProvidedURL,
  RequestPerMinutes,
  RowWrapper,
  Status,
} from "./styles";
import {
  Category,
  DateTime,
  Location,
  Meta,
  MetricValue,
  Text,
} from "../../../../assets";
import { setActiveModal } from "../../../../store/slices/modals";
import {
  requestDatasetRemove,
  setDatasetPreview,
} from "../../../../store/slices/datasets";
import { NewDatasetDTO } from "../../../../models/Datasets";
import { Submenu } from "../../../Submenu";
import SwitchComponent from "../../../Inputs/CustomSwitch/Switch";

export const ResourceRow: React.FC<NewDatasetDTO> = (
  dataset: NewDatasetDTO
) => {
  const dispatch = useDispatch();
  const [menuOpen, setMenuOpen] = useState(false);
  const { name, fields, id } = dataset;
  const [showAllDimensions, setShowAllDimensions] = useState<boolean>(false);
  const openModal = (dataset: NewDatasetDTO) => {
    dispatch(setDatasetPreview(dataset));
    dispatch(setActiveModal({ id: "datasetOverviewModal" }));
  };

  const dimensionsToShow = () => {
    if (showAllDimensions) return fields;
    if (!showAllDimensions) return fields.slice(0, 6);
  };

  const date = new Date();

  const formattedDate = date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });

  return (
    <RowWrapper onMouseLeave={() => setShowAllDimensions(false)}>
      <FileName onClick={() => openModal(dataset)}>{name}</FileName>
      <Dimensions>
        {dimensionsToShow()!.map((dimension, idx) => {
          const iconSrc = (d: any) => {
            if (d) {
              switch (true) {
                case d.type === "text":
                  return Text;
                case d.type === "metric":
                  return MetricValue;
                case d.type === "location":
                  return Location;
                case d.type === "datetime":
                  return DateTime;
                case d.type === "categorical":
                  return Category;
                case d.type === "meta":
                  return Meta;
                default:
                  return Category;
              }
            }
          };
          return (
            <Dimension key={idx}>
              <img src={iconSrc(dimension)} alt="dimension icon" />
              <DatasetNameSeparator />
              <LabelText>{dimension.label}</LabelText>
            </Dimension>
          );
        })}
        {Math.sign(fields.length - 6) &&
        fields.length - 6 > 0 &&
        !showAllDimensions ? (
          <DatasetDimensionsCount onClick={() => setShowAllDimensions(true)}>
            +{fields.length - 6}
          </DatasetDimensionsCount>
        ) : (
          ""
        )}
      </Dimensions>
      <RequestPerMinutes>{`100`}</RequestPerMinutes>
      <ProvidedURL>{`https://api.census.gov/`}</ProvidedURL>
      <LastExecution>{formattedDate}</LastExecution>
      <Status>
        <SwitchComponent onChange={() => {}} checked />
      </Status>

      <More onClick={() => setMenuOpen((prevState) => !prevState)} />
      {menuOpen && (
        <Submenu
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
          datasetItemMenu
          onRemove={() => dispatch(requestDatasetRemove(id))}
          preview
          share
          download
        />
      )}
    </RowWrapper>
  );
};
