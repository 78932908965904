import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ChangePasswordDTO, LoginResponseDTO } from "../../models/Authentication";

export interface AuthState {
  user: LoginResponseDTO | null;
}

const initialState: AuthState = {
  user: null,
};

const appConfigSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    requestRegistration: (state, action) => {},
    requestChangePassword: (state, action: PayloadAction<ChangePasswordDTO>) => {},
    requestSignIn: (state, payload) => {},

    setRegistrationSuccess: (
      state,
      action: PayloadAction<LoginResponseDTO>
    ) => {
      state.user = action.payload;
    },

    setSignInSuccess: (state, action: PayloadAction<LoginResponseDTO>) => {
      state.user = action.payload;
    },

    signOut: (state) => {
      return {
        ...state,
        user: null,
      };
    },

    setUpdateUserToken: (state, action: PayloadAction<LoginResponseDTO>) => {
      state.user = action.payload;
    },
  },
});

export const {
  requestSignIn,
  requestRegistration,
  requestChangePassword,
  setRegistrationSuccess,
  setUpdateUserToken,
  setSignInSuccess,
  signOut,
} = appConfigSlice.actions;

export default appConfigSlice.reducer;
