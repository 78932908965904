import styled from "styled-components";

export const FilesTable = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.neutralBorder};

`;

export const HeadingWrapper = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.neutralTextWeak};
  height: 44px;
  align-items: center;
  background-color: #9FA4AD14;
  border-bottom: 2px solid ${({ theme }) => theme.neutralBorder};
  /* border-radius: 7px 7px 0 0; */
`;

export const AssetName = styled.div`
  max-width: 248px;
  width:100%;
  margin: 0 16px;
  position: relative;
`;

export const Format = styled.div`
  max-width: 128px;
  width: 100%;
  margin: 0 16px;
  display: flex;
  position: relative;
  margin-left: auto;

`;

export const UsedInProjects = styled.div`
  width: 160px;
  padding: 0 16px;
  position: relative;
`

export const UploadedDate = styled.div`
  width: 160px;
  padding: 0 16px;
  position: relative;
`
export const Owner = styled.div`
  width: 160px;
  padding: 0 16px;
  position: relative;
`

export const Details = styled.div`
  width: 20px;
  margin: 0 12px;
`;

export const Limits = styled.div`
  display: flex;
  width:100%;
  flex-direction: column;
  padding: 0 20px;
  margin-bottom: 16px;
`

export const LimitsHead = styled.p`
  color: #000;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
`

export const LimitsText = styled.p`
  color: #2E3849;
  font-size: 12px;
  align-items: flex-start;
  font-weight: 400;
  line-height: 16px;
`

