import styled from "styled-components";

export const ResourcesNoDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  display: flex;
  margin: 10% auto;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`

export const ResourcesNoDataTexts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  max-width: 450px;
`

export const ResourcesNoDataTextsTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: ${({ theme }) => theme.neutralTextWeak};
`
export const ResourcesNoDataTextsSubTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: ${({ theme }) => theme.neutralTextWeak};
`
