import {
  FeatureArrowIcon,
  FeatureContainer,
  FeatureCount,
  FeatureHeader,
  FeatureLabel,
  FeatureRow,
} from "./styles";

interface Item {
  label: string;
  number?: number;
}
interface Props {
  header: string;
  items: Item[];
}

const HouseFeature = ({ header, items }: Props) => {
  return (
    <FeatureContainer>
      <FeatureHeader>{header}</FeatureHeader>
      {items.map((item, index) => (
        <FeatureRow key={index}>
          <FeatureLabel $isDark={!item.number}>
            <FeatureArrowIcon>▶</FeatureArrowIcon>
            {item.label}
          </FeatureLabel>
          <FeatureCount>{item.number}</FeatureCount>
        </FeatureRow>
      ))}
    </FeatureContainer>
  );
};

export default HouseFeature;
