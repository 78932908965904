import styled from "styled-components";

export const CheckboxContainer = styled.label<{ checked: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: ${({ checked }) => (checked ? "#6F6FE91F" : "transparent")};
  padding: 8.5px 14px;
`;

export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  opacity: 0;
  position: absolute;
  pointer-events: none;
`;

export const StyledCheckbox = styled.div<{ checked: boolean }>`
  width: 17px;
  height: 17px;
  background: ${({ checked }) => (checked ? "#2C33C8" : "white")};
  border: 2px solid ${({ checked }) => (checked ? "transparent" : "#2E3849")};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  position: relative;
  border-radius: 2px;
`;

export const CheckboxLabel = styled.span<{ checked: boolean }>`
  margin-left: 9px;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.neutralText};
  text-shadow: ${({ checked, theme }) =>
    checked ? `0px 0px 1px ${theme.neutralText}` : "none"};
`;

export const Checkmark = styled.div`
  &:before {
    content: "";
    position: absolute;
    width: 3px;
    height: 7px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    top: 1px;
    left: 4px;
  }
`;
