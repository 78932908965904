import React, { ChangeEvent } from "react";
import {
  EssentialsBlock,
  EssentialsWrapper,
  FullNameBlockWrapper,
  SwitchWrapper,
  SwitchText,
  GapFlexRowBlock,
  GapColumnBlock,
  SubTitleText,
  FlexBlock,
} from "../styles";
import { FormikErrors, FormikTouched } from "formik";
import { CheckBox } from "../../../Inputs/CustomCheckbox/CheckBox";
import { getSettings } from "./getSettings";
import { IProfile } from "../../../../models/Widgets";


type NotificationsProps = {
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleBlur: React.ChangeEventHandler;
  values: IProfile;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<IProfile>>;
  errors: {
    [key: string]:
      | string
      | FormikErrors<any>
      | string[]
      | FormikErrors<any>[]
      | undefined;
  };
  touched: {
    [key: string]:
      | boolean
      | FormikTouched<any>
      | FormikTouched<any>[]
      | undefined;
  };
};

export const Notifications: React.FC<NotificationsProps> = ({
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
  setFieldValue,
}) => {
  const { notificationSettings, emailNotificationSettings } = getSettings(
    values,
    errors,
    touched,
    handleChange,
    handleBlur
  );

  return (
    <EssentialsBlock>
      <EssentialsWrapper>
        <FullNameBlockWrapper>
          <GapFlexRowBlock>
            <GapColumnBlock>
              <SwitchWrapper>
                <SwitchText>Desktop Notifications</SwitchText>
              </SwitchWrapper>
              {notificationSettings.map((setting) => (
                <FlexBlock key={setting.name}>
                  <CheckBox
                    onChange={setting.onChange}
                    onBlur={setting.onBlur}
                    errors={setting.errors}
                    touched={setting.touched}
                    name={setting.name}
                    checked={setting.checked}
                  />
                  <SubTitleText>{setting.label}</SubTitleText>
                </FlexBlock>
              ))}
            </GapColumnBlock>
            <GapColumnBlock>
              <SwitchWrapper>
                <SwitchText>Emails</SwitchText>
              </SwitchWrapper>
              {emailNotificationSettings.map((setting) => (
                <FlexBlock key={setting.name}>
                  <CheckBox
                    onChange={setting.onChange}
                    onBlur={setting.onBlur}
                    errors={setting.errors}
                    touched={setting.touched}
                    name={setting.name}
                    checked={setting.checked}
                  />
                  <SubTitleText>{setting.label}</SubTitleText>
                </FlexBlock>
              ))}
            </GapColumnBlock>
          </GapFlexRowBlock>
        </FullNameBlockWrapper>
      </EssentialsWrapper>
    </EssentialsBlock>
  );
};
