import { WidgetItem } from "../../../../models/Widgets";

export const mockWidgetItem: WidgetItem = {
  id: '',
  description: '',
  insights: '',
  createdAt: '',
  updatedAt: '',
  name: '',
  chartType: '',
  pageId: '',
  userId: '',
  datasetId: '',
  layout: '',
  background: '',
  legend: false,
  search: false,
  layers: [],
  xAxe: [],
  yAxe: [],
  data: [],
  groupBy: [],
  orientation: '',
  display: [],
  arrangeBy: [],
  tooltip: false,
  formatting: [],
  markers: [],
  terrain: false,
  timeline: true,
};