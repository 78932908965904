import React from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";

import { HeadingMenuContainer } from "../../components/NavBar/styles";
import { PageMenu } from "../../components/PageMenu";
import { ResourcesTabs } from "../../components/ResourcesTabs";

export const Resources: React.FC = () => {
  const { pathname } = useLocation();
  const { id } = useParams()
  
  return (
    <>
      {!pathname.includes('/search') && !id && (
        <HeadingMenuContainer>
          <ResourcesTabs />
          <PageMenu />
        </HeadingMenuContainer>
      )}
      <Outlet />
    </>
  );
};
