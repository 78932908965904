import styled from "styled-components";
import { searchBarWidth } from "../../helpers/stylesHelpers";

const svgDataUrl = encodeURIComponent(`
 <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.68306 3.56694C2.43898 3.32286 2.43898 2.92714 2.68306 2.68306C2.92714 2.43898 3.32287 2.43898 3.56694 2.68306L10 9.11612L16.4331 2.68306C16.6771 2.43898 17.0729 2.43898 17.3169 2.68306C17.561 2.92714 17.561 3.32286 17.3169 3.56694L10.8839 10L17.3169 16.4331C17.561 16.6771 17.561 17.0729 17.3169 17.3169C17.0729 17.561 16.6771 17.561 16.4331 17.3169L10 10.8839L3.56694 17.3169C3.32287 17.561 2.92714 17.561 2.68306 17.3169C2.43898 17.0729 2.43898 16.6771 2.68306 16.4331L9.11612 10L2.68306 3.56694Z" fill="#5F6877"/>
</svg>

`);

export const SearchBarContainer = styled.div<{
  $width?: string;
  $isValue?: boolean;
}>`
  display: flex;
  width: ${({ $width }) => searchBarWidth($width)};
  height: 36px;
  align-self: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.primaryBorderColor};
  border-radius: 50px;
  background-color: ${({ theme, $isValue }) =>
    $isValue ? theme.neutralBackgroundBase : theme.neutralBackgroundBase};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.neutralBackgroundHover};
  }

  &:focus-within {
    background-color: ${({ theme }) => theme.neutralBackgroundBase};
    border: 1px solid ${({ theme }) => theme.primaryBorder};
  }
`;

export const IconWrapper = styled.div`
  width: 40px;
  display: flex;
  align-items: center;
  border-style: none;
  justify-content: center;
`;

export const InputField = styled.input`
  width: 100%;
  border-style: none;
  padding: 5px;
  border-radius: 0 50px 50px 0;
  outline: none;
  color: ${({ theme }) => theme.neutralText};
  font-size: 14px;
  position: relative;
  background: none;
  cursor: pointer;

  &:focus {
    color: ${({ theme }) => theme.neutralText};
  }

  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    display: flex;
    background-image: url("data:image/svg+xml;utf8,${svgDataUrl}");
    background-repeat: no-repeat;
    cursor: pointer;
  }
`;
