import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  FileDeleteDto,
  FileDownloadDto,
  FileUploadDto,
  GenericSuggestionDTO,
  SuggestionField,
  UploadResponseDto,
  UploadResponseSingle,
  UploadResponseSingleI,
} from "../../models/Files";

const initialState: UploadResponseDto = {
  uploadedFilesCount: 0,
  items: [],
  uploadFiles: undefined,
  count: 0,
  uploadProgress: 0,
};

const filesSlice = createSlice({
  name: "files",
  initialState,
  reducers: {
    requestUploadFile: (state: UploadResponseDto, action) => {
      return {
        ...state,
        uploadedFilesCount: Number(action.payload[0].length),
      };
    },

    setUploadProgress: (state: UploadResponseDto, action) => {
      return {
        ...state,
        uploadProgress: action.payload,
      };
    },

    setUploadFile: (state: UploadResponseDto, action) => {
      return {
        ...state,
        uploadFiles: action.payload,
      };
    },

    setFileData: (
      state: UploadResponseDto,
      { payload }: { payload: UploadResponseSingleI[] }
    ) => {
      const filteredItems: UploadResponseSingle[] = payload.map((item) => {
        const mappedSuggestedNames: GenericSuggestionDTO["suggested_names"] =
          {};

        const suggestions = item?.suggestions?.at(0);

        suggestions?.suggested_names?.forEach((r) => {
          mappedSuggestedNames[r.name] = (
            r.suggestions as unknown as string[]
          ).join();
        });

        const mappedSuggestedTypes: { [key: string]: SuggestionField[] } = {};
        suggestions?.suggested_types?.forEach((typeObj) => {
          mappedSuggestedTypes[typeObj.name] = typeObj.types;
        });

        const mergedObject = [];

        for (const key in mappedSuggestedNames) {
          if (mappedSuggestedTypes[key]) {
            const label = mappedSuggestedNames[key];
            const types = mappedSuggestedTypes[key];
            mergedObject.push({ label, objectKey: key, types });
          }
        }

        return {
          ...item,
          suggestions: {
            ...suggestions,
            suggested_groups: suggestions?.suggested_groups,
            suggested_names: mappedSuggestedNames,
            suggested_types: mappedSuggestedTypes,
          },
          parsedSuggestions: mergedObject,
        };
      });

      return {
        ...state,
        items: [...state.items, ...filteredItems],
        count: state.count + payload.length,
      };
    },

    updateFileData: (
      state: UploadResponseDto,
      {
        payload,
      }: PayloadAction<{
        key: string;
        newHeader: string;
        filePath: string;
        type: string;
      }>
    ) => {
      return {
        ...state,
        items: state.items.map((item) => {
          const updatedParsedSuggestions = item.parsedSuggestions?.map(
            (suggestion) => {
              if (suggestion.objectKey === payload.key) {
                return {
                  ...suggestion,
                  label: payload.newHeader,
                  types: [{ type: payload.type!, subtype: payload.type! }],
                };
              }
              return suggestion;
            }
          );

          if (item.path === payload.filePath) {
            return {
              ...item,
              parsedSuggestions: updatedParsedSuggestions,
            };
          }

          return item;
        }),
      };
    },

    removeFileData: (state: UploadResponseDto, action) => {
      return {
        ...state,
        items: state.items.filter((item) => item.path !== action.payload),
        count: state.count - 1,
      };
    },
    requestUploadSimpleFile: (
      state: UploadResponseDto,
      action: PayloadAction<FileUploadDto>
    ) => {},

    requestDownloadFile: (
      state: UploadResponseDto,
      action: PayloadAction<FileDownloadDto>
    ) => {},

    requestDeleteFile: (
      state: UploadResponseDto,
      action: PayloadAction<FileDeleteDto>
    ) => {},
  },
});
export const {
  requestUploadFile,
  setFileData,
  removeFileData,
  setUploadProgress,
  updateFileData,
  setUploadFile,
  requestUploadSimpleFile,
  requestDownloadFile,
  requestDeleteFile,
} = filesSlice.actions;
export default filesSlice.reducer;
