import React, { useState } from "react";
import {
  CloseIcon,
  ModalFooterButtons,
  ModalFooterWrapper,
  ModalHeading,
  ModalHeadingWrapper,
  ModalOverlay,
  OverviewContent,
} from "../styles";
import {
  InputWrapper,
  OverviewContentWrapper,
  RenameInput,
  RenameInputHeader,
  RenameModal,
} from "./styles";
import { Button } from "../../Button";
import { Check } from "react-bootstrap-icons";
import { getModalData } from "../../../store/selectors/modals";
import { useDispatch, useSelector } from "react-redux";
import {
  requestGetAllDatasets,
  requestUpdateDatasetById,
} from "../../../store/slices/datasets";

type Props = {
  onClose: () => void;
};

const DataSetRename = ({ onClose }: Props) => {
  const dispatch = useDispatch();

  const data = useSelector(getModalData);
  const extension = "." + String(data.name).split(".")[1];

  const [value, setValue] = useState<string>(String(data.name).split(".")[0]);

  const handleUpdateName = () => {
    dispatch(
      requestUpdateDatasetById({
        id: data.id,
        name: value + extension,
        callbacks: {
          onSuccess() {
            dispatch(requestGetAllDatasets({}));
            onClose();
          },
        },
      })
    );
  };

  const isActive = String(data.name).split(".")[0] !== value && !!value.length;

  return (
    <ModalOverlay onClick={onClose}>
      <RenameModal onClick={(e) => e.stopPropagation()}>
        <ModalHeadingWrapper $disableSeparator={false}>
          <ModalHeading>Rename Dataset “{data?.name ?? ""}”</ModalHeading>
          <CloseIcon onClick={onClose} />
        </ModalHeadingWrapper>

        <OverviewContent>
          <OverviewContentWrapper>
            <InputWrapper>
              <RenameInputHeader>New Dataset Name</RenameInputHeader>
              <RenameInput
                value={value}
                onChange={(e) => setValue(e.target.value)}
                placeholder="Type Here"
              />
            </InputWrapper>
          </OverviewContentWrapper>
        </OverviewContent>

        <ModalFooterWrapper>
          <Button
            name="Cancel"
            onClick={onClose}
            variant="neutral"
            size="medium"
          />
          <ModalFooterButtons>
            <Button
              name={"Save"}
              onClick={handleUpdateName}
              type="submit"
              variant={isActive ? "secondary" : "disabled"}
              size="medium"
              disabled={!isActive}
              icon={<Check />}
            />
          </ModalFooterButtons>
        </ModalFooterWrapper>
      </RenameModal>
    </ModalOverlay>
  );
};

export default DataSetRename;
