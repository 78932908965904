import { Popup } from "react-map-gl";
import styled from "styled-components";

export const StyledMapContainer = styled.div<{
  rounded?: boolean;
  bordered?: boolean;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  position: relative;
  border-radius: ${({ rounded }) => (rounded ? "8px" : "0")};
  border: ${({ bordered, theme }) =>
    bordered ? `1px solid ${theme.neutralBorder}` : "none"};
`;

export const StyledPopup = styled(Popup)`
  background-color: #ffffff;
  border-radius: 6px;
  width: 180px;
  left: 16px;
  height: 244px;
  border: 1px solid #dfe1e4;
  box-shadow: 0px 0px 24px 0px #11111114;
  z-index: 1000;

  img {
    width: 156px;
    height: 120px;
    border-radius: 6px;
    margin-bottom: 8px;
  }

  .house-price {
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;
    color: ${({ theme }) => theme.neutralText};
  }

  .house-address {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: ${({ theme }) => theme.neutralTextWeak};
    margin-bottom: 11px;
  }

  .mapboxgl-popup-content {
    padding: 8px 11px;
    background: #ffffff !important;
    box-shadow: none;
    border: none;
    margin: 0;
  }

  .mapboxgl-popup-close-button,
  .mapboxgl-popup-tip {
    display: none;
  }
`;
