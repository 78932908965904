import styled from 'styled-components';


export const RadioButtonWrapper = styled.div`
  display: flex;
  width: max-content;
  gap: 8px;
  align-items: center;
`

export const Lable = styled.p<{
  $disabled?: boolean;
}>`
  font-size: 14px;
  ${({$disabled, theme}) => $disabled && `
  color: ${theme.neutralTextDisabled}
  `}
`

export const Input = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 16px;
  height: 16px;
  cursor: pointer;
`;

export const Checkmark = styled.span`
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: ${({theme}) => theme.neutralBackground};
  border: 1px solid #5F687714;
`;

export const Container = styled.label`
  display: inline-block;
  position: relative;
  cursor: pointer;
  user-select: none;
  height: 16px;

  ${Input} {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  ${Input}:checked + ${Checkmark} {
    background-color: #00143F;
  }

  ${Input}:checked + ${Checkmark} {
    border:none;
  }

  ${Input}:hover + ${Checkmark} {
    border:none;
  }
  
  ${Input}:active + ${Checkmark} {
    border:none;
  }

  ${Checkmark}:after {
    transition: 0s;
    content: "";
    position: absolute;
    display: none;
  }

  ${Input}:checked + ${Checkmark}:after {
    display: block;
  }

  ${Checkmark}:after {
    top: 5px;
    left: 5px;
    width: 6px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: white

  }

  ${Input}:checked:hover ~ ${Checkmark}::after {
    top: 3px;
    left: 3px;
    width: 10px;
    aspect-ratio: 1;
    background: white;
  }

  ${Input}:checked:active ~ ${Checkmark}::after {
    top: 4px;
    left: 4px;
    width: 8px;
    aspect-ratio: 1;
    background: white;

  }

  ${Input}:not(:checked):hover ~ ${Checkmark}::after {
    top: 3px;
    left: 3px;
    width: 10px;
    aspect-ratio: 1;
    display: block;
    background: #00143F;
  }

  ${Input}:not(:checked):active ~ ${Checkmark}::after {
    top: 4px;
    left: 4px;
    width: 8px;
    aspect-ratio: 1;
    background: #00143F;
    display: block;
  }

  //disabled-state
  ${Input}:disabled + ${Checkmark} {
    border: none;
  }
  ${Input}:disabled:hover + ${Checkmark}::after {
    background: transparent;
  }
`;
