import { FormikErrors, FormikTouched } from "formik";
import { ChangeEvent } from "react";
import { IProfile } from "../../../../models/Widgets";

export function getSettings(
  values: IProfile, 
  errors: { [key: string]: boolean | string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined; }, 
  touched: { [key: string]: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined; }, 
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void, 
  handleBlur: React.ChangeEventHandler<Element>) {
    
  const notificationSettings = [
    {
      name: 'desktopNotifications.importantAlerts',
      checked: Boolean(values.desktopNotifications.importantAlerts),
      errors: errors.desktopNotifications as string,
      touched: touched.desktopNotifications as boolean,
      label: 'Enable desktop alerts for important notifications?',
      onChange: handleChange,
      onBlur: handleBlur,
    },
    {
      name: 'desktopNotifications.dashboardPublished',
      checked: Boolean(values.desktopNotifications.dashboardPublished),
      errors: errors.receiveDashboard as string,
      touched: touched.receiveDashboard as boolean,
      label: 'Receive dashboard/report published notifications?',
      onChange: handleChange,
      onBlur: handleBlur,
    },
    {
      name: 'desktopNotifications.viewerEditor',
      checked: Boolean(values.desktopNotifications.viewerEditor),
      errors: errors.receiveViewerEditor as string,
      touched: touched.receiveViewerEditor as boolean,
      label: 'Receive “Added as viewer/editor” notifications?',
      onChange: handleChange,
      onBlur: handleBlur,
    },
    {
      name: 'desktopNotifications.playSoundOnReminder',
      checked: Boolean(values.desktopNotifications.playSoundOnReminder),
      errors: errors.playSoundAlert as string,
      touched: touched.playSoundAlert as boolean,
      label: 'Play a sound when I receive a reminder or alert?',
      onChange: handleChange,
      onBlur: handleBlur,
    },
    {
      name: 'desktopNotifications.statusUpdate',
      checked: Boolean(values.desktopNotifications.statusUpdate),
      errors: errors.receiveStatus as string,
      touched: touched.receiveStatus as boolean,
      label: 'Receive status update notifications?',
      onChange: handleChange,
      onBlur: handleBlur,
    },
  ];

  const emailNotificationSettings = [
    {
      name: 'emailNotifications.emailViewerEditor',
      checked: Boolean(values.emailNotifications.emailViewerEditor),
      errors: errors.receiveViewerEditorMail as string,
      touched: touched.receiveViewerEditorMail as boolean,
      label: 'Receive “Added as viewer/editor” emails?',
      onChange: handleChange,
      onBlur: handleBlur,
    },
    {
      name: 'emailNotifications.emailDashboardCompleted',
      checked: Boolean(values.emailNotifications.emailDashboardCompleted),
      errors: errors.receiveDashboardEmail as string,
      touched: touched.receiveDashboardEmail as boolean,
      label: 'Receive dashboard/report completed emails?',
      onChange: handleChange,
      onBlur: handleBlur,
    },
    {
      name: 'emailNotifications.emailStatusUpdate',
      checked: Boolean(values.emailNotifications.emailStatusUpdate),
      errors: errors.receiveStatusMail as string,
      touched: touched.receiveStatusMail as boolean,
      label: 'Receive profile status update emails?',
      onChange: handleChange,
      onBlur: handleBlur,
    },
  ];
  return { notificationSettings, emailNotificationSettings };
}
