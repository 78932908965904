import styled from "styled-components";

export const StyledMapContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  position: relative;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.neutralBorder};
`;
