import {
  Slider,
  Switch,
  SwitchInput,
  SwitchLabel,
  SwitchWrapper,
} from "./style";

type Props = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: React.ChangeEventHandler;
  values?: any;
  checked?: boolean;
  name?: string;
  disabled?: boolean;
  errors?: string;
  touched?: boolean;
  label?: string;
  labelSide?: "right" | "left";
  disableMargin?: boolean;
};

const SwitchComponent: React.FC<Props> = ({
  onChange,
  onBlur,
  values,
  checked,
  name,
  disabled,
  errors,
  touched,
  label,
  labelSide = "right",
  disableMargin = false
}: Props) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e);
  };

  return (
    <SwitchWrapper $disabled={disabled} $disableMargin={disableMargin} $labelSide={labelSide}>
      {!!label && <SwitchLabel $checked={checked}>{label}</SwitchLabel>}
      <Switch>
        <SwitchInput
          disabled={disabled}
          onChange={handleChange}
          onBlur={onBlur}
          type="checkbox"
          name={name}
          checked={checked}
        />
        <Slider $error={!!(errors && touched)} />
      </Switch>
    </SwitchWrapper>
  );
};

export default SwitchComponent;
