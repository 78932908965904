import { styled } from "styled-components";
import { CheckLg } from "react-bootstrap-icons";

export const ConnectAPIModal = styled.div`
  background: white;
  position: absolute;
  right: 50%;
  left: 50%;
  min-width: 496px;
  transform: translate(-50%, 0);
  border-radius: 16px;
  z-index:11px;
`

export const OverviewContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 228px);
  overflow-y: auto;
`

export const OverviewLoaderWrapper = styled.div`
  margin: 30px 0;
  display: flex;
  gap: 24px;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 228px);
  overflow-y: auto;
`

export const FinishLoadWrapper = styled.div`
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #DFE0FD;
  border: 1px solid #7D82F8;
  width:56px;
  height: 56px;
`

export const FinishLoad = styled(CheckLg)`
  fill: #141BB1;
`

export const LoaderWrapper = styled.div`
  position: relative;
  width:56px;
  height: 56px;
`



export const LoaderText = styled.div`
  display: flex;
  flex-direction: column;
  color: #5F6877;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; 
  text-align: center;
`

export const SelectAuthTypeWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px 20px;

`

export const Authorization = styled.p`
  color: #000417;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-top: 30px;
  margin-bottom: 16px;
`

export const Info = styled.div`
  display: flex;
  gap: 4px;
  margin-top: 4px;
`

export const InfoText = styled.p`
  color: #5F6877;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px; 
`