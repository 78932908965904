import React, { HTMLInputTypeAttribute } from "react";
import { ErrorMessage, Heading, InputContainer, InputWrapper, StyledInput } from "./styles";

interface CustomInputInterface {
  value: any;
  heading?: string;
  type: HTMLInputTypeAttribute;
  name: string;
  onChange: (e: any) => void;
  maxLength?: number;
  className?: string;
  error?: string;
}

export const CustomInput = ({
  value,
  heading,
  type,
  name,
  onChange,
  maxLength,
  className,
  error,
}: CustomInputInterface) => {
  return (
    <InputContainer className={className}>
      <Heading>{heading && heading}</Heading>
      <InputWrapper $hasError={!!error}>
        <StyledInput
          type={type}
          value={value}
          name={name}
          onChange={(e) => onChange(e.target.value)}
          maxLength={maxLength}
          $hasError={!!error}
        />
      </InputWrapper>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </InputContainer>
  );
};
