import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button } from "../../../../Button";
import { CustomInput } from "../../../../CustomInput";
import {
  HouseSettingsContainer,
  HouseSettingsHr,
  HouseSettingsRotate,
  HouseSettingsSection,
  HouseSettingsText,
  SuggestionContainer,
  SuggestionItem,
} from "./styles";
import { InfoCircle } from "react-bootstrap-icons";
import { useAddressSuggestions } from "../../../../../hooks/useAddressSuggestions";

export interface HouseSettingsProps {
  zpId: string;
  address: string;
  latitude: number;
  longitude: number;
  scale?: number;
  rotateX?: number;
  rotateY?: number;
  rotateZ?: number;
}

interface Props {
  onSave: (houseSettings: HouseSettingsProps) => void;
}

const validationSchema = Yup.object({
  zpId: Yup.string(),
  address: Yup.string(),
  latitude: Yup.number()
    .min(-90, "Latitude must be between -90 and 90")
    .max(90, "Latitude must be between -90 and 90"),
  longitude: Yup.number()
    .min(-180, "Longitude must be between -180 and 180")
    .max(180, "Longitude must be between -180 and 180"),
  scale: Yup.number().positive("Scale must be positive"),
  rotateX: Yup.number(),
  rotateY: Yup.number(),
  rotateZ: Yup.number(),
});

const HouseSettings: React.FC<Props> = ({ onSave }) => {
  const formik = useFormik<HouseSettingsProps>({
    initialValues: {
      zpId: "",
      address: "",
      latitude: 0,
      longitude: 0,
      scale: 1,
      rotateX: 0,
      rotateY: 0,
      rotateZ: 0,
    },
    validationSchema,
    onSubmit: (values) => {
      onSave(values);
    },
  });

  const handleAddressSelect = (address: string, longitude: number, latitude: number) => {
    formik.setFieldValue('address', address);
    formik.setFieldValue('longitude', longitude);
    formik.setFieldValue('latitude', latitude);
  };

  const {
    suggestions,
    showSuggestions,
    suggestionsRef,
    handleAddressChange,
    handleSuggestionSelect
  } = useAddressSuggestions({
    onSelect: handleAddressSelect
  });

  return (
    <HouseSettingsContainer>
      <form onSubmit={formik.handleSubmit}>
        <HouseSettingsSection>
          <HouseSettingsText style={{ marginBottom: "6px" }}>
            House Settings
          </HouseSettingsText>
          <CustomInput
            className="input-container"
            heading="ZPID"
            name="zpId"
            value={formik.values.zpId}
            onChange={(value) => formik.setFieldValue("zpId", value)}
            type="text"
            error={formik.touched.zpId ? formik.errors.zpId : ""}
          />
          <HouseSettingsText style={{ marginTop: "4px", display: "flex" }}>
            <InfoCircle style={{ marginRight: "4px" }} />
            Optional
          </HouseSettingsText>
        </HouseSettingsSection>
        <HouseSettingsHr />

        <HouseSettingsSection>
          <div ref={suggestionsRef} style={{ position: 'relative' }}>
            <CustomInput
              className="input-container"
              heading="Address"
              name="address"
              value={formik.values.address}
              onChange={(value) => {
                formik.setFieldValue('address', value);
                handleAddressChange(value);
              }}
              type="text"
              error={formik.touched.address ? formik.errors.address : ""}
            />
            {showSuggestions && suggestions.length > 0 && (
              <SuggestionContainer>
                {suggestions.map((suggestion) => (
                  <SuggestionItem
                    key={suggestion.id}
                    onClick={() => handleSuggestionSelect(suggestion)}
                  >
                    {suggestion.fullAddress}
                  </SuggestionItem>
                ))}
              </SuggestionContainer>
            )}
          </div>
          <CustomInput
            className="input-container"
            heading="Longitude"
            name="longitude"
            value={formik.values.longitude}
            onChange={(value) => formik.setFieldValue("longitude", value)}
            type="number"
            error={formik.touched.longitude ? formik.errors.longitude : ""}
          />
          <CustomInput
            className="input-container"
            heading="Latitude"
            name="latitude"
            value={formik.values.latitude}
            onChange={(value) => formik.setFieldValue("latitude", value)}
            type="number"
            error={formik.touched.latitude ? formik.errors.latitude : ""}
          />
        </HouseSettingsSection>
        <HouseSettingsHr />

        <HouseSettingsSection>
          <CustomInput
            className="input-container"
            heading="Scale"
            name="scale"
            value={formik.values.scale}
            onChange={(value) =>
              formik.setFieldValue("scale", value)
            }
            type="number"
            error={formik.touched.scale ? formik.errors.scale : ""}
          />

          <HouseSettingsText style={{ marginBottom: "6px", marginTop: "6px" }}>
            Model position
          </HouseSettingsText>
          <HouseSettingsRotate>
            <CustomInput
              className="input-container"
              heading="X"
              name="rotateX"
              value={formik.values.rotateX}
              onChange={(value) =>
                formik.setFieldValue("rotateX", value)
              }
              type="number"
            />
            <CustomInput
              className="input-container"
              heading="Y"
              name="rotateY"
              value={formik.values.rotateY}
              onChange={(value) =>
                formik.setFieldValue("rotateY", value)
              }
              type="number"
            />
            <CustomInput
              className="input-container"
              heading="Z"
              name="rotateZ"
              value={formik.values.rotateZ}
              onChange={(value) =>
                formik.setFieldValue("rotateZ", value)
              }
              type="number"
            />
          </HouseSettingsRotate>
        </HouseSettingsSection>
        <HouseSettingsHr />

        <HouseSettingsSection>
          <Button
            name="Place"
            onClick={formik.handleSubmit}
            type="submit"
            variant="primary"
            disabled={!formik.isValid}
            size="medium"
          />
        </HouseSettingsSection>
      </form>
    </HouseSettingsContainer>
  );
};

export default HouseSettings;