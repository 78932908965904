import * as Yup from "yup";

export const connectApiValidationSchema = () => {
  return Yup.object().shape({
    url: Yup.string()
      .url('Invalid URL format')
      .min(5, "Too Short!")
      .required("Please enter URL or paste text"),
    authType: Yup.string().oneOf(['basicAuth', 'noAuth', 'bearerToken',"JWTBearer","digestAuth"], 'Invalid authentication type'),
    username: Yup.string(),
    password: Yup.string(),
    token: Yup.string(),
    algorithm: Yup.string(),
    secret: Yup.string(),
    secretBase64Encoded: Yup.boolean(),
    payload: Yup.string(),
    JWT_Headers: Yup.string(),
    requestHeaderPrefix: Yup.string(),
    disableRetrying: Yup.boolean(),
    realm: Yup.string(),
    nonce: Yup.string(),
    qop: Yup.string(),
    nonceCount: Yup.string(),
    clientNonce: Yup.string(),
    opaque: Yup.string(),
  });
};