import { ChevronRight } from "react-bootstrap-icons";
import { CheckBox } from "../Inputs/CustomCheckbox/CheckBox";
import { TopicSelectWrapper, CheckBoxWrapper, TopicButtonWrapper, TopicSelectTitle, Count } from "./styles";
interface Props {
  onClick?: (e: any) => void;
  onChange: (e: any) => void;
  name: string
  disableIcon?: boolean
  disablePadding?: boolean
  selected?: "checked" | "none" | "indeterminate" | undefined
  count?: number;
}

export const TopicSelect: React.FC<Props> = (props) => {
  const { onChange, onClick, name, disableIcon, disablePadding, selected, count } = props
  return (
    <TopicSelectWrapper $disablePadding={disablePadding} $selected={selected !== 'none'}>
      <CheckBoxWrapper>
        <CheckBox status={selected} onChange={onChange} />
      </CheckBoxWrapper>
      <TopicButtonWrapper onClick={onClick || (() => { })}>
        <TopicSelectTitle>
          {name}
        </TopicSelectTitle>
        {count ? <Count>{count}</Count> : ''}
        {!disableIcon &&
          <ChevronRight />
        }
      </TopicButtonWrapper>
    </TopicSelectWrapper >
  )
}
