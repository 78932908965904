import styled from "styled-components";

export const KPIWrapperMainContainer = styled.div<{
  $selected?: boolean;
  $recommended?: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  height: 100%;
  width: 100%;
`;

export const KPIWrapperBorder = styled.div<{
  $borderRight?: boolean;
  $borderTop?: boolean;
  $borderLeft?: boolean;
}>`
  border-right: 1px solid
    ${({ theme, $borderRight }) =>
      $borderRight ? theme.neutralBorder : "transparent"};
  border-top: 1px solid
    ${({ theme, $borderTop }) =>
      $borderTop ? theme.neutralBorder : "transparent"};
  border-left: 1px solid
    ${({ theme, $borderLeft }) =>
      $borderLeft ? theme.neutralBorder : "transparent"};
  padding: 8px;
  height: 100%;
  width: 100%;
`;

export const KPIWrapperCol = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;

export const KPIWrapperRow = styled.div<{ $isRow?: boolean }>`
  display: flex;
  /* If component width is less than 25% of viewport width, use column layout */
  flex-direction: ${({ $isRow }) => $isRow ? 'row' : 'column'};
  justify-content: flex-start;
  align-items: stretch;
  overflow: auto;
  width: 100%;
  height: 100%;
  gap: 1rem;
`;

export const KPISettings = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;
