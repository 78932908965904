import React from "react";

import {
  DatasetsTable,
  Dimensions,
  HeadingWrapper,
  Name,
  UploadedOn,
  Wrapper,
} from "./styles";
import { ResourceRow } from "../ResourceRow";
import { useSortByCreatedDate } from "../../../../hooks/useSortByDate";
import { Dataset } from "../../../../pages/Resources/pages/PublicResources/pages/SearchDataById/interfaces/companies.interface";

interface Props {
  data: Dataset[];
  disableBorder?: boolean
  disableRoundingTop?: boolean
  showDatasetData?: boolean
  setShowDatasetData?: React.Dispatch<React.SetStateAction<boolean>>
}
export const FilterDatasetList: React.FC<Props> = ({ setShowDatasetData, showDatasetData, data, disableBorder, disableRoundingTop }) => {
  const { sortItemsByCreatedDate } =
    useSortByCreatedDate("desc");

  const sortedItems = sortItemsByCreatedDate(data ? data : []);
  return (
    <DatasetsTable $disableBorder={disableBorder} $disableRoundingTop={disableRoundingTop}>
      <HeadingWrapper $disableRoundingTop={disableRoundingTop}>
        <Name>Name</Name>
        {!showDatasetData && (
          <>
            <Dimensions>Dimensions</Dimensions>
            <UploadedOn>
              Status
            </UploadedOn>
          </>
        )}

      </HeadingWrapper>
      <Wrapper>
        {sortedItems?.map((dataset: Dataset) => {
          return (
            <ResourceRow
              variables={dataset?.variables}
              showDatasetData={showDatasetData}
              setShowDatasetData={setShowDatasetData}
              id={dataset.id}
              title={dataset?.title}
              key={dataset.id} 
              type={dataset?.type} 
              dataProviderId={dataset?.dataProviderId} 
              base_url={dataset?.base_url} 
              topicId={dataset?.topicId} 
              filters={dataset?.filters}            
              />
          )
        })}
      </Wrapper>
    </DatasetsTable>
  );
};
