import React, { useState } from "react";

import {
  CheckBoxWrapper,
  DatasetsTable,
  Details,
  Dimensions,
  HeadingWrapper,
  Name,
  Provider,
  UploadedOn,
} from "./styles";
import { ResourceRow } from "../ResourceRow";
import { DatasetsResponseListDto, NewDatasetDTO } from "../../../../models/Datasets";
import { useSortByCreatedDate } from "../../../../hooks/useSortByDate";
import { CheckBox } from "../../../Inputs/CustomCheckbox/CheckBox";
interface Props { 
  data: DatasetsResponseListDto; 
  disableBorder?: boolean
  disableRoundingTop?: boolean
 }
export const ResourcesList: React.FC<Props> = ({ data, disableBorder,disableRoundingTop }) => {
  const { sortItemsByCreatedDate } =
    useSortByCreatedDate("desc");
  const { items } = data;
  const sortedItems = sortItemsByCreatedDate(items ? items : []);
  const [checkAll, setCheckAll] = useState<boolean>(false)
  return (
    <DatasetsTable $disableBorder={disableBorder} $disableRoundingTop={disableRoundingTop}>
      <HeadingWrapper $disableRoundingTop={disableRoundingTop}>
        <CheckBoxWrapper>
          <CheckBox
            checked={checkAll}
            onChange={(e) => setCheckAll(e.target.checked)}
          />
        </CheckBoxWrapper>
        <Name>Name</Name>
        <Dimensions>Dimensions</Dimensions>
        <UploadedOn>
          Uploaded on
        </UploadedOn>
        <Provider>Provider</Provider>
        <Details></Details>
      </HeadingWrapper>
      {sortedItems?.map((dataset: NewDatasetDTO) => {
        return (
          <ResourceRow
            id={dataset.id}
            name={dataset.name}
            createdAt={dataset.createdAt}
            fileFormat={dataset?.filePath?.split('.')?.pop()}
            fields={dataset.fields}
            filePath={dataset.filePath}
            updatedAt={dataset.createdAt}
            selectAll={checkAll}
            key={dataset.id}
          />
        )
      })}
    </DatasetsTable>
  );
};
