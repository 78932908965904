import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowLeft, ArrowRight } from "react-bootstrap-icons";

import * as Styles from "./styles";
import svg from './svg.svg';

import { Search } from "../../../../../../components/SearchBar";
import { BreadCrumbs } from "../../../../../../components/Breadcrumbs";
import { useSelector } from "react-redux";
import { getDataProvidersList } from "../../../../../../store/selectors/dataProviders";
import { useDispatch } from "react-redux";
import { requestGetDataProviders } from "../../../../../../store/slices/dataProviders";

interface Props { }

export const SearchData: React.FC<Props> = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const dataProvidersList = useSelector(getDataProvidersList)

  useEffect(() => {
    if (!dataProvidersList.length) {
      dispatch(requestGetDataProviders({}))
    }
  }, [dataProvidersList, dispatch])

  const onClick = (id: string) => {
    navigate('/resources/public-resources/search/' + id)
  }

  return (
    <Styles.SearchDataPageWrapper>
      <Styles.SearchDataPageHeader>
        <BreadCrumbs
          breadcrumbs={[
            { name: 'Public Datasets', link: '/resources/public-resources', Icon: ArrowLeft },
          ]}
        />
        <Styles.SearchDataPageHeaderWrapper>
          <Styles.SearchDataPageHeaderTitle>Search Data</Styles.SearchDataPageHeaderTitle>
        </Styles.SearchDataPageHeaderWrapper>
      </Styles.SearchDataPageHeader>
      <Styles.SearchDataPageContent>
        <Styles.SearchInputWrapper>
          <Search placeholder="Filter by name" />
        </Styles.SearchInputWrapper>
        <Styles.SearchDataPageCardsWrapper>
          {dataProvidersList?.map((company) => {
            if (company.id !== 'census') {
              return '';
            }

            return (
              <Styles.SearchDataPageCard>
                <Styles.SearchDataCardButton onClick={() => { onClick(company.id) }}>
                  View data
                  <ArrowRight />
                </Styles.SearchDataCardButton>
                <Styles.CardTop>
                  <Styles.Img src={svg} alt="" />
                  <Styles.CardTopTitle>{company.name === 'census'? 'U.S. Census Bureau Data' : company.name}</Styles.CardTopTitle>
                  <Styles.CardTopSubTitle>Offers detailed demographic, economic, and geographic data, including population counts, household statistics, and economic activity.</Styles.CardTopSubTitle>
                </Styles.CardTop>
                <Styles.SeparatorLine />
                <Styles.CardBottom>
                  <Styles.CardBottomTextWrapper>
                    <Styles.CardBottomText>
                      Housing Data
                    </Styles.CardBottomText>
                    <Styles.CardBottomDot />
                    <Styles.CardBottomText>
                      USA
                    </Styles.CardBottomText>
                  </Styles.CardBottomTextWrapper>
                  <Styles.CardBottomTextInfo>
                    {/*TO DO: svg should be replaced with icon from our design system */}
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6 9H4V10H6V9Z" fill="#5F6877" />
                      <path d="M9 9H7V10H9V9Z" fill="#5F6877" />
                      <path d="M6 7H4V8H6V7Z" fill="#5F6877" />
                      <path d="M9 11H7V12H9V11Z" fill="#5F6877" />
                      <path d="M12 7H10V8H12V7Z" fill="#5F6877" />
                      <path d="M12 11H10V12H12V11Z" fill="#5F6877" />
                      <path d="M13.5 1.5H2.5C2.23489 1.50033 1.98072 1.60579 1.79326 1.79326C1.60579 1.98072 1.50033 2.23489 1.5 2.5V13.5C1.50033 13.7651 1.60579 14.0193 1.79326 14.2067C1.98072 14.3942 2.23489 14.4997 2.5 14.5H13.5C13.7651 14.4997 14.0193 14.3942 14.2067 14.2067C14.3942 14.0193 14.4997 13.7651 14.5 13.5V2.5C14.4997 2.23489 14.3942 1.98072 14.2067 1.79326C14.0193 1.60579 13.7651 1.50033 13.5 1.5ZM13.5 2.5V4.5H2.5V2.5H13.5ZM2.5 13.5V5.5H13.5V13.5H2.5Z" fill="#5F6877" />
                    </svg>
                    {/*TO DO: svg should be replaced with icon from our design system */}

                    1,000+
                  </Styles.CardBottomTextInfo>
                </Styles.CardBottom>
              </Styles.SearchDataPageCard>
            )
          })}
        </Styles.SearchDataPageCardsWrapper>
      </Styles.SearchDataPageContent>
    </Styles.SearchDataPageWrapper>
  );
};
