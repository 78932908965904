import React, { useEffect, useRef } from "react";

import {
  Chip,
  ChipsWrapper,
  ContentSeparator,
  ContentWrapper,
  Description,
  DescriptionInput,
  DetailsWrapper,
  Title,
  UseCase,
} from "./styles";
import { ProjectTypeEnum } from "../Modals/CreateProject";

type ProjectDetailsProps = {
  setName: (name: string) => void;
  setDescription: (description: string) => void;
  setProjectType: (projectType: keyof typeof ProjectTypeEnum) => void;
  name: string;
  description: string;
  projectType: string;
};

export const ProjectDetails = ({
  setName,
  setDescription,
  setProjectType,
  name,
  description,
  projectType,
}: ProjectDetailsProps) => {
  const ref = useRef<HTMLTextAreaElement>(null);
  const titleRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    const textarea = ref.current;
    if (textarea) {
      textarea.style.maxHeight = "72px";
      textarea.style.height = "14px";
      textarea.style.height = textarea.scrollHeight + "px";
    }
  }, [description]);

  useEffect(() => {
    const textarea = titleRef.current;
    if (textarea) {
      textarea.style.maxHeight = "78px";
      textarea.style.height = "14px";
      textarea.style.height = textarea.scrollHeight + "px";
    }
  }, [name]);

  return (
    <DetailsWrapper>
      <ContentWrapper>
        <Title
          title="Name your project"
          placeholder="Name your project"
          onChange={(e) => setName(e.target.value)}
          value={name}
          ref={titleRef}
          wrap="hard"
          maxLength={48}
        />
        <Description>Description</Description>
        <DescriptionInput
          placeholder="Add description"
          onChange={(e) => setDescription(e.target.value)}
          ref={ref}
          value={description}
          maxLength={248}
        />
        <UseCase>
          Use case
        </UseCase>
        <ChipsWrapper>
          <Chip $selected={projectType === 'basic'} onClick={() => { setProjectType('basic') }}>Basic</Chip>
          <Chip $selected={projectType === 'real_estate'} onClick={() => { setProjectType('real_estate') }}>Real Estate</Chip>
          {/* <Chip>Healthcare</Chip> */}
        </ChipsWrapper>
      </ContentWrapper>
      <ContentSeparator />
    </DetailsWrapper>
  );
};
