import React, { useState } from "react";
import {
  Advanced,
  AdvancedInfo,
  Arrow,
  CheckBoxWrapper,
  Container,
  Info,
  Text,
} from "./styles";
import { FormConnectAPIGroup } from "../FormGroup";
import { CheckBox } from "../../../../Inputs/CustomCheckbox/CheckBox";
import { Select } from "../../../../Inputs/CustomSelect/Select";

type Props = {
  handleChange: any;
  handleBlur: any;
  values: any;
  setFieldValue: any;
};

const DigestAuth = ({
  handleChange,
  handleBlur,
  values,
  setFieldValue,
}: Props) => {
  const [advancedConfig, setAdvancedConfig] = useState<boolean>(false);

  return (
    <Container>
      <Info>
        By default, Postman will extract values from the received response, add
        it to the request, and retry it. Do you want to disable this?
      </Info>
      <CheckBoxWrapper>
        <CheckBox
          onChange={(e) => setFieldValue("disableRetrying", e.target.checked)}
          checked={values.disableRetrying}
        />
        <Text>Yes, disable retrying the request</Text>
      </CheckBoxWrapper>

      <FormConnectAPIGroup
        handleChange={handleChange}
        handleBlur={handleBlur}
        placeholder={"Enter username"}
        type={"text"}
        name={"username"}
        values={values.username}
        label={"Username"}
        id={"username"}
      />
      <FormConnectAPIGroup
        handleChange={handleChange}
        handleBlur={handleBlur}
        placeholder={"Enter password"}
        type={"password"}
        name={"password"}
        values={values.password}
        label={"Password"}
        id={"password"}
      />

      <Advanced>
        Advanced configuration
        <Arrow
          $active={advancedConfig}
          onClick={() => setAdvancedConfig((prev) => !prev)}
        />
      </Advanced>

      {advancedConfig && (
        <>
          <AdvancedInfo>
            Postman auto-generates default values for some of these fields
            unless a value is specified.
          </AdvancedInfo>

          <FormConnectAPIGroup
            handleChange={handleChange}
            handleBlur={handleBlur}
            placeholder={"testrealm@example.com"}
            type={"text"}
            name={"realm"}
            values={values.realm}
            label={"Realm"}
            id={"realm"}
          />

          <FormConnectAPIGroup
            handleChange={handleChange}
            handleBlur={handleBlur}
            placeholder={"Nonce"}
            type={"text"}
            name={"nonce"}
            values={values.nonce}
            label={"Nonce"}
            id={"nonce"}
          />

          <Select
            background="white"
            options={[
              {
                value: "MD5",
                option: "MD5",
              },
            ]}
            value={values.algorithm}
            handleChange={(value) => setFieldValue("algorithm", value)}
            label="Algorithm"
            withOutClose
          />

          <FormConnectAPIGroup
            handleChange={handleChange}
            handleBlur={handleBlur}
            placeholder={"e.g. auth-int"}
            type={"text"}
            name={"qop"}
            values={values.qop}
            label={"qop"}
            id={"qop"}
          />

          <FormConnectAPIGroup
            handleChange={handleChange}
            handleBlur={handleBlur}
            placeholder={"e.g. 00000001"}
            type={"text"}
            name={"nonceCount"}
            values={values.nonceCount}
            label={"Nonce Count"}
            id={"nonceCount"}
          />

          <FormConnectAPIGroup
            handleChange={handleChange}
            handleBlur={handleBlur}
            placeholder={"e.g. 0a4f113b"}
            type={"text"}
            name={"clientNonce"}
            values={values.clientNonce}
            label={"Client Nonce"}
            id={"clientNonce"}
          />

          <FormConnectAPIGroup
            handleChange={handleChange}
            handleBlur={handleBlur}
            placeholder={"Opaque"}
            type={"text"}
            name={"opaque"}
            values={values.opaque}
            label={"Opaque"}
            id={"opaque"}
          />
        </>
      )}
    </Container>
  );
};

export default DigestAuth;
