import styled from "styled-components";

export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DatasetWrapper = styled.div<{ $full?: boolean }>`
  display: block;
  margin: 0 auto;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 20px 0 20px;
`

export const Title = styled.textarea`
  border: none;
  outline: none;
  font-size: 32px;
  font-weight: 600;
  resize: none;
  overflow: hidden;
  color: ${({ theme }) => theme.neutralText};

  &::placeholder {
    color: ${({ theme }) => theme.disabledText};
  }
  &:hover::placeholder {
    color: ${({ theme }) => theme.neutralTextWeak};
  }
`;

export const UploadedItemsHeader = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.neutralBorder};
  padding: 8px 20px;
  display: flex;
  gap: 10px;
`

export const FileName = styled.p`
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  color: ${({ theme }) => theme.neutralWeak};
`

export const Description = styled.span`
  font-size: 14px;
  font-weight: 500;
  padding: 8px 0 4px 0;
  color: ${({ theme }) => theme.neutralText};
`;

export const UseCase = styled.span`
  font-size: 14px;
  font-weight: 500;
  padding: 6px 0;
  line-height: 20px;
  color: ${({ theme }) => theme.neutralText};
`;

export const ChipsWrapper = styled.div`
  display: flex;
  gap: 4px;
`

export const Chip = styled.div<{ $selected?: boolean }>`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 6px;
  border-radius: 100px;
  color: #000417;
  background: ${({ $selected }) => $selected ? "#6F6FE93D" : "#EFF0F1"};
  border: 1px solid ${({ $selected }) => $selected ? "#7D82F8" : "#DFE1E4"};
  font-size: 12px;
  line-height: 16px;
  transition: .15s all;
  cursor: pointer;
  ${({ $selected }) => !$selected ? `
    &:hover {
      background: #DFE1E4;
      border: 1px solid #CFD2D6;
    }
    &:active {
      background: #DFE1E4;
      border: 1px solid #AFB3BB;
    }
  ` : ""}
`

export const DescriptionInput = styled.textarea`
  border: none;
  outline: none;
  position: relative;
  font-size: 14px;
  font-weight: 400;
  resize: none;
  overflow: hidden;

  &::placeholder {
    color: ${({ theme }) => theme.disabledText};
  }
  &:hover::placeholder {
    color: ${({ theme }) => theme.neutralTextWeak};
  }
`;
export const ContentSeparator = styled.div`
  height: 1px;
  width: 100%;
  background: ${({ theme }) => theme.neutralBorder};
  margin-top: 24px;
`;

export const ImportedFilesContainer = styled.div<{ $noGap?: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: ${({ $noGap }) => ($noGap ? "" : "8px")};
`;
