import axios from "./axios";
import { AxiosError, AxiosResponse } from "axios";

import {
  CREATE_SECTIONS,
  GET_SECTIONS,
  PATCH_SECTION_BY_ID,
} from "./constants";
import { generateQueryString } from "../helpers/queryGenerator";
import { RequestCallbacks } from "../models";

export interface ISection {
  id: string;
  createdAt: string;
  updatedAt: string;
  pageId: string;
  title: string;
  blockId: string;
  description: string;
  image: string;
  align: string;
  textColor?: string;
  kpis?: string[];
  showTitle: boolean;
  showDescription: boolean;
  showImage: boolean;
  showKpi: boolean;
}

export interface ICreateSection {
  pageId: string;
  title: string;
  blockId: string;
  description: string;
  image: string;
  align: string;
  textColor?: string;
  kpis?: string[];
  showTitle: boolean;
  showDescription: boolean;
  showImage: boolean;
  showKpi: boolean;
  callbacks?: RequestCallbacks<ISection>;
}

export interface IUpdateSection {
  id: string;
  pageId: string;
  title?: string | undefined;
  blockId?: string | undefined;
  description?: string | undefined;
  image?: string | undefined;
  align?: string | undefined;
  textColor?: string;
  kpis?: string[];
  showTitle?: boolean;
  showDescription?: boolean;
  showImage?: boolean;
  showKpi?: boolean;
  callbacks?: RequestCallbacks<ISection>;
}

export interface QueryGetSections {
  pageId: string;
  limit?: number;
  callbacks?: RequestCallbacks<{ items: ISection[]; count: number }>;
  skip?: number;
}

export const getAllSections = async (query: QueryGetSections) => {
  const queryParams = generateQueryString<QueryGetSections>({
    ...query,
    limit: query?.limit || 20,
  });

  return axios
    .get(`${GET_SECTIONS}${queryParams}`)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));
};

export const getAllPublicSections = async (query: QueryGetSections) => {
  const queryParams = generateQueryString<QueryGetSections>({
    ...query,
    limit: query?.limit || 20,
  });

  return axios
    .get(`/public${GET_SECTIONS}${queryParams}`)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));
};

export const createSection = async (data: ICreateSection) => {
  return axios
    .post(CREATE_SECTIONS, data)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));
};

export const updateSection = async (data: IUpdateSection) => {
  return axios
    .patch(PATCH_SECTION_BY_ID.replace("{id}", data.id), data)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));
};

export const deleteSection = async (id: string) => {
  return axios
    .delete(PATCH_SECTION_BY_ID.replace("{id}", id))
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));
};
