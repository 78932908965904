import React, { useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";

import {
  Buttons,
  CloseIcon,
  Container,
  DownIcon,
  DropDownMenuItem,
  DropDownMenuList,
  DropDownSelect,
  Label,
  SelectContainer,
  SelectedValue,
} from "./style";
import { ISelect } from "./types";

type Props = {
  label?: string;
  options: ISelect[];
  value: string;
  withOutClose?: boolean;
  openTop?: boolean;
  width?: string;
  handleChange?: (value: any) => void;
  background?: string;
};

export const Select: React.FC<Props> = ({
  label,
  options,
  value,
  handleChange,
  openTop,
  width,
  withOutClose,
  background,
}: Props) => {
  const [selectedValue, setSelectedValue] = useState<ISelect | undefined>(
    options?.find((item) => item.value === value)
  );
  const [activeDropDown, setActiveDropDown] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement | null>(null);

  useOnClickOutside([ref], () => setActiveDropDown(false));

  const handleSelectItem = (item: ISelect) => {
    setSelectedValue(item);
    setActiveDropDown(false);
  };

  const DropDownMenuHeight = 33 * options.length + 16 + 43;

  return (
    <>
      <Container $width={width} ref={ref}>
        {label && <Label>{label}</Label>}
        <SelectContainer $background={background}>
          <SelectedValue onClick={() => setActiveDropDown((prev) => !prev)}>
            {selectedValue?.option || "Select value"}
          </SelectedValue>
          <Buttons>
            {!selectedValue && !withOutClose && (
              <CloseIcon
                onClick={() => setSelectedValue({ option: "", value: "" })}
              />
            )}
            <DownIcon
              $close={activeDropDown}
              onClick={() => setActiveDropDown((prev) => !prev)}
            />
          </Buttons>
        </SelectContainer>

        {!!activeDropDown && (
          <DropDownSelect
            $openTop={openTop}
            $label={!!label}
            $width={ref?.current?.offsetWidth ?? 300}
            $height={DropDownMenuHeight <= 259 ? DropDownMenuHeight : 259}
          >
            <DropDownMenuList>
              {options.map((item) => (
                <DropDownMenuItem
                  $selected={item.value === value}
                  onClick={() => {
                    handleSelectItem(item);
                    if (handleChange) {
                      handleChange(item.value);
                    }
                  }}
                >
                  {item.option}
                </DropDownMenuItem>
              ))}
            </DropDownMenuList>
          </DropDownSelect>
        )}
      </Container>
    </>
  );
};
