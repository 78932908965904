export const createQueryYearFilter = (debouncedSelected: any) => {
  const selectedYears = debouncedSelected.map((yearObj: any) => yearObj.value);

  const filter = selectedYears.reduce((acc: any, year: any) => {
    const yearToNr = parseFloat(year);
    if (typeof yearToNr === "number" && !Number.isNaN(yearToNr)) {
      acc.push(yearToNr);
    }
    return acc;
  }, []);

  const query = 
    selectedYears?.length
      ? JSON.stringify({
          year: { $in: filter },
        })
      : "";

  return query;
};