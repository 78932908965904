import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProfile, IUpdateProfile } from "../../models/Widgets";

interface IProfilesState {
  profile: IProfile 
}

const initialState: IProfilesState = {
  profile: {
    id: "",
    uid: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    jobTitle: "",
    organization: "",
    location: "",
    officePhone: "",
    avatar: "",
    isAdmin: false,
    canAddProjects: false,
    autoAccessToFutureProjects: false,
    desktopNotifications: {
      importantAlerts: false,
      dashboardPublished: false,
      viewerEditor: false,
      playSoundOnReminder: false,
      statusUpdate: true
    },
    emailNotifications: {
      emailViewerEditor: false,
      emailDashboardCompleted: false,
      emailStatusUpdate: false
    },
  }
}

const profilesSlices = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setProfiles: (state: IProfilesState, action: PayloadAction<IProfile>) => {
      state.profile = {...state.profile, ...action.payload};
    },
    requestProfile: (state: IProfilesState, action: PayloadAction) => {},
    requestDeleteProfile: (state: IProfilesState, action: PayloadAction) => {},
    requestUpdateProfile: (state: IProfilesState, action: PayloadAction<IUpdateProfile>) => {},
  },  
},
);

export const {
  setProfiles,
  requestProfile,
  requestDeleteProfile,
  requestUpdateProfile
} = profilesSlices.actions;

export default profilesSlices.reducer;