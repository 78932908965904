
import { Check } from "react-bootstrap-icons";
import { Button } from "../../Button";
import LoaderDots from "../ConnectApiModal/components/Loader";
import { ModalOverlay } from "../styles";

import {
  ModalHeaderWrapper,
  ModalTitle,
  ModalWrapper,
  CloseIcon,
  ModalContent,
  ModalContentText,
  ModalFooterWrapper
} from "./styles";

export const ProgressModal = ({ onClose }: { onClose: () => void }) => {

  return (
    <ModalOverlay onClick={onClose}>
      <ModalWrapper onClick={(e) => e.stopPropagation()}>
        <ModalHeaderWrapper>
          <ModalTitle>Test</ModalTitle>
          <CloseIcon
            onClick={() => {
              onClose();
            }}
          />
        </ModalHeaderWrapper>
        <ModalContent>
          <LoaderDots disableAbsolute />
          <ModalContentText>
            Establishing a secure connection and retrieving data. <br />Please wait...
          </ModalContentText>
        </ModalContent>
        <ModalFooterWrapper>
          <Button name="Cancel" onClick={() => { }} variant="secondary" size="medium" />
          <Button name="Done" icon={<Check />} disabled onClick={() => { }} variant="secondary" size="medium" />
        </ModalFooterWrapper>
      </ModalWrapper>
    </ModalOverlay>
  );
};
