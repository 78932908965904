import styled from 'styled-components';
import { PlusCircleDotted } from 'react-bootstrap-icons';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  gap: 8px;
`

export const SelectHeading = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 8px;
`

export const AxeIcon = styled.div<{
  $Axe?: 'X' | 'Y'
}>`
  display: ${({$Axe}) => $Axe ? 'block' : 'none'};
  width: 12px;
  height: 12px;
  border: 1px dashed ${({theme}) => theme.neutralTextWeak};

  ${({theme, $Axe}) => $Axe?`
    ${$Axe === 'X' ? 
      `border-bottom: 2px solid ${theme.neutralTextWeak};` 
        : 
      $Axe === 'Y' ? `border-left: 2px solid ${theme.neutralTextWeak};`: ''}
  `:``}
`


export const AddIcon = styled(PlusCircleDotted)<{
  $Show?: boolean
}>`
   ${({$Show}) => !$Show?`
    display: none;
  `:``}
`


export const DisplayIcon = styled.div<{
  $Show?: boolean
}>`
  ${({$Show}) => !$Show?`
    display: none;
  `:``}
  width: 12px;
  position: relative;
  height: 12px;
  border-left: 1px dashed ${({theme}) => theme.neutralTextWeak}; 
  border-right: 1px dashed ${({theme}) => theme.neutralTextWeak}; 
  &::after { 
    content: "";
    position: absolute;
    width: 2px;
    height: 12px;
    background: ${({theme}) => theme.neutralTextWeak}; 
    left: 50%;
    transform: translateX(-50%);
    top: 0;
  }
`

export const Lable = styled.p`
  font-size: 14px;
  color: ${({theme}) => theme.neutralText};
  font-weight: 400;
`

export const SubLable = styled.p`
  margin-left: 11px;
  font-size: 14px;
  font-weight: 400;
  color: ${({theme}) => theme.neutralTextWeak};
  position: relative;

  &:before {
    content: '';
    position: absolute; 
    left: -11px; 
    top: 50%; 
    transform: translateY(-50%); 
    width: 3px; 
    height: 3px; 
    background: ${({theme}) => theme.neutralTextWeak}; 
    border-radius: 50%; 
  }
`

export const AcceptedTypes = styled.div`
  display: flex;
  gap: 4px;
  margin-left: auto;
`

export const DropArea = styled.div<{
  $isOver: boolean;
  $isEmpty: boolean;
}>`
  transition: 0.3s;
  width: 100%;
  padding: 4px;
  border-radius: 5px;
  text-align: center;
  border: 1px ${({ $isOver }) => $isOver? 'solid' : 'dashed'}  ${({ theme, $isEmpty }) => $isEmpty? theme.primaryBorder : theme.neutralBorder};
  background: ${({ theme, $isEmpty }) => $isEmpty? theme.primaryBackground : 'transparent' };
  color: ${({theme}) => theme.primaryBorder};
  display: flex;
  align-items: center;
  gap: 4px;
  flex-flow: wrap;
  ${({theme, $isOver }) => !!$isOver && `
    background: ${theme.primaryBackgroundMedium};
    color: ${theme.primaryBorder};
  `}
`

export const EmptySelectText = styled.p`
  width: 100%;
  height: 24px;
  display:flex;
  align-items: center;
  justify-content: center;
`

