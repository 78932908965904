import React, { ChangeEvent, useState } from "react";
import { ErrorMsg, FieldWrapper, Input, Label, RequiredStar } from "./styles";

type FormGroupProps = {
  label?: string;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleBlur: React.ChangeEventHandler;
  placeholder: string;
  type: string;
  name: string;
  values: string | number;
  id: string;
  errors?: string;
  touched?: boolean;
  required?: boolean;
  disableMargin?: boolean;
  pattern?: string;
};

export const FormConnectAPIGroup: React.FC<FormGroupProps> = ({
  label,
  handleChange,
  handleBlur,
  placeholder,
  type,
  name,
  values,
  errors,
  touched,
  id,
  required,
  disableMargin,
  pattern,
}) => {
  const [hasValue, setHasValue] = useState(!!values);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleChange(e);
    setHasValue(!!e.target.value);
  };

  return (
    <FieldWrapper $disableMargin={disableMargin}>
      {!!label && <Label>{label}</Label>}
      {required && !hasValue ? <RequiredStar>*</RequiredStar> : ""}
      <Input
        pattern={pattern}
        inputMode="numeric"
        $error={!!(errors && touched)}
        $hasValue={hasValue}
        $required={required && !hasValue}
        id={id}
        type={id === "password" ? "text" : type}
        name={name}
        onChange={handleInputChange}
        onBlur={handleBlur}
        placeholder={`${placeholder}`}
        value={values}
      />
      {errors && touched && <ErrorMsg>{errors}</ErrorMsg>}
    </FieldWrapper>
  );
};
