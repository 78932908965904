import { styled } from "styled-components";

export const Legend = styled.div`
  position: relative;
  background: transparent;
  z-index: 100;
  padding:6px 2px;
`;

export const RangeInput = styled.input.attrs((props) => ({
  type: 'range',
}))`
  position: absolute;
  background: transparent;
  height: 0;
  width: 100%;
  -webkit-appearance :none;
  -moz-appearance :none;
  appearance :none;
  background: none;
  cursor: pointer;
  
  /* Firefox */
  &::-moz-range-track {
    width: 100%; 
    height: 0px; 
    cursor: pointer;
    background: transparent;
    position: relative;
    z-index: 1;
  }

  &::-moz-range-thumb {
    height: 8px; 
    width: 8px; 
    border: 2px solid white;
    border-radius: 50%;
    position: relative; /* Changed from absolute */
    background-color: black;
    cursor: pointer;
    &:hover{
      background-color: white;
      border: 2px solid black;
    }
    &:active{
      background-color: white;
      border: 2px solid black;
    }
  }

  /* WebKit (Chrome, Safari) */
  &::-webkit-slider-runnable-track {
    width: 100%; 
    height: 0px; 
    cursor: pointer;
    background: transparent;
    position: relative;
    z-index: 1;
  }

  &::-webkit-slider-thumb{
    position: relative;
    top:-6px;
    height: 12px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: black;
    box-shadow: 0 0 0 2px inset white;
    -webkit-appearance: none;
    appearance: none;
    &:hover{
      background-color: white;
      border: 2px solid black;
    }
    &:active{
      background-color: white;
      border: 2px solid black;
    }
  }

  /* Microsoft Edge and Internet Explorer */
  &::-ms-track {
    width: 100%;
    height: 5px; 
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  &::-ms-thumb {
    height: 8px; 
    width: 8px; 
    border: 2px solid white;
    border-radius: 50%;
    background-color: black;
    cursor: pointer;
    &:hover{
      background-color: white;
      border: 2px solid black;
    }
    &:active{
      background-color: white;
      border: 2px solid black;
    }
  }

  &::-ms-fill-lower {
    background: transparent;
  }

  &::-ms-fill-upper {
    background: transparent;
  }
`;