import React from "react";
import { Lock, ShieldLock } from "react-bootstrap-icons";

import {
  Arrow,
  SecurityButton,
  ProfileSideBarAboutCardDescription,
  ProfileSideBarAboutCardDetailsWrapper,
  ProfileSideBarAboutCardIcon,
  ProfileSideBarAboutCardTitle,
  ProfileSideBarAboutCardWrapper,
  ProfileSideBarHeading,
  ProfileSideBarSectionWrapper,
  Slash,
} from "./styles";
import { InfoIcon } from "../../pages/Profile/styles";
import { useDispatch } from "react-redux";
import { setActiveModal } from "../../store/slices/modals";

export const SecuritySection = () => {
  const dispatch = useDispatch();

  return (
    <ProfileSideBarSectionWrapper>
      <ProfileSideBarHeading>Security</ProfileSideBarHeading>
      <ProfileSideBarAboutCardWrapper>
        <ProfileSideBarAboutCardIcon>
          <Lock />
        </ProfileSideBarAboutCardIcon>
        <ProfileSideBarAboutCardDetailsWrapper>
          <ProfileSideBarAboutCardTitle>Password</ProfileSideBarAboutCardTitle>
          <ProfileSideBarAboutCardDescription
            onClick={() => {
              dispatch(
                setActiveModal({ id: "uploadProfileModal", firstItem: 3 })
              );
            }}
          >
            <SecurityButton>
              <Slash />
              <Arrow /> Change Password <Slash />
              <Arrow />
            </SecurityButton>
          </ProfileSideBarAboutCardDescription>
        </ProfileSideBarAboutCardDetailsWrapper>
      </ProfileSideBarAboutCardWrapper>
      <ProfileSideBarAboutCardWrapper>
        <ProfileSideBarAboutCardIcon>
          <ShieldLock />
        </ProfileSideBarAboutCardIcon>
        <ProfileSideBarAboutCardDetailsWrapper>
          <ProfileSideBarAboutCardTitle>
            Two-Factor Authentication <InfoIcon />
          </ProfileSideBarAboutCardTitle>
          <ProfileSideBarAboutCardDescription>
            <SecurityButton>
              <Slash />
              <Arrow /> Enable <Slash />
              <Arrow />
            </SecurityButton>
          </ProfileSideBarAboutCardDescription>
        </ProfileSideBarAboutCardDetailsWrapper>
      </ProfileSideBarAboutCardWrapper>
    </ProfileSideBarSectionWrapper>
  );
};
