import styled from "styled-components";
import { Trash } from "react-bootstrap-icons";

export const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  flex-flow: wrap;
`;
export const FileName = styled.div<{
  $previewSelected?: boolean
}>`
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.neutralText};
  ${({$previewSelected, theme}) => $previewSelected && `
    max-width: 350px;
    line-height: 18px;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &::before {
      content: "";
      position: absolute;
      left: 1px;
      top: 6px;
      width: 3px;
      height: 3px;
      border-radius: 50%;
      background-color: ${theme.neutralTextWeak};
    }
  `}

`;

export const DividerDot = styled.div`
  height: 3px;
  width: 3px;
  border-radius: 50%;
  background: ${({ theme }) => theme.neutralTextWeak};
  margin: 0 4px;
`;

export const RemoveButton = styled.div`
  display: flex;
  color: ${({ theme }) => theme.alertText};
  font-size: 14px;
  font-weight: 500;
  height: 20px;
  align-items: center;
  cursor: pointer;
`;

export const Slash = styled.div`
  height: 1px;
  width: 20px;
  background: ${({ theme }) => theme.alertText};
  transform: rotate(-75deg);
`;

export const TrashIcon = styled(Trash)`
  height: 20px;
  width: 20px;
  margin-left: 4px;
`;
