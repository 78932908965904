import { IFormatting, WidgetItem } from "../../../../models/Widgets";

export const BubbleChartGroupedData = (
  currentWidgetData?: WidgetItem,
  bubbleChartSuggestion?: any
) => {
  const groupBy = currentWidgetData?.groupBy?.[0];

  const xAxe = currentWidgetData?.xAxe?.[0] ?? bubbleChartSuggestion?.xAxe?.[0];

  const yAxe = currentWidgetData?.yAxe?.[0] ?? bubbleChartSuggestion?.yAxe?.[0];

  if (groupBy) {
    return currentWidgetData?.data?.reduce((acc, obj) => {
      const group = obj[groupBy];
      if (!acc[group]) {
        acc[group] = [];
      }
      acc[group].push({
        x: obj[xAxe] ?? obj.year,
        y: obj[yAxe] ?? obj.value,
      });
      return acc;
    }, {});
  } else {
    return {
      default: currentWidgetData?.data?.map((l) => ({
        x: l[xAxe] ?? l.year,
        y: l[yAxe] ?? l.value,
      })),
    };
  }
};

export const getBubbleChartDefaultColors = (data: any): IFormatting[] => {
  const groupBy = data.groupBy?.[0];

  if (!groupBy) {
    return [{ key: "default", color: "1" }];
  }

  const keys = data.uniqueValues[groupBy] || [];

  return keys.map((item: string, index: number) => ({
    key: item,
    color: String(index + 1),
  }));
};
