import { XLg } from "react-bootstrap-icons";
import styled from "styled-components";


export const ModalWrapper = styled.div`
  background-color: ${({ theme }) => theme.neutralBackgroundBase};
  border-radius: 12px;
  width: 496px;
  position: absolute;
  right: 50%;
  left: 50%;
  transform: translate(-50%, 0);
`;

export const ModalHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 16px 20px;
  border-bottom: 1px solid #DFE1E4;
`;

export const ModalFooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  border-top: 1px solid #DFE1E4;
`;

export const ModalTitle = styled.div`
  display: flex;
  flex: 1;
  color: ${({ theme }) => theme.neutralText};
  font-weight: 600;
  font-size: 16px;
`;

export const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex-direction: column;
  padding: 32px 16px;
`;

export const ModalContentText = styled.div`
  text-align: center;
  color: #5F6877;
  font-size: 14px;
  line-height: 20px;
`;

export const CloseIcon = styled(XLg)`
  width: 36px;
  height: 36px;
  padding: 8px;
  background: #9FA4AD1F;
  border-radius: 50%;
`;
