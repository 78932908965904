import { SearchRealEstateMap } from "./components/SearchRealEstateMap";
import { PageTitle, PageDescription, PageHeroContainer } from "./styles";
import SearchRealEstate from "../../components/SearchRealEstate";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { requestProjectById } from "../../store/slices/projects";
import { getCurrentProjectData } from "../../store/selectors/projects";

const RealEstateExplore = () => {
  // get the project id from url
  const dispatch = useDispatch();
  const { id: projectId } = useParams();
  // get/fetch the project by projectId
  const curentProject = useSelector(getCurrentProjectData);

  useEffect(() => {
    if (projectId) {
      dispatch(requestProjectById(projectId));
    }
  }, [projectId, dispatch]);

  const houses = curentProject?.files?.map((file) => {
    return {
      latitude: file.mapBoxConfig?.coordinates[1] ?? -32.967575,
      longitude: file.mapBoxConfig?.coordinates[0] ?? 151.65889,
      rotateX: file.mapBoxConfig?.rotateX ?? 0,
      rotateY: file.mapBoxConfig?.rotateY ?? 0,
      rotateZ: file.mapBoxConfig?.rotateZ ?? 0,
      scale: file.mapBoxConfig?.scale ?? 1,
    };
  })

  return (
    <div style={{height: 'calc(100vh - 64px - 68px - 160px)'}}>
      <PageHeroContainer>
        <PageTitle>
          Start exploring by searching location you are interested
        </PageTitle>
        <PageDescription>
          Start exploring by entering an exact address, ZIP code, city, or
          neighborhood to find detailed analytics related to housing, rental
          prices, population trends, and more.
        </PageDescription>
        <SearchRealEstate />
      </PageHeroContainer>
      <SearchRealEstateMap houses={houses || []} />
    </div>
  );
};

export default RealEstateExplore;
