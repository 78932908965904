import React from "react";
import {
  Checkmark,
  Input,
  Container,
  RadioButtonWrapper,
  Lable,
} from "./styles";

type Props = {
  size?: "M" | "S";
  name: string;
  id?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  value: string;
  checked?: boolean;
  disabled?: boolean;
};

const RadioButton = ({
  id,
  name,
  size,
  label,
  value,
  checked,
  disabled,
  onChange = () => {},
}: Props) => {
  return (
    <RadioButtonWrapper>
      <Container>
        <Input
          disabled={disabled}
          type="radio"
          name={name}
          onChange={onChange}
          value={value}
          checked={checked}
        />
        <Checkmark />
      </Container>
      <Lable $disabled={disabled}>{label}</Lable>
    </RadioButtonWrapper>
  );
};

export default RadioButton;
