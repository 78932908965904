import styled from "styled-components";
import {
  autoWidth,
  buttonActiveColors,
  buttonColors,
  buttonHoverColors,
  buttonLine,
  textColor,
} from "../../helpers/stylesHelpers";

export const VerticalLine = styled.div<{
  $variant?:
  | "primary"
  | "secondary"
  | "neutral"
  | "neutral-color"
  | "disabled"
  | "danger";
}>`
  border-left: 1px solid ${({ theme, $variant }) => buttonLine(theme, $variant)};
  height: 16px;
  position: relative;
  opacity: 0.3;
  transition: all 0.15s linear;
`;

export const CustomButton = styled.button<{
  $disabled?: boolean;
  $variant?:
  | "primary"
  | "secondary"
  | "neutral"
  | "neutral-color"
  | "disabled"
  | "danger";
  $neutral?: boolean;
  $fullWidth?: boolean;
  $size?: "xs" | "small" | "medium" | "large" | "full";
  $width?: string;
  $round?: boolean;
}>`
  display: flex;
  align-items: center;
  place-self: center;
  border: none;
  font-size: ${({ $size }) => ($size === "xs" ? "12px" : "14px")};
  line-height: 14px;
  font-weight: 500;
  max-height: ${({ $size }) =>
    $size === "full" ? "40px" : $size ? autoWidth($size) : "auto"};
  height: ${({ $size }) =>
    $size === "full" ? "40px" : $size ? autoWidth($size) : "auto"};
  width: ${({ $round, $size, $fullWidth }) =>
    $round ? autoWidth($size) : ($size === "full" || !!$fullWidth) ? "100%" : "auto"};
  padding: ${({ $size }) => ($size === "small" ? "10px" : $size === "xs" ? "4px 6px" : "14px")};
  border-radius: 20px;
  justify-content: center;
  cursor: pointer;
  background-color: ${({ theme, $variant }) => buttonColors(theme, $variant)};
  color: ${({ theme, $variant }) => textColor(theme, $variant)};
  opacity: ${({ $disabled }) => ($disabled ? "0.6" : "1")};
  transition: all 0.15s linear;
  svg path {
    fill: ${({ theme, $variant }) => textColor(theme, $variant)};
  }
  &:hover {
    background-color: ${({ theme, $variant }) =>
    buttonHoverColors(theme, $variant)};
    color: ${({ theme, $variant }) =>
    $variant === "primary" ? theme.primaryTextHover : $variant === "secondary" && theme.primaryTextStrongHover};

    ${VerticalLine} {
      height: ${({ $size }) => autoWidth($size)};
      border-left: 1px solid ${({ theme, $variant }) => $variant === "primary" ? theme.primaryTextHover : $variant === "secondary" && theme.neutralBorderMedium};
    }

    &:active {
      background-color: ${({ theme, $variant }) =>
    buttonActiveColors(theme, $variant)};
      color: ${({ theme, $variant }) =>
    $variant === "secondary" && theme.primaryTextStrongActive};
      fill: ${({ theme, $variant }) =>
    $variant === "secondary" && theme.primaryTextStrongActive};

      svg path {
        fill: ${({ theme, $variant }) =>
    $variant === "secondary" && theme.primaryTextStrongActive};
      }
      ${VerticalLine} {
      height: ${({ $size }) => autoWidth($size)};
      border-left: 1px solid ${({ theme, $variant }) => $variant === "primary" ? theme.primaryTextHover : $variant === "secondary" && theme.neutralBorderMedium};
    }
    }

    svg path {
      fill: ${({ theme, $variant }) =>
    $variant === "secondary" && theme.primaryTextStrongHover};
    }
  }

  ${({ theme, $variant }) =>
    $variant === "disabled" && `
      svg path {
        fill: ${theme.disabledText}
      }
    `};

  svg {
    height: 20px;
    width: 20px;

    ${({ theme, $variant }) =>
    $variant === 'neutral' && `
        fill: ${theme.neutralTextWeak};
    `};
  }
`;

export const ButtonContainer = styled.div<{ $full?: boolean }>`
  display: flex;
  gap: 20px;
  width: ${({ $full }) => ($full ? "100%" : "")};
`;

export const IconWrapper = styled.div<{ $small?: boolean }>`
  display: flex;
  gap: ${({ $small }) => ($small ? "6px" : "8px")};
  align-items: center;
`;
