import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";

import { store } from "../store";
import { requestRefreshToken } from "./auth";
import { setUpdateUserToken } from "../store/slices/auth";
import { ServerResponse } from "../models";
import { resetActiveModal } from "../store/slices/modals";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

instance.interceptors.request.use(
  async (req) => {
    const token = store.getState()?.auth?.user?.accessToken;
    req.headers.Authorization = `Bearer ${token}`;
    return req;
  },

  (error) => {
    toast.error(
      error
        ? `${error.message.charAt(0).toUpperCase() + error.message.slice(1)}`
        : "An error occurred."
    );
  }
);

instance.interceptors.response.use(
  (response) => response,
  async (error: AxiosError | any) => {
    const token = store.getState()?.auth?.user?.accessToken;
    const refreshToken = store.getState()?.auth?.user?.refreshToken;


    if (error.response?.status === 401 && error.response?.data?.message === "Current password is incorrect") {
      return {
        status: error.response.status,
        message: error.response?.data?.message
      };
    } else if (error.response?.status === 401 && token) {
      const { response }: ServerResponse = await requestRefreshToken(
        refreshToken!
      );

      store.dispatch(setUpdateUserToken(response?.data));
      error.config!.headers["Authorization"] =
        "Bearer " + response?.data.accessToken;

      return instance.request(error.config!);
    } else if (error.response?.status === 417) {
      store.dispatch(resetActiveModal() as any);
      toast.error("Your session expired, please re-login");
    } else if (error.response) {
      store.dispatch(resetActiveModal() as any);
      if (
        error.response.data?.message ===
        "token is invalid or was expired, re login to get fresh tokens"
      )
        return;
      toast.error(
        `${
          (error.response.data as any).message.charAt(0).toUpperCase() +
          (error.response.data as any).message.slice(1)
        }`
      );
    } else {
      store.dispatch(resetActiveModal() as any);

      toast.error(
        error
          ? `${error.message.charAt(0).toUpperCase() + error.message.slice(1)}`
          : "An error occurred."
      );
    }
    return Promise.reject(error);
  }
);

export default instance;
