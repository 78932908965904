import { styled } from "styled-components";

export const PositionHouseModalContent = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%
`;

export const RealEstateMapContainer = styled.div`
    height: calc(100% - 65px);
    position: relative;
`; 

export const PositionHouseModalBody = styled.div`
    display: grid;
    grid-template-columns: 25% 75%;
    width: 100%;
    height: 100%;
`;