import styled from "styled-components";
import { ChevronDown } from "react-bootstrap-icons";

export const LegendContainer = styled.div<{ open: boolean }>`
  min-width: 228px;
  border: 1px solid #dfe1e4;
  box-shadow: 0 2px 4px 1px #22242c0a;
  height: fit-content;
  padding-bottom: ${({ open }) => (open ? "8px" : "2px")};
  border-radius: 8px;
  position: absolute;
  background-color: #ffffff;
  right: 12px;
  bottom: 12px;
`;

export const LegendActions = styled.button`
  padding: 12px 12px 10px 12px;
  background-color: white;
  font-weight: 400;
  color: ${({ theme }) => theme.neutralTextWeak};
  font-size: 12px;
  line-height: 16px;
  width: 100%;
  outline: none;
  border-radius: 8px;
  border: none;
  text-align: left;
  cursor: pointer;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
`;

export const LegendActionIcon = styled(ChevronDown)<{ active: boolean }>`
  width: 12px;
  height: 12px;
  color: #2e3849;
  transform: rotate(${({ active }) => (active ? "180deg" : "0deg")});
  transition: transform 0.3s ease-in-out;
`;

export const LegendItemWrapper = styled.div`
  padding-top: 8.5px;
  padding-bottom: 8.5px;
`;
