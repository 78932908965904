import styled from "styled-components";
import { ArrowUp } from "react-bootstrap-icons";

export const DatasetWrapper = styled.div<{ $full?: boolean }>`
  display: block;
  margin: 0 auto;
  width: 100%;
`;

export const ImportedFilesContainer = styled.div<{ $noGap?: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: ${({ $noGap }) => ($noGap ? "" : "8px")};
`;

export const MyDataHeader = styled.div`
  width: 100%;
  background: #9FA4AD14;
  padding: 12px;
  min-height: 20px;
  display: flex;
`
export const CheckBoxWrapper = styled.div`
  margin: 0 8px;
`
export const Name = styled.div`
  color: #2E3849;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; 
  width: 600px;
  margin-left: 12px;
`

export const UploadedOn = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  color: #2E3849;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; 
  min-width: 120px;
  margin-left: 12px;
  margin-left: auto;
`

export const Arrow = styled(ArrowUp)`
  fill: #2C33C8;
  width: 16px;
  height: 16px;
  cursor: pointer;
`

export const Trash = styled.div`
  min-width: 20px;
  margin: 0 8px;
`

