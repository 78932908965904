import { WidgetItem } from "../../../../models/Widgets";

export const PunchcardChartGroupedData = (
  currentWidgetData?: WidgetItem,
  punchcardChartSuggestion?: any
) => {
  const uniqueValuesKeys = currentWidgetData?.uniqueValues
    ? Object.keys(currentWidgetData?.uniqueValues)
    : [];

  const groupBy =
    currentWidgetData?.groupBy?.[0] ?? currentWidgetData?.uniqueValues?.[0];

  const groupByKey: any = groupBy || uniqueValuesKeys?.[0];

  const xAxe =
    currentWidgetData?.xAxe?.[0] ?? punchcardChartSuggestion?.xAxe?.[0];
  const yAxe =
    currentWidgetData?.yAxe?.[0] ?? punchcardChartSuggestion?.yAxe?.[0];

  const mapData = (data: any) =>
    data.map((item: any) => ({
      x: item[xAxe] ?? item.year,
      y: item[yAxe] ?? item.value,
    }));

  if (groupByKey) {
    return currentWidgetData?.data?.reduce((acc, obj) => {
      const group = obj[groupByKey];
      if (!acc[group]) {
        acc[group] = [];
      }
      acc[group].push({
        x: obj[xAxe] ?? obj.year,
        y: obj[yAxe] ?? obj.value,
      });
      return acc;
    }, {});
  } else {
    return {
      default: mapData(currentWidgetData?.data ?? []),
    };
  }
};
