import React, { useEffect, useMemo } from "react";
import { PageMenu } from "../../components/PageMenu";
import { HeadingMenuContainer } from "../../components/NavBar/styles";
import {
  ProfilePageWrapper,
  UserProfileHeaderIcon,
  UserProfileHeaderUserDescription,
  UserProfileHeaderUserDetails,
  UserProfileHeaderUserName,
  UserProfileHeaderUserRoleBadge,
  UserProfileHeaderWrapper,
} from "./styles";
import { ProfileSideBar } from "../../components/ProfileSideBar";
import { UserProfileProjects } from "../../components/UserProfileProjects";
import { useDispatch, useSelector } from "react-redux";
import { requestProfile } from "../../store/slices/profiles";
import { getProfiles } from "../../store/selectors/profiles";

export const ProfilePage = () => {
  const dispatch = useDispatch();
  const currentProfile = useSelector(getProfiles);

  useEffect(() => {
    dispatch(requestProfile());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initials = useMemo(() => {
    let initials = currentProfile?.email?.charAt(0);
    if (currentProfile?.firstName?.length || currentProfile?.lastName?.length) {
      initials =
        (currentProfile?.firstName?.charAt(0) || "") +
        (currentProfile?.lastName?.charAt(0) || "");
    }
    return initials?.toUpperCase();
  }, [
    currentProfile?.email,
    currentProfile?.firstName,
    currentProfile?.lastName,
  ]);

  const ProfileUserName = currentProfile.firstName && currentProfile.lastName;

  return (
    <>
      <HeadingMenuContainer>
        <UserProfileHeaderWrapper>
          <UserProfileHeaderIcon>{initials}</UserProfileHeaderIcon>
          <UserProfileHeaderUserDetails>
            <UserProfileHeaderUserName>
              {!ProfileUserName
                ? "Not set"
                : `${currentProfile.firstName} ${currentProfile.lastName}`}
            </UserProfileHeaderUserName>
            <UserProfileHeaderUserDescription>
              {currentProfile.jobTitle}
              {currentProfile.isAdmin && (
                <UserProfileHeaderUserRoleBadge>
                  Admin
                </UserProfileHeaderUserRoleBadge>
              )}
            </UserProfileHeaderUserDescription>
          </UserProfileHeaderUserDetails>
        </UserProfileHeaderWrapper>
        <PageMenu />
      </HeadingMenuContainer>
      <ProfilePageWrapper>
        <ProfileSideBar currentProfile={currentProfile} />
        <UserProfileProjects />
      </ProfilePageWrapper>
    </>
  );
};
