import { useDispatch, useSelector } from "react-redux";
import {
  TextAndImageHeadingContent,
  TextAndImageHeading,
  TextAndImageHighlights,
  SectionWrapperBlock,
  AlignBlock,
  UploadBlock,
  UploadLabelBlock,
  UploadTitle,
  UploadDescription,
  UploadIcon,
  UploadIconBlock,
  AlignBlockContent,
  SkiBlock,
  SkiItem,
  SkiItemTitleBlock,
  SkiItemTag,
  SkiItemTitle,
  SkiItemDescription,
  SkiItemDescriptionPoint,
  SettingsWrapper,
  UploadBlockWrapper,
  SectionRemove,
} from "./styles";
import { SectionWrapper } from "./styles";
import {
  getIsEditMode,
  getIsPublicMode,
} from "../../../../../../store/selectors/main";
import { useEffect, useState, useRef } from "react";
import { useDebounceCallback } from "usehooks-ts";
import { CenterIcon, LeftIcon, RightIcon } from "../../icons/TextPositionIcons";
import {
  requestDownloadFile,
  requestUploadSimpleFile,
} from "../../../../../../store/slices/files";
import { ISection } from "../../../../../../api/sections";
import { BorderedSettingsBlock, IsDragging, IsHovered } from "../../styles";
import SectionDropdown from "./SectionDropdown/SectionDropdown";
import { SectionImageDropdown } from "./SectionImageDropdown";

interface Props {
  contentItem: ISection;
  setContentItem: (item: ISection) => void;
  draggable?: boolean;
  deleteItem: () => void;
}

export const ALIGN: { [key: string]: string } = {
  left: "row",
  center: "column",
  right: "row-reverse",
};

const SectionTextImage: React.FC<Props> = ({
  setContentItem,
  contentItem,
  draggable,
  deleteItem,
}) => {
  const isEditMode = useSelector(getIsEditMode);
  const isPublicMode = useSelector(getIsPublicMode);
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState();
  const dispatch = useDispatch();
  const debounced = useDebounceCallback(setContentItem, 600);
  const debouncedFile = useDebounceCallback(setFile, 1000);

  const prevContentItemRef = useRef(contentItem);

  useEffect(() => {
    const prevContentItem = prevContentItemRef.current;
    const contentItemJson = JSON.stringify(contentItem);

    if (JSON.stringify(prevContentItem) !== contentItemJson) {
      setContentItem(contentItem);
      prevContentItemRef.current = contentItem;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentItem]);

  useEffect(() => {
    if (file && typeof file !== "string") {
      try {
        dispatch(
          requestUploadSimpleFile({
            files: [file],
            callbacks: {
              onSuccess: (resp) => {
                const uploadedFile = resp?.at(0);
                if (uploadedFile) {
                  setContentItem({
                    ...contentItem,
                    image: uploadedFile.path,
                  });
                }
              },
            },
          })
        );
      } catch (e) { }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (contentItem.image) {
      dispatch(
        requestDownloadFile({
          path: contentItem.image,
          callbacks: {
            onSuccess: (resp) => {
              setFile(resp.url);
            },
          },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentItem.image]);

  return (
    <SectionWrapperBlock
      $isEditMode={!isPublicMode && isEditMode}
      $draggable={draggable}
      onMouseEnter={handleOpen}
      onMouseLeave={handleClose}
    >
      {!isPublicMode && open ? <IsHovered /> : null}
      {!isPublicMode && draggable ? <IsDragging /> : null}
      <SectionWrapper spellCheck={false}>
        {isEditMode && !isPublicMode && (
          <>
            <SectionRemove onClick={deleteItem} />
            <SettingsWrapper $hover={open}>
              <BorderedSettingsBlock>
                <LeftIcon
                  type={contentItem?.align}
                  onClick={() => {
                    debounced({ ...contentItem, align: "left" });
                  }}
                />
                <CenterIcon
                  type={contentItem?.align}
                  onClick={() => {
                    debounced({ ...contentItem, align: "center" });
                  }}
                />
                <RightIcon
                  type={contentItem?.align}
                  onClick={() => {
                    debounced({ ...contentItem, align: "right" });
                  }}
                />
              </BorderedSettingsBlock>
              <SectionDropdown
                open={open}
                item={contentItem}
                setContentItem={(newItem) => {
                  setContentItem(newItem);
                }}
              />
            </SettingsWrapper>
          </>
        )}
        <TextAndImageHeadingContent>
          {contentItem?.showTitle ? (
            <TextAndImageHeading
              defaultValue={contentItem?.title}
              spellCheck={false}
              placeholder="Add a title to this story"
              disabled={!isEditMode || isPublicMode}
              contentEditable={isEditMode || !isPublicMode}
              $isEditMode={isEditMode && !isPublicMode}
              onChange={(e) => {
                debounced({ ...contentItem, title: e.target.value });
              }}
              maxLength={59}
            />
          ) : null}
          <AlignBlock
            $alignment={
              contentItem?.align ? (ALIGN[contentItem?.align] as string) : "row"
            }
          >
            <AlignBlockContent
              $alignment={contentItem.align === "center" ? "row" : "column"}
            >
              {contentItem?.showDescription ? (
                <TextAndImageHighlights
                  defaultValue={contentItem?.description}
                  spellCheck={false}
                  placeholder="Please provide a clear and detailed description related to the data shown in the dashboard. This will help readers better understand the insights and allow AI to generate an image that accurately represents the information."
                  disabled={!isEditMode || isPublicMode}
                  contentEditable={isEditMode || !isPublicMode}
                  $isEditMode={isEditMode && !isPublicMode}
                  onChange={(e) => {
                    debounced({ ...contentItem, description: e.target.value });
                  }}
                  maxLength={240}
                />
              ) : null}
              {contentItem.showKpi ? (
                <SkiBlock>
                  {contentItem.kpis?.map((kpi) => {
                    return (
                      <SkiItem key={kpi}>
                        <SkiItemTitleBlock>
                          <SkiItemTag>##</SkiItemTag>
                          <SkiItemTitle>unit</SkiItemTitle>
                        </SkiItemTitleBlock>
                        <SkiItemDescription>
                          <SkiItemDescriptionPoint />
                          Select KPI
                        </SkiItemDescription>
                      </SkiItem>
                    );
                  })}
                </SkiBlock>
              ) : null}
            </AlignBlockContent>
            <UploadBlockWrapper>
              {contentItem.showImage ? (
                <UploadBlock
                  $isCenter={contentItem?.align === "center"}
                  $imageUrl={typeof file === "string" ? file : undefined}
                >
                  <SectionImageDropdown
                    setFile={debouncedFile}
                    fileUrl={file}
                  />
                  {typeof file === "string" ? null : (
                    <UploadLabelBlock
                      $isCenter={contentItem?.align === "center"}
                    >
                      <UploadIconBlock>
                        <UploadIcon />
                      </UploadIconBlock>
                      <UploadTitle>Upload an Image</UploadTitle>
                      <UploadDescription>
                        The image should reflect the presented information
                      </UploadDescription>
                    </UploadLabelBlock>
                  )}
                </UploadBlock>
              ) : null}
            </UploadBlockWrapper>
          </AlignBlock>
        </TextAndImageHeadingContent>
      </SectionWrapper>
    </SectionWrapperBlock>
  );
};

export default SectionTextImage;
