import React from "react";
import { ResourcesNoDataTexts, ResourcesNoDataTextsSubTitle, ResourcesNoDataTextsTitle, ResourcesNoDataWrapper } from "./styles";
import { Button } from "../../../../components/Button";
import { Icon } from "react-bootstrap-icons";

interface Props {
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  title: string;
  subTitle: string;
  onClick: () => void;
  name: string;
  IconButton: Icon;
}

export const ResourcesNoData: React.FC<Props> = ({ Icon, title, subTitle, onClick, name, IconButton }) => {
  return (
    <ResourcesNoDataWrapper>
      <Icon />
      <ResourcesNoDataTexts>
        <ResourcesNoDataTextsTitle>{title}</ResourcesNoDataTextsTitle>
        <ResourcesNoDataTextsSubTitle>{subTitle}</ResourcesNoDataTextsSubTitle>
      </ResourcesNoDataTexts>
      <Button
        name={name}
        onClick={onClick}
        icon={<IconButton />}
        variant="primary"
        size="medium"
      />
    </ResourcesNoDataWrapper>
  )
}
