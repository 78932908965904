import React from "react";
import { ReactComponent as EmptyIcon } from "../../../../../assets/resourcesPagesIcons/NoPublic.svg";
import { Search } from "react-bootstrap-icons";
import { ResourcesNoData } from "../../../../../pages/Resources/pages/NoData";
import { DatasetsResponseListDto } from "../../../../../models/Datasets";
import PublicDataList from "./PublicDataList";
import { useNavigate } from "react-router-dom";

type Props = {
  setLibraryDatasetOverviewOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setDimensionsSelected: React.Dispatch<any>;
  allDatasets: DatasetsResponseListDto;
  libraryDatasetOverviewOpen: boolean;
  libraryDatasetsIds: string[];
  searchInput: string;
};

const PublicData = ({
  allDatasets,
  setLibraryDatasetOverviewOpen,
  searchInput,
  setDimensionsSelected,
  libraryDatasetsIds,
  libraryDatasetOverviewOpen,
}: Props) => {
  const navigate = useNavigate();

  return (
    <>
      {!allDatasets.items.length ? (
        <ResourcesNoData
          Icon={EmptyIcon}
          title="No datasets were discovered"
          subTitle={`Start searching and adding datasets by pressing button below.`}
          IconButton={Search}
          name={"Search Public Data"}
          onClick={() => {
            navigate("/resources/public-resources/search");
          }}
        />
      ) : (
        <PublicDataList
          setFileOverviewOpen={setLibraryDatasetOverviewOpen}
          searchInput={searchInput}
          setDimensionsSelected={setDimensionsSelected}
          libraryDatasetsIds={libraryDatasetsIds}
          libraryDatasetOverviewOpen={libraryDatasetOverviewOpen}
        />
      )}
    </>
  );
};

export default PublicData;
