import {
  FLContainer,
  FLContent,
  FLLabel,
  FLLabelDetails,
  FLRow,
  FLTitle,
} from "./styles";

interface Data {
  label: string;
  details: string;
}

interface Props {
  financialData: Data[];
  listingData: Data[];
}

const FinancialAndListing = ({ financialData, listingData }: Props) => {
  return (
    <FLContainer>
      <FLTitle>Financial & Listing Details</FLTitle>
      <FLContent>
        <div>
          {financialData?.map((data, index) => (
            <FLRow key={index}>
              <FLLabel>{data.label}</FLLabel>
              <FLLabelDetails>{data.details}</FLLabelDetails>
            </FLRow>
          ))}
        </div>
        <div>
          {listingData?.map((data, index) => (
            <FLRow key={index}>
              <FLLabel>{data.label}</FLLabel>
              <FLLabelDetails>{data.details}</FLLabelDetails>
            </FLRow>
          ))}
        </div>
      </FLContent>
    </FLContainer>
  );
};

export default FinancialAndListing;
