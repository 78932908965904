import styled, { css } from "styled-components";

export const CarouselContainer = styled.div`
  width: 100%;
  text-align: center;
`;

export const MainSelectionContainer = styled.div`
  position: relative;
  border-radius: 12px;
  background-color: transparent;
  border: 1px solid #dfe1e4;
  overflow: hidden;
`;

export const MainImage = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ThumbnailsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
  gap: 8px;
`;

export const Thumbnail = styled.img<{ selected: boolean }>`
  width: 48px;
  height: 48px;
  border: 2px solid ${({ selected }) => (selected ? "#454BDF" : "transparent")};
  border-radius: 6px;
  cursor: pointer;
  transition: border 0.3s;
`;

export const ArrowButton = styled.button<{ direction: "left" | "right" }>`
  position: absolute;
  top: 50%;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-50%);
  background-color: #eff0f1;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
  width: 40px;
  height: 40px;

  ${({ direction }) => css`
    ${direction === "left" ? "left: 20px;" : "right: 20px;"}
  `}
`;
