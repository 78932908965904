import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Property, PropertyQueryParams } from "../../models/RealEstate";

export interface ZillowState {
  data: Property | null;
  loading: boolean;
  error: string | null;
}

const initialState: ZillowState = {
  data: null,
  loading: false,
  error: null
};

const zillowSlice = createSlice({
  name: "realEstateZillow",
  initialState,
  reducers: {
    requestProperty: (state, action: PayloadAction<PropertyQueryParams>) => {
      state.loading = true;
      state.error = null;
    },
    setPropertyDetails: (state, action: PayloadAction<Property>) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
    resetPropertyDetails: (state) => {
      return initialState;
    }
  }
});

export const {
  requestProperty,
  setPropertyDetails,
  setError,
  resetPropertyDetails
} = zillowSlice.actions;

export default zillowSlice.reducer;