import { IMarker, RealEstateMap } from "../../RealEstateMap";
import { LayoutRealEstateContainer, WidgetWrapper } from "./styles";
import { useEffect, useMemo, useState } from "react";
import axios from "../../../api/axios";
import { Col, Row } from "react-flexbox-grid";
import { WIDGETS } from "../../Widgets/widgets";
import { useSelector } from "react-redux";
import {
  getCurrentPageKpi,
  getCurrentPageWidgets,
  getPageSettings,
} from "../../../store/selectors/projects";
import { KPISettingsWrapper } from "../../KPISettingsWrapper";
import {
  getAiKpis,
  getLoadingAiSuggestions,
} from "../../../store/selectors/widgets";
import {
  requestCreateKpis,
  requestPageKpis,
} from "../../../store/slices/projectPages";
import { useDispatch } from "react-redux";
import { Loader } from "../../Loader";

const LayoutRealEstate = () => {
  const [markers, setMarkers] = useState<IMarker[]>([]);
  const [mapCenter, setMapCenter] = useState({ longitude: 0, latitude: 0 });
  const [isLoadingMap, setIsLoadingMap] = useState(false);
  const [mapError, setMapError] = useState<string | null>(null);

  const pageWidgetsState = useSelector(getCurrentPageWidgets);
  const currentPage = useSelector(getPageSettings);
  const aiKpisSuggestion = useSelector(getAiKpis);
  const aiKpi = useSelector(getCurrentPageKpi);
  const isLoadingAiSuggestions = useSelector(getLoadingAiSuggestions);
  const pageWidgets = useMemo(() => pageWidgetsState, [pageWidgetsState]);
  const dispatch = useDispatch();

  useEffect(() => {
    const shouldFetchKpis =
      !isLoadingAiSuggestions && currentPage?.id && !aiKpi.items.length;

    if (shouldFetchKpis && currentPage.id) {
      dispatch(
        requestPageKpis({
          pageId: currentPage.id,
          includeData: true,
        })
      );
    }
  }, [currentPage?.id, aiKpi.items.length, isLoadingAiSuggestions, dispatch]);

  useEffect(() => {
    const shouldCreateKpis =
      !isLoadingAiSuggestions &&
      currentPage?.id &&
      aiKpisSuggestion?.length > 0;

    if (shouldCreateKpis) {
      const createKpisPromises = aiKpisSuggestion.map((queryKpi: any) =>
        dispatch(
          requestCreateKpis({
            ...queryKpi,
            pageId: currentPage.id,
          })
        )
      );

      Promise.all(createKpisPromises).catch((error) => {
        console.error("Error creating KPIs:", error);
      });
    }
  }, [aiKpisSuggestion, currentPage?.id, isLoadingAiSuggestions, dispatch]);

  useEffect(() => {
    const getCoordinates = async () => {
      setIsLoadingMap(true);
      setMapError(null);
      
      try {
        let location = currentPage?.location;
        
        // If no location is set, get location from IP
        if (!location) {
          const ipResponse = await axios.get('https://api.ipapi.com/api/check?access_key=' + process.env.REACT_APP_IPAPI_KEY);
          location = `${ipResponse.data.city}, ${ipResponse.data.region_name}`;
        }

        const geocodingUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
          location
        )}.json`;
        
        const geoResponse = await axios.get(geocodingUrl, {
          params: {
            access_token: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
            limit: 1
          }
        });

        const features = geoResponse.data.features;
        if (!features?.length) {
          throw new Error("Location not found");
        }

        const [longitude, latitude] = features[0].center;
        setMapCenter({ longitude, latitude });

        const propertiesResponse = await axios.post("/data-provider/api/zillow/search", {
          location,
          coordinates: { longitude, latitude },
          page: 1,
          status: "forSale",
          getAll: false,
        });

        if (propertiesResponse.data) {
          setMarkers(
            propertiesResponse.data.map((marker: IMarker) => ({
              longitude: marker.longitude ?? 0,
              latitude: marker.latitude ?? 0,
              imgSrc: marker.imgSrc,
              price: marker.price,
              streetAddress: marker.streetAddress,
              zpid: marker.zpid,
            }))
          );
        }
      } catch (error) {
        console.error("Error fetching map data:", error);
        setMapError(
          error instanceof Error 
            ? error.message 
            : "Failed to load map data"
        );
      } finally {
        setIsLoadingMap(false);
      }
    };

    getCoordinates();
  }, [currentPage?.location]);

  return (
    <LayoutRealEstateContainer className="layout-real-estate">
      {isLoadingAiSuggestions && <Loader fullScreen />}
      <Row style={{ height: "100%" }}>
        <Col
          lg={6}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            height: "100%",
          }}
        >
          <Row>
            <Col lg={12}>
              <WidgetWrapper $map={true}>
                <KPISettingsWrapper
                  recommended
                  storytelling={false}
                  kpis={aiKpi.items}
                />
              </WidgetWrapper>
            </Col>
          </Row>

          {pageWidgets.items.map((widget, i) => {
            if (widget.chartType !== "polarAreaChart") return null;
            const ChartComponent =
              widget?.chartType && WIDGETS[widget.chartType];
            return ChartComponent ? (
              <Row key={widget.id}>
                <Col lg={12}>
                  <WidgetWrapper>
                    <ChartComponent
                      storytelling={false}
                      currentWidget={widget}
                      recommended={false}
                      showLegend
                    />
                  </WidgetWrapper>
                </Col>
              </Row>
            ) : null;
          })}
          <Row style={{ height: "calc(100% - 8px)" }}>
            {pageWidgets.items.map((widget, i) => {
              if (widget.chartType === "polarAreaChart") return null;
              const ChartComponent =
                widget?.chartType && WIDGETS[widget.chartType];
              return ChartComponent ? (
                <Col lg={6} key={widget.id}>
                  <WidgetWrapper>
                    <ChartComponent
                      storytelling={false}
                      currentWidget={widget}
                      recommended={false}
                      showLegend
                    />
                  </WidgetWrapper>
                </Col>
              ) : null;
            })}
          </Row>
        </Col>
        <Col lg={6}>
          <Row
            style={{
              border: "1px #D3DBE3 solid",
              position: "relative",
              height: "calc(100vh - 136px)",
              marginLeft: "0px",
              borderRadius: "8px",
              overflow: "hidden",
            }}
          >
            {isLoadingMap && <Loader />}
            {mapError && <div style={{ padding: "1rem", color: "red" }}>{mapError}</div>}
            <RealEstateMap markers={markers} houseSettings={mapCenter} />
          </Row>
        </Col>
      </Row>
    </LayoutRealEstateContainer>
  );
};

export default LayoutRealEstate;