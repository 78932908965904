import { IMarkers } from "../../MapSettings/types/types";
import { Circle, Polygon, Rect } from "./style";

type IGetMarkerProps = {
  i: number | string;
  markerType: IMarkers;
  color: string;
  xScale: string | number | undefined;
  yScale: string | number | undefined;
  xScaleBand: string | number | undefined;
  r: string | number | undefined;
  onMouseEnterHandler?: (e: any) => void;
  onMouseLeaveHandler?: () => void;
  hovered?: boolean;
  opacity?: number;
};

export const LineChartMarker = ({
  i,
  markerType,
  color,
  xScale = 0,
  yScale = 0,
  xScaleBand = 0,
  r,
  hovered = false,
  opacity = 1,
  onMouseEnterHandler = (e) => {},
  onMouseLeaveHandler = () => {},
}: IGetMarkerProps) => {
  switch (markerType) {
    case "circle":
      return (
        <Circle
          opacity={opacity}
          key={i}
          cx={Number(xScale) + Number(xScaleBand)}
          cy={Number(yScale)}
          r={r}
          fill={color}
          stroke={color}
          strokeWidth={2}
          onMouseEnter={onMouseEnterHandler}
          onMouseLeave={onMouseLeaveHandler}
        />
      );
    case "rhombus":
      const rhombusSize = hovered ? 10 : 7;

      return (
        <Rect
          key={i}
          opacity={opacity}
          x={Number(xScale) + Number(xScaleBand) - rhombusSize / 2}
          y={Number(yScale) - rhombusSize / 2}
          width={rhombusSize}
          height={rhombusSize}
          fill={color}
          strokeWidth="1"
          transform={`rotate(45, ${
            Number(xScale) + Number(xScaleBand)
          }, ${yScale})`}
          onMouseEnter={onMouseEnterHandler}
          onMouseLeave={onMouseLeaveHandler}
          style={{ position: "relative", zIndex: 99999 }}
        />
      );

    case "square":
      const squareSize = hovered ? 9 : 6;
      return (
        <Rect
          key={i}
          opacity={opacity}
          x={Number(xScale) + Number(xScaleBand) - squareSize / 2}
          y={Number(yScale) - squareSize / 2}
          width={squareSize}
          height={squareSize}
          fill={color}
          stroke={color}
          strokeWidth={2}
          onMouseEnter={onMouseEnterHandler}
          onMouseLeave={onMouseLeaveHandler}
        />
      );
    case "triangle":
      const triangleSize = hovered ? 4 : 3;

      return (
        <Polygon
          key={i}
          opacity={opacity}
          points={`
            ${Number(xScale) + Number(xScaleBand)},${
            Number(yScale) - triangleSize
          }
            ${Number(xScale) + Number(xScaleBand) - triangleSize},${
            Number(yScale) + triangleSize
          }
            ${Number(xScale) + Number(xScaleBand) + triangleSize},${
            Number(yScale) + triangleSize
          }
          `}
          fill={color}
          stroke={color}
          strokeWidth={triangleSize - 1}
          onMouseEnter={onMouseEnterHandler}
          onMouseLeave={onMouseLeaveHandler}
        />
      );
    case "disabled":
      return;
    default:
      return (
        <Circle
          key={i}
          opacity={opacity}
          cx={Number(xScale) + Number(xScaleBand)}
          cy={Number(yScale)}
          r={r}
          fill={"white"}
          stroke={color}
          strokeWidth={2}
          onMouseEnter={onMouseEnterHandler}
          onMouseLeave={onMouseLeaveHandler}
        />
      );
  }
};

type IVerticalLollipopMarkers = {
  markerType: IMarkers;
  xScale: number;
  xVal: number;
  xScaleBand: number;
  yScale: number;
  color: string;
};

type IHorizontalLollipopMarkers = {
  markerType: IMarkers;
  yScale: number;
  yVal: number;
  yScaleBand: number;
  color: string;
  xScale: number;
};

export const LollipopMarkersVertical = ({
  markerType,
  xScale,
  xVal,
  xScaleBand,
  yScale,
  color,
}: IVerticalLollipopMarkers) => {
  switch (markerType) {
    case "donut":
      return (
        <>
          <path
            d={`
              M ${xScale + xVal + xScaleBand}, ${yScale - 5}
              a 5,5 0 1,0 0,10
              a 5,5 0 1,0 0,-10
              M ${xScale + xVal + xScaleBand}, ${yScale - 2}
              a 2,2 0 1,1 0,4
              a 2,2 0 1,1 0,-4
            `}
            fill={color}
            stroke="#fff"
            strokeWidth={1}
          />
          <circle
            cx={xScale + xVal + xScaleBand}
            cy={yScale}
            r={2}
            fill="#fff"
          />
        </>
      );

    case "circle":
      return (
        <path
          d={`
      M ${xScale + xVal + xScaleBand}, ${yScale - 5}
      a 5,5 0 1,0 0,10
      a 5,5 0 1,0 0,-10
    `}
          fill={color}
          stroke="#fff"
          strokeWidth={1}
        />
      );

    case "square":
      return (
        <path
          d={`
        M${xScale + xVal + xScaleBand - 5}, ${yScale + 5}
        L${xScale + xVal + xScaleBand + 5}, ${yScale + 5}
        L${xScale + xVal + xScaleBand + 5}, ${yScale - 5}
        L${xScale + xVal + xScaleBand - 5}, ${yScale - 5} Z`}
          fill={color}
          stroke="#fff"
          strokeWidth={1}
        />
      );

    case "triangle":
      return (
        <path
          d={`
        M${xScale + xVal + xScaleBand}, ${yScale - 5}
        L${xScale + xVal + xScaleBand - 5}, ${yScale + 5}
        L${xScale + xVal + xScaleBand + 5}, ${yScale + 5}
        Z`}
          fill={color}
          stroke="#fff"
          strokeWidth={1}
        />
      );

    case "disabled":
      return <></>;

    default:
      return (
        <path
          d={`
    M${xScale + xVal + xScaleBand}, ${yScale + 5}
    L${xScale + xVal + xScaleBand + 5}, ${yScale}
    L${xScale + xVal + xScaleBand}, ${yScale - 5}
    L${xScale + xVal + xScaleBand - 5}, ${yScale}Z`}
          fill={color}
          stroke="#fff"
          strokeWidth={1}
        />
      );
  }
};

export const LollipopMarkersHorizontal = ({
  markerType,
  xScale,
  yVal,
  yScaleBand,
  yScale,
  color,
}: IHorizontalLollipopMarkers) => {
  switch (markerType) {
    case "donut":
      return (
        <>
          <path
            d={`
              M ${xScale + 5}, ${yScale + yVal + yScaleBand}
              a 5,5 0 1,0 -10,0
              a 5,5 0 1,0 10,0
              M ${xScale + 2}, ${yScale + yVal + yScaleBand}
              a 2,2 0 1,1 -4,0
              a 2,2 0 1,1 4,0
            `}
            fill={color}
            stroke="#fff"
            strokeWidth={1}
          />
          <circle
            cx={xScale}
            cy={yScale + yVal + yScaleBand}
            r={2}
            fill="#fff"
          />
        </>
      );

    case "circle":
      return (
        <path
          d={`
            M ${xScale + 5}, ${yScale + yVal + yScaleBand}
            a 5,5 0 1,0 -10,0
            a 5,5 0 1,0 10,0
          `}
          fill={color}
          stroke="#fff"
          strokeWidth={1}
        />
      );

    case "square":
      return (
        <path
          d={`
            M${xScale + 5}, ${yScale + yVal + yScaleBand - 5}
            L${xScale - 5}, ${yScale + yVal + yScaleBand - 5}
            L${xScale - 5}, ${yScale + yVal + yScaleBand + 5}
            L${xScale + 5}, ${yScale + yVal + yScaleBand + 5} Z`}
          fill={color}
          stroke="#fff"
          strokeWidth={1}
        />
      );

    case "triangle":
      return (
        <path
          d={`
            M${xScale}, ${yScale + yVal + yScaleBand - 5}
            L${xScale - 5}, ${yScale + yVal + yScaleBand + 5}
            L${xScale + 5}, ${yScale + yVal + yScaleBand + 5}
            Z`}
          fill={color}
          stroke="#fff"
          strokeWidth={1}
        />
      );

    case "disabled":
      return <></>;

    default:
      return (
        <path
          d={`
            M${xScale + 5}, ${yScale + yVal + yScaleBand}
            L${xScale}, ${yScale + yVal + yScaleBand + 5}
            L${xScale - 5}, ${yScale + yVal + yScaleBand}
            L${xScale}, ${yScale + yVal + yScaleBand - 5} Z`}
          fill={color}
          stroke="#fff"
          strokeWidth={1}
        />
      );
  }
};
