import React from "react";

import {
  ApisTable,
  Details,
  Dimensions,
  HeadingWrapper,
  APIName,
  RequestPerMinutes,
  ProviderURL,
  LastExecution,
  Status,
} from "./styles";
import { ResourceRow } from "../ApisRow";
import {
  DatasetsResponseListDto,
  NewDatasetDTO,
} from "../../../../models/Datasets";
import { useSortByCreatedDate } from "../../../../hooks/useSortByDate";

export const ApisList: React.FC<Partial<DatasetsResponseListDto>> = ({
  items,
}: Partial<DatasetsResponseListDto>) => {
  const { sortItemsByCreatedDate } = useSortByCreatedDate("desc");
  const sortedItems = sortItemsByCreatedDate(items ? items : []);

  return (
    <ApisTable>
      <HeadingWrapper>
        <APIName>API Name</APIName>
        <Dimensions>Dimensions</Dimensions>
        <RequestPerMinutes>Requests per min</RequestPerMinutes>
        <ProviderURL>Provider URL</ProviderURL>
        <LastExecution>Last Execution</LastExecution>
        <Status>Status</Status>
        <Details></Details>
      </HeadingWrapper>
      {sortedItems?.map((dataset: NewDatasetDTO) => {
        return (
          <ResourceRow
            id={dataset.id}
            name={dataset.name}
            createdAt={dataset.createdAt}
            fileFormat={dataset?.filePath?.split(".")?.pop()}
            fields={dataset.fields}
            filePath={dataset.filePath}
            updatedAt={dataset.createdAt}
            key={dataset.id}
          />
        );
      })}
    </ApisTable>
  );
};
