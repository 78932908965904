import React from "react";
import {
  CloseButton,
  FileName,
  Format,
  RowWrapper,
  UploadedDate,
} from "./styles";
import { useDispatch } from "react-redux";
import { removeSelectedFile } from "../../../../../store/slices/datasets";
import { FileLibraryItem } from "../../../../../models/Files";
import { removeFileData } from "../../../../../store/slices/files";

type Props = { file: FileLibraryItem };

const FilesRow = ({ file }: Props) => {
  const dispatch = useDispatch();

  const date = new Date();

  const formattedDate = date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });

  return (
    <RowWrapper>
      <FileName>{file.originalName}</FileName>
      <Format>.{file.type}</Format>

      <UploadedDate>{formattedDate}</UploadedDate>

      <CloseButton
        onClick={() => {
          dispatch(removeSelectedFile(file.path));
          dispatch(removeFileData(file.path));
        }}
      />

      {/* <More onClick={() => setMenuOpen((prevState) => !prevState)} />
      {menuOpen && (
        <Submenu
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
          datasetItemMenu
          onRemove={() => dispatch(requestDatasetRemove(""))}
          preview
          share
          download
        />
      )} */}
    </RowWrapper>
  );
};

export default FilesRow;
