import { useEffect, useState } from "react";
import { MapCheckBox } from "../Checkbox";
import {
  LegendContainer,
  LegendActions,
  LegendActionIcon,
} from "./styles";
import {REAL_ESTATE_LEGEND_OPTIONS} from "./options";
import { RealEstateMapLegendItem } from "../../types";


interface Props {
  onSelectionChange: (selectedItems: RealEstateMapLegendItem[]) => void;
  defaultSelectedItems: RealEstateMapLegendItem[];
}

export const RealEstateMapLegend = ({onSelectionChange, defaultSelectedItems}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [checkedItems, setCheckedItems] = useState<RealEstateMapLegendItem[]>([]);

  useEffect(() => {
    setCheckedItems(defaultSelectedItems);
    onSelectionChange(defaultSelectedItems);
  },[defaultSelectedItems, onSelectionChange])

  const handleCheckboxChange = (id: RealEstateMapLegendItem) => {
    setCheckedItems((prevState) => {
      const newCheckedItems = prevState.includes(id)
        ? prevState.filter((item) => item !== id)
        : [...prevState, id];
      onSelectionChange(newCheckedItems);
      return newCheckedItems;
    });
  };

  return (
    <LegendContainer open={isOpen}>
      <LegendActions onClick={() => setIsOpen(!isOpen)}>
        Legend <LegendActionIcon active={isOpen} />
      </LegendActions>
      {isOpen && (
        <>
          {REAL_ESTATE_LEGEND_OPTIONS.map((item) => {
            return (
              <MapCheckBox
                key={item.label}
                icon={item.icon}
                onChange={() => handleCheckboxChange(item.id)}
                checked={checkedItems.includes(item.id)}
              >
                {item.label}
              </MapCheckBox>
            );
          })}
        </>
      )}
    </LegendContainer>
  );
};
