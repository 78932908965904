import React from "react";
import { Container } from "./styles";

type Props = {};

const NoAuth = (props: Props) => {
  return <Container>This request does not use any authorization.</Container>;
};

export default NoAuth;
