import { styled } from "styled-components";

export const HouseSettingsContainer = styled.div`
  border: 1px solid #dfe1e4;
  padding: 14px 0;
  background-color: #ffffff;
  border-radius: 8px;
  position: absolute;
  top: 10px;
  right: 20px;
  max-width: 228px;

  .input-container {
    padding: 0;
    margin-top: 4px;

    > div {
      color: #5f6877;
      font-size: 12px;
      line-height: 16px;
    }

    input {
      height: 32px;
    }
  }
`;

export const HouseSettingsText = styled.h6`
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #5f6877;
  margin-bottom: 8px;
`;

export const HouseSettingsHr = styled.hr`
  border-bottom: 1px solid #dfe1e4;
  margin: 12px 0;
  opacity: 0.3;
`;

export const HouseSettingsSection = styled.div`
  padding: 0 12px;

  button {
    width: 100%;
    height: 32px;
  }
`;

export const HouseSettingsRotate = styled.div`
  display: flex;
  gap: 10px;
`;

export const SuggestionContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  border-radius: 10px;
  margin-top: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

export const SuggestionItem = styled.div`
  padding: 8px 12px;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.neutralBorder};
  font-size: 14px;
  &:hover {
    background-color: ${({ theme }) => theme.neutralBackgroundHover};
  }
`;
