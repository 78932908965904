import React from "react";

import {
  FilesTable,
  Details,
  Format,
  HeadingWrapper,
  AssetName,
  UsedInProjects,
  UploadedDate,
  Owner,
} from "./styles";
import { FilesRow } from "../FilesRow";
import {
  DatasetsResponseListDto,
  NewDatasetDTO,
} from "../../../../models/Datasets";
import { useSortByCreatedDate } from "../../../../hooks/useSortByDate";

export const FilesList: React.FC<Partial<DatasetsResponseListDto>> = ({
  items,
}: Partial<DatasetsResponseListDto>) => {
  const { sortItemsByCreatedDate } = useSortByCreatedDate("desc");
  const sortedItems = sortItemsByCreatedDate(items ? items : []);

  return (
    <FilesTable>
      <HeadingWrapper>
        <AssetName>Asset name</AssetName>
        <Format>Format</Format>
        <UsedInProjects>Used in projects</UsedInProjects>
        <UploadedDate>Uploaded date</UploadedDate>
        <Owner>Owner</Owner>
        <Details></Details>
      </HeadingWrapper>
      {sortedItems?.map((dataset: NewDatasetDTO) => {
        return (
          <FilesRow
            id={dataset.id}
            name={dataset.name}
            createdAt={dataset.createdAt}
            fileFormat={dataset?.filePath?.split(".")?.pop()}
            fields={dataset.fields}
            filePath={dataset.filePath}
            updatedAt={dataset.createdAt}
            key={dataset.id}
          />
        );
      })}
    </FilesTable>
  );
};
