import { XLg } from "react-bootstrap-icons";
import styled from "styled-components";

export const ModalWrapper = styled.div`
  background-color: ${({ theme }) => theme.neutralBackgroundBase};
  border-radius: 12px;
  width: 480px;
  position: absolute;
  right: 50%;
  left: 50%;
  transform: translate(-50%, 0);
  max-height: 480px;
`;

export const SeparatorLine = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.neutralBorder};
  margin: 2px 0px;
`;

export const TopicsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  padding: 12px;
  height: calc(480px - 12px - 12px);
  overflow-y: hidden;
`
export const CloseIcon = styled(XLg)`
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;
  z-index: 1px;
  padding: 2px;
  font-size: 20px;
`

export const TopicsHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const TopicsHeaderTop = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

export const HeaderTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`

export const TemporarBackButton = styled.div`
  display: flex;
  align-items: center;
  gap: 4px; 
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`
export const TemporarBackButtonText = styled.div`
  color: #5D63F6;
  cursor: pointer;
`

export const TopicsBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow-y: auto;
  height: 100%;
`
export const TopicsSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;
`
