import { useState } from "react";
import {
  ModalWrapper,
  ModalOverlay,
  ModalHeadingWrapper,
  ModalHeading,
  CloseIconWrap,
  CloseIcon,
} from "../styles";
import {
  PositionHouseModalBody,
  PositionHouseModalContent,
  RealEstateMapContainer,
} from "./styles";
import { RealEstateMap } from "../../RealEstateMap";
import HouseSettings, { HouseSettingsProps } from "./components/HouseSettings";
import DataResources from "./components/DataResources";
import { useDispatch, useSelector } from "react-redux";
import { setNewProjectFiles } from "../../../store/slices/datasets";
import { getDraftProjectFiles } from "../../../store/selectors/datasets";
import { IFile } from "../../../models/Projects";

interface Props {
  onClose: () => void;
}

const PositionHouseModal = ({ onClose }: Props) => {
  const [isClosing, setIsClosing] = useState(false);
  const [selectedFile, setSelectedFile] = useState<IFile>();
  const [houseSettings, setHouseSettings] = useState<HouseSettingsProps>({
    zpId: "",
    address: "",
    latitude: 0,
    longitude: 0,
    scale: 0,
    rotateX: 0,
    rotateY: 0,
    rotateZ: 0,
  });

  const handleOnClose = () => {
    setIsClosing(true);
  };
  const dispatch = useDispatch();
  const newProjectFiles = useSelector(getDraftProjectFiles);

  // To open modal, use dispatch(setActiveModal({ id: "positionHouseModal" }));

  const handleOnSave = () => {
    // update the selected file with the new house settings by name
    // TODO: test behaviour when there are many files, now only one file is working
     const updatedFiles: IFile[] = newProjectFiles.map((item) => {
      if (item.name === selectedFile?.name) {
        return {
          ...item,
          mapBoxConfig: {...houseSettings, coordinates: [houseSettings.longitude, houseSettings.latitude]},
        };
      }
      return item;
    });
    // 
    dispatch(setNewProjectFiles(updatedFiles));
  };


  return (
    <ModalOverlay onClick={handleOnClose} $isClosing={isClosing}>
      <ModalWrapper
        $maxWidth={true}
        onClick={(e) => e.stopPropagation()}
        $isClosing={isClosing}
      >
        <PositionHouseModalContent>
          <PositionHouseModalBody>
            <DataResources
              onSelectFile={setSelectedFile}
              onClose={handleOnClose}
              onDone={handleOnSave}
            />
            <div>
              <ModalHeadingWrapper
                style={{
                  backgroundColor: "#9FA4AD14",
                  borderLeft: "1px solid #9FA4AD14",
                }}
              >
                <ModalHeading style={{ fontSize: "18px" }}>
                  {selectedFile?.name}
                </ModalHeading>
                <CloseIconWrap onClick={handleOnClose}>
                  <CloseIcon />
                </CloseIconWrap>
              </ModalHeadingWrapper>
              <RealEstateMapContainer>
                <RealEstateMap
                  threeDModelPath={selectedFile?.urls[0]}
                  houseSettings={houseSettings}
                  bordered={false}
                  rounded={false}
                  withZoomAction={false}
                  withLegend={false}
                />
                <HouseSettings onSave={(s) => setHouseSettings(s)} />
              </RealEstateMapContainer>
            </div>
          </PositionHouseModalBody>
        </PositionHouseModalContent>
      </ModalWrapper>
    </ModalOverlay>
  );
};

export default PositionHouseModal;
