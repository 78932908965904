import { styled } from "styled-components";

export const StyledAboutHome = styled.div`
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid #dfe1e4;

  > .about-home-title {
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.neutralText};
    font-weight: 600;
    margin-bottom: 12px;
  }

  > .about-home-tags {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    margin-bottom: 20px;

    > .about-home-tag {
      padding: 8px;
      border-radius: 100px;
      background-color: #eff0f1;
      border: 1px solid #dfe1e4;
      color: ${({ theme }) => theme.neutralTextWeak};
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
    }
  }

  > .about-home-description {
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.neutralTextWeak};
    font-weight: 400;
    margin-bottom: 12px;
  }

  > .about-home-footer {
    display: flex;
    gap: 16px;
    > .about-home-footer-item {
      color: ${({ theme }) => theme.neutralText};
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      gap: 8px;
      align-items: center;
    }
  }
`;
