import { styled } from "styled-components";

export const RenameModal = styled.div`
  background: white;
  position: absolute;
  right: 50%;
  left: 50%;
  min-width: 496px;
  transform: translate(-50%, 0);
  border-radius: 16px;
  z-index:11px;
`
export const OverviewContentWrapper = styled.div`
  display: flex;
  width:100%;
  height: 100%;
  padding: 16px;
`

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  width: 100%;
`

export const RenameInput = styled.input`
  width: 100%;
  border-radius: 24px;
  border: 1px solid #CFD2D6;
  padding: 12px 16px;
  color: ${({ theme }) => theme.neutralText};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  outline: none;
`

export const RenameInputHeader = styled.p`
  color: ${({ theme }) => theme.neutralTextWeak};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; 
`
