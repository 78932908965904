import styled from "styled-components";

export const CheckboxWrapper = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CheckboxLabel = styled.div<{$disabled: boolean}>`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: ${({ $disabled, theme }) => $disabled? theme.neutralTextDisabled : theme.neutralText};
  margin-left: 8px;
`;

export const KpiSettingItem = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 0;
`;

export const KpiSettingItemName = styled.div`
  display: flex;
  flex-direction: row;
`;

export const InputField = styled.input`
  width: 100%;
  border-style: none;
  padding: 5px;
  border-radius: 10px;
  outline: none;
  color: ${({ theme }) => theme.neutralText};
  font-size: 14px;
  position: relative;
  background: none;
  cursor: pointer;
  border: ${({ theme }) => `1px solid ${theme.neutralBorder}`};
  margin-left: 8px;
  min-width: 220px;

  &:focus {
    color: ${({ theme }) => theme.neutralText};
  }
`;

export const EditButton = styled.button`
  margin-left: 4px;
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;