import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  AvailableWidgetsWrapper,
  AvailableWidgetsCount,
  AvailableWidgetsHeading,
  AvailableWidgetsHeadingWrapper,
  WidgetItem,
  WidgetsItemsWrapper,
} from "./styles";
import { VerticalBarchart } from "../Widgets/VerticalBarchart";
import { SankeyPlot } from "../Widgets/Sankey";
import { GroupedBarChart } from "../Widgets/GroupedBarChart";
import { LineChart } from "../Widgets/LineChart/SingleLineChart";
import { AreaChart } from "../Widgets/AreaChart";
import { getCurrentWidget } from "../../store/selectors/projects";
import { GroupedLineChart } from "../Widgets/LineChart/GroupedLineChart";
import { HorizontalBarChart } from "../Widgets/HorizontalBarChart";
import { HorizontalLollipopChart } from "../Widgets/Lollipop/Horizontal";
import { VerticalLollipopChart } from "../Widgets/Lollipop/Vertical";
import { WidgetChartWrapper } from "../Widgets";
import { MatrixChart } from "../Widgets/MatrixChart";
import { WIDGETS } from "../Widgets/widgets";
import { AVAILABLE_WIDGETS } from "../../constants/widgetRecomended";
import { HorizontalGroupedBarChart } from "../Widgets/HorizontalGroupedBarChart";
import { MarkersVisualisationDataDto } from "../../models/Widgets";
import { UniversalMap } from "../Widgets/MapBox";
import { PolarAreaChart } from "../Widgets/PolarAreaChart";
import { RadarChart } from "../Widgets/RadarChart";
import { PunchcardChart } from "../Widgets/PunchcardChart";
import { ScatterPlot } from "../Widgets/ScatterPlot";
import {
  getScatterPlotDefaultColors,
  ScatterPlotDefaultMarkers,
} from "../Widgets/ScatterPlot/utils/getGroupData";
import { BubbleChart } from "../Widgets/BubbleChart";

export const AvailableWidgets = ({
  selectChart,
}: {
  selectChart: (chart?: string) => void;
}) => {
  const [recommendedWidgets, setRecommendedWidgets] = useState<string[]>([]);
  const currentWidget = useSelector(getCurrentWidget);
  const [selectedChart, setSelectedChart] = useState<string | undefined>(
    currentWidget?.id
  );
  const [recomendedToMap, setRecomendedToMap] = useState<boolean>(false);

  useEffect(() => {
    if (currentWidget) {
      let widgets = AVAILABLE_WIDGETS[currentWidget.chartType] as string[];
      const orientation = currentWidget.orientation;
      const chartType = orientation
        ? `${currentWidget.chartType}_${orientation}`
        : currentWidget.chartType;
      widgets = widgets?.filter((r) => r !== chartType);
      if (["matrix", "sankey"].includes(currentWidget.chartType)) {
        const ifRecomendedToMap = currentWidget?.data?.some(
          (d) => d?.county && d?.state
        );
        setRecomendedToMap(!!ifRecomendedToMap);
        const mapWidgets = [];
        if (ifRecomendedToMap) {
          if (currentWidget.chartType !== "matrix") mapWidgets.push("matrix");
          if (currentWidget.chartType !== "sankey") mapWidgets.push("sankey");
        }
        if (ifRecomendedToMap) {
          mapWidgets.forEach((t) => {
            const index = widgets.indexOf(t);
            if (index !== -1) {
              widgets.splice(index, 1, `${t}_map`);
            }
          });

          widgets = [...widgets, "map_matrix"];
        }
      }
      setRecommendedWidgets(widgets || []);
    } else {
      setRecommendedWidgets([]);
    }
  }, [currentWidget]);

  const onSelectChart = (value?: string) => {
    const chart = value !== selectedChart ? value : undefined;
    setSelectedChart(chart);
    selectChart(chart);
  };

  const renderRecommendedWidgets = () => {
    return recommendedWidgets?.map((widget: string, index: number) => {
      if (widget === currentWidget?.chartType) return null;
      switch (widget) {
        case "lollipopChart_horizontal":
          return (
            <WidgetItem key={index} $selected={true}>
              <WidgetChartWrapper
                recommended={true}
                selected={selectedChart === "lollipopChart_horizontal"}
                onSelect={() => onSelectChart("lollipopChart_horizontal")}
              >
                <HorizontalLollipopChart
                  currentWidget={currentWidget!}
                  recommended={true}
                  showLegend={false}
                  hideName={true}
                  selected={selectedChart === "lollipopChart_horizontal"}
                />
              </WidgetChartWrapper>
            </WidgetItem>
          );
        case "lollipopChart_vertical":
          return (
            <WidgetItem key={index}>
              <WidgetChartWrapper
                recommended={true}
                selected={selectedChart === "lollipopChart_vertical"}
                onSelect={() => onSelectChart("lollipopChart_vertical")}
              >
                <VerticalLollipopChart
                  currentWidget={currentWidget!}
                  recommended={true}
                  showLegend={false}
                  hideName={true}
                  selected={selectedChart === "lollipopChart_vertical"}
                />
              </WidgetChartWrapper>
            </WidgetItem>
          );
        case "barChart_vertical":
          return (
            <WidgetItem key={index}>
              <WidgetChartWrapper
                recommended={true}
                selected={selectedChart === "barChart_vertical"}
                onSelect={() => onSelectChart("barChart_vertical")}
              >
                {currentWidget?.groupBy === null ? (
                  <VerticalBarchart
                    currentWidget={currentWidget!}
                    recommended={true}
                    showLegend={false}
                    hideName={true}
                    selected={selectedChart === "barChart_vertical"}
                  />
                ) : (
                  <GroupedBarChart
                    currentWidget={currentWidget!}
                    recommended={true}
                    showLegend={false}
                    hideName={true}
                    selected={selectedChart === "barChart_vertical"}
                  />
                )}
              </WidgetChartWrapper>
            </WidgetItem>
          );
        case "barChart_horizontal":
          return (
            <WidgetItem key={index}>
              <WidgetChartWrapper
                recommended={true}
                selected={selectedChart === "barChart_horizontal"}
                onSelect={() => onSelectChart("barChart_horizontal")}
              >
                {currentWidget?.groupBy === null ? (
                  <HorizontalBarChart
                    currentWidget={currentWidget!}
                    recommended={true}
                    showLegend={false}
                    hideName={true}
                    selected={selectedChart === "barChart_horizontal"}
                  />
                ) : (
                  <HorizontalGroupedBarChart
                    currentWidget={currentWidget!}
                    recommended={true}
                    showLegend={false}
                    hideName={true}
                    selected={selectedChart === "barChart_horizontal"}
                  />
                )}
              </WidgetChartWrapper>
            </WidgetItem>
          );
        case "sankey":
          const groupBy = currentWidget?.groupBy?.at(0)!;
          const xAxe = currentWidget?.xAxe?.at(0)!;
          const yAxe = currentWidget?.yAxe?.at(0)!;

          return (
            <WidgetItem key={index}>
              <WidgetChartWrapper
                recommended={true}
                selected={selectedChart === "sankey"}
                onSelect={() => onSelectChart("sankey")}
              >
                <SankeyPlot
                  currentWidget={{
                    ...currentWidget!,
                    data: currentWidget?.data,
                    display: [yAxe],
                    arrangeBy: [xAxe, groupBy],
                  }}
                  recommended={true}
                  showLegend={false}
                  hideName={true}
                  selected={selectedChart === "sankey"}
                />
              </WidgetChartWrapper>
            </WidgetItem>
          );
        case "matrix_map":
          const matrixMapChartData =
            currentWidget?.layers?.at(0)?.data || currentWidget?.data || [];
          const dataMatrix = matrixMapChartData?.reduce(
            (
              t: MarkersVisualisationDataDto[],
              r: MarkersVisualisationDataDto
            ) => {
              if (r.state === "California") {
                const exist = t?.some(
                  (n: MarkersVisualisationDataDto) =>
                    n.year === r.year && n.county === r.county
                );
                if (exist) {
                  return t?.map((d: MarkersVisualisationDataDto) => {
                    if (d.year === r.year && d.county === r.county) {
                      return {
                        ...d,
                        value: (Number(d.value) + Number(r.value)).toString(),
                      };
                    }
                    return d;
                  });
                }
                return [
                  ...t,
                  { year: r.year, value: r.value, county: r.county },
                ];
              }
              return t;
            },
            []
          );
          const uniqueValues = dataMatrix?.reduce(
            (t: string[], r: MarkersVisualisationDataDto) => {
              if (!t?.includes(r.county)) {
                return [...t, r.county];
              }
              return t;
            },
            []
          );
          return (
            <WidgetItem key={index}>
              <WidgetChartWrapper
                recommended={true}
                selected={selectedChart === "matrix_map"}
                onSelect={() => onSelectChart("matrix_map")}
              >
                <MatrixChart
                  selected={selectedChart === "matrix_map"}
                  recommended={true}
                  showLegend={false}
                  hideName={true}
                  currentWidget={{
                    ...currentWidget!,
                    layers: [],
                    arrangeBy: ["state", "year"],
                    chartType: "matrixChart",
                    data: dataMatrix,
                    xAxe: ["year"],
                    yAxe: ["value"],
                    groupBy: ["county"],
                    uniqueValues: { county: uniqueValues! },
                  }}
                />
              </WidgetChartWrapper>
            </WidgetItem>
          );
        case "lineChart":
          return (
            <WidgetItem key={index}>
              <WidgetChartWrapper
                recommended={true}
                selected={selectedChart === "lineChart"}
                onSelect={() => onSelectChart("lineChart")}
              >
                {currentWidget?.groupBy ? (
                  <GroupedLineChart
                    currentWidget={currentWidget}
                    recommended={true}
                    showLegend={false}
                    hideName={true}
                    selected={selectedChart === "lineChart"}
                  />
                ) : (
                  <LineChart
                    currentWidget={currentWidget!}
                    recommended={true}
                    showLegend={false}
                    hideName={true}
                    selected={selectedChart === "lineChart"}
                  />
                )}
              </WidgetChartWrapper>
            </WidgetItem>
          );
        case "areaChart":
          return (
            <WidgetItem key={index}>
              <WidgetChartWrapper
                recommended={true}
                selected={selectedChart === "areaChart"}
                onSelect={() => onSelectChart("areaChart")}
              >
                <AreaChart
                  currentWidget={currentWidget!}
                  recommended={true}
                  showLegend={false}
                  hideName={true}
                  selected={selectedChart === "areaChart"}
                />
              </WidgetChartWrapper>
            </WidgetItem>
          );
        case "punchcardChart":
          return (
            <WidgetItem key={index}>
              <WidgetChartWrapper
                recommended={true}
                selected={selectedChart === "punchcardChart"}
                onSelect={() => onSelectChart("punchcardChart")}
              >
                <PunchcardChart
                  currentWidget={currentWidget!}
                  recommended={true}
                  showLegend={false}
                  hideName={true}
                  selected={selectedChart === "punchcardChart"}
                />
              </WidgetChartWrapper>
            </WidgetItem>
          );
        case "polarAreaChart":
          return currentWidget?.groupBy?.length ? null : (
            <WidgetItem key={index}>
              <WidgetChartWrapper
                recommended={true}
                selected={selectedChart === "polarAreaChart"}
                onSelect={() => onSelectChart("polarAreaChart")}
              >
                <PolarAreaChart
                  currentWidget={currentWidget!}
                  recommended={true}
                  showLegend={false}
                  hideName={true}
                  selected={selectedChart === "polarAreaChart"}
                />
              </WidgetChartWrapper>
            </WidgetItem>
          );
        case "radar":
          return (
            <WidgetItem key={index}>
              <WidgetChartWrapper
                recommended={true}
                selected={selectedChart === "radar"}
                onSelect={() => onSelectChart("radar")}
              >
                <RadarChart
                  currentWidget={currentWidget!}
                  recommended={true}
                  showLegend={false}
                  hideName={true}
                  selected={selectedChart === "radar"}
                />
              </WidgetChartWrapper>
            </WidgetItem>
          );
        case "scatterplotChart":
          const uniqueValuesKeys =
            (currentWidget?.uniqueValues &&
              Object.keys(currentWidget?.uniqueValues!)) ||
            [];
          const groupByScatter = currentWidget?.groupBy?.at(0);

          const groupByKey =
            groupByScatter && groupByScatter?.length
              ? groupByScatter
              : uniqueValuesKeys?.at(0);

          return (
            <WidgetItem key={index}>
              <WidgetChartWrapper
                recommended={true}
                selected={selectedChart === "scatterplotChart"}
                onSelect={() => onSelectChart("scatterplotChart")}
              >
                <ScatterPlot
                  currentWidget={{
                    ...currentWidget!,
                    chartType: "scatterplotChart",
                    formatting: getScatterPlotDefaultColors(
                      currentWidget!,
                      groupByKey
                    ),
                    markers: ScatterPlotDefaultMarkers(
                      currentWidget!,
                      groupByKey
                    ),
                    groupBy: currentWidget?.groupBy || [groupByKey ?? ""],
                  }}
                  recommended={true}
                  showLegend={false}
                  hideName={true}
                  selected={selectedChart === "scatterplotChart"}
                />
              </WidgetChartWrapper>
            </WidgetItem>
          );
        case "bubbleChart":
          return (
            <WidgetItem key={index}>
              <WidgetChartWrapper
                recommended={true}
                selected={selectedChart === "bubbleChart"}
                onSelect={() => onSelectChart("bubbleChart")}
              >
                <BubbleChart
                  currentWidget={{
                    ...currentWidget!,
                    chartType: "bubbleChart",
                  }}
                  recommended={true}
                  showLegend={false}
                  hideName={true}
                  selected={selectedChart === "bubbleChart"}
                />
              </WidgetChartWrapper>
            </WidgetItem>
          );
        case "matrix":
          const groupByMatrix = currentWidget?.arrangeBy?.at(0)!;
          const arrangeBy = currentWidget?.uniqueValues
            ? Object.keys(currentWidget?.uniqueValues!)?.at(1)!
            : currentWidget?.arrangeBy?.at(0)!;
          const display =
            currentWidget?.display?.at(0)! || currentWidget?.yAxe?.at(0)!;

          return (
            <WidgetItem key={index}>
              <WidgetChartWrapper
                recommended={true}
                selected={selectedChart === "matrix"}
                onSelect={() => onSelectChart("matrix")}
              >
                <MatrixChart
                  currentWidget={{
                    ...currentWidget!,
                    chartType: "matrixChart",
                    arrangeBy: [],
                    groupBy: [groupByMatrix],
                    xAxe: [arrangeBy],
                    yAxe: [display],
                  }}
                  recommended={true}
                  showLegend={false}
                  hideName={true}
                  selected={selectedChart === "matrix"}
                />
              </WidgetChartWrapper>
            </WidgetItem>
          );
        case "sankey_map":
          const sankeyMapChartData =
            currentWidget?.layers?.at(0)?.data || currentWidget?.data || [];
          const data = sankeyMapChartData?.reduce(
            (
              t: MarkersVisualisationDataDto[],
              r: MarkersVisualisationDataDto
            ) => {
              const exist = t?.some(
                (n: MarkersVisualisationDataDto) => n.year === r.year
              );
              if (exist) {
                return t?.map((d: MarkersVisualisationDataDto) => {
                  if (d.year === r.year) {
                    return {
                      ...d,
                      value: (Number(d.value) + Number(r.value)).toString(),
                    };
                  }
                  return d;
                });
              }
              return [...t, r];
            },
            []
          );
          return (
            <WidgetItem key={index}>
              <WidgetChartWrapper
                recommended={true}
                selected={selectedChart === "sankey_map"}
                onSelect={() => onSelectChart("sankey_map")}
              >
                <SankeyPlot
                  currentWidget={{
                    ...currentWidget!,
                    palette: currentWidget?.palette || undefined,
                    chartType: "sankeyChart",
                    blockId: currentWidget?.blockId?.toString(),
                    display: ["value"],
                    arrangeBy: ["state", "year"],
                    data,
                    query:
                      currentWidget?.layers?.at(0)?.query ||
                      currentWidget?.query,
                  }}
                  recommended={true}
                  showLegend={false}
                  hideName={true}
                  selected={selectedChart === "sankey_map"}
                />
              </WidgetChartWrapper>
            </WidgetItem>
          );
        case "map_matrix":
          return (
            <WidgetItem key={index}>
              <WidgetChartWrapper
                map={true}
                recommended={true}
                selected={selectedChart === "map_matrix"}
                onSelect={() => onSelectChart("map_matrix")}
              >
                <UniversalMap
                  showLegend={false}
                  recommended={true}
                  selected={selectedChart === "map_matrix"}
                  currentWidget={{
                    ...currentWidget!,
                    layers: [
                      {
                        data: currentWidget?.data,
                        name: "Members by Year",
                        query: currentWidget?.query!,
                        colour: "green",
                        format: "geojson",
                        tooltip: true,
                        analytics: "average",
                        datasetId: currentWidget?.datasetId!,
                        geospatialData: ["state", "county"],
                        arrangeByMetric: ["value"],
                        visualisationType: "markers",
                      },
                    ],
                    chartType: "mapChart",
                    xAxe: ["year"],
                    yAxe: ["value"],
                    groupBy: ["county"],
                    uniqueValues: {},
                  }}
                />
              </WidgetChartWrapper>
            </WidgetItem>
          );
        default:
          return null;
      }
    });
  };

  const renderCurrentWidget = () => {
    if (!currentWidget) {
      return null;
    }
    const ChartComponent: any =
      currentWidget &&
      currentWidget.chartType &&
      WIDGETS[currentWidget.chartType];

    return (
      <WidgetItem key={currentWidget.id}>
        <WidgetChartWrapper
          recommended={true}
          storytelling={false}
          map={currentWidget.chartType === "mapChart"}
          selected={selectedChart === currentWidget.id}
          onSelect={() => onSelectChart(currentWidget.id)}
        >
          <ChartComponent
            recommended={true}
            showLegend={false}
            storytelling={false}
            selected={selectedChart === currentWidget.id}
            currentWidget={currentWidget}
            hideName={true}
          />
        </WidgetChartWrapper>
      </WidgetItem>
    );
  };

  return (
    <AvailableWidgetsWrapper>
      <AvailableWidgetsHeadingWrapper>
        <AvailableWidgetsHeading>Available Widgets</AvailableWidgetsHeading>
        <AvailableWidgetsCount>
          {AVAILABLE_WIDGETS[currentWidget?.chartType!]?.length +
            (recomendedToMap ? 1 : 0)}
        </AvailableWidgetsCount>
      </AvailableWidgetsHeadingWrapper>

      <WidgetsItemsWrapper>
        {renderCurrentWidget()}
        {renderRecommendedWidgets()}
      </WidgetsItemsWrapper>
    </AvailableWidgetsWrapper>
  );
};
