import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const NavLinksWrapper = styled.div`
  display: flex;
  gap: 4px;
  padding: 20px 0;
`

export const NavLinkButton = styled(NavLink)`
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  padding: 4px 12px;
  border: 1px solid transparent;
  border-radius: 20px;
  color: ${({ theme }) => theme.neutralWeak};
  background-color: ${({ theme }) => theme.neutralBackgroundBase};

  &.active {
    color: ${({ theme }) => theme.neutralText};
    border: 1px solid ${({ theme }) => theme.neutralBorder};
  }

  &:hover {
    color: ${({ theme }) => theme.neutralText};
    background-color: ${({ theme }) => theme.neutralBackgroundHover};
  }
`;
