import styled from "styled-components";

export const InputContainer = styled.div`
  padding: 16px 20px;
  position: relative;
`;

export const Heading = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.neutralText};
`;

export const InputWrapper = styled.div<{ $hasError?: boolean }>`
  display: flex;
  height: 36px;
  border: 1px solid ${({ theme, $hasError }) => 
    $hasError ? theme.error || '#FF4D4F' : theme.neutralBorder};
  border-radius: 50px;
  margin-top: 8px;
  width: 100%;
  overflow: hidden;

  &:focus-within {
    border: 1px solid ${({ theme, $hasError }) => 
      $hasError ? theme.alertText || '#FF4D4F' : theme.primaryBorder};
  }
`;

export const StyledInput = styled.input<{ $hasError?: boolean }>`
  width: 100%;
  border-style: none;
  padding: 0 14px;
  height: 36px;
  align-self: center;
  border-radius: 0 50px 50px 0;
  outline: none;
  color: ${({ theme }) => theme.neutralText};
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.neutralBackgroundHover};
  }

  &:focus-within {
    background-color: ${({ theme }) => theme.neutralBackgroundBase};
  }
`;

export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.alertText || '#FF4D4F'};
  font-size: 12px;
  margin-top: 4px;
`;
