import { styled } from "styled-components";

export const HouseDetailsContainer = styled.div`
  background-color: #ffffff;
  padding-bottom: 33px;
  width: 100%;
  align-content: center;
  margin: auto;
  overflow-y: auto;
  height: calc(96vh - 128px);
`;

export const HouseDetailsHero = styled.div`
  background-color: #9fa4ad14;
  padding: 20px;
  margin-bottom: 20px;
`;

export const HouseDetailsContent = styled.div`
  max-width: 720px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0 auto;
  width: 100%;
`;
