import React from "react";
import { Checkbox, Checkmark, Container, Input } from "./style";

type Props = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: React.ChangeEventHandler;
  checked?: boolean;
  error?: boolean;
  name?: string;
  disabled?: boolean;
  errors?: string;
  touched?: boolean;
  status?: "checked" | "indeterminate" | "none";
  className?: string;
};

export const CheckBox = ({
  onChange,
  checked,
  disabled,
  error,
  errors,
  name,
  touched,
  status,
  className,
}: Props) => {
  return (
    <Checkbox
      className={className}
      $error={!!(errors && touched) || error}
      $indeterminate={status === "indeterminate"}
    >
      <Container>
        <Input
          disabled={disabled}
          type="checkbox"
          name={name}
          onChange={!disabled ? onChange : () => {}}
          checked={checked || (status && status !== "none")}
        />
        <Checkmark />
      </Container>
    </Checkbox>
  );
};
