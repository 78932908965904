import { PayloadAction } from "@reduxjs/toolkit";
import { deleteProfile, getProfile, updateProfile } from "../../api/profiles";
import { IProfile, IUpdateProfile } from "../../models/Widgets";
import { ServerResponse } from "../../models";
import { put, call, takeLatest } from "redux-saga/effects";
import { setProfiles } from "../slices/profiles";
import { toast } from "react-toastify";


export function* handleGetProfile({ payload }: PayloadAction<IProfile>) {
  const { response }: ServerResponse = yield call(getProfile);
  
  if (response?.status === 200) { 
    yield put(setProfiles(response.data));
    payload?.callbacks?.onSuccess(response.data);
  }
}

export function* handleDeleteProfile({ payload }: PayloadAction<IProfile>) {
  const { response }: ServerResponse = yield call(deleteProfile);

  if (response?.status === 200) {
    payload?.callbacks?.onSuccess();
    toast.success("Profile deleted");
  }
}

export function* handleUpdateProfile({ payload }: PayloadAction<IUpdateProfile>) { 
  const { response, error }: ServerResponse = yield call(updateProfile, payload);

  if (error) {
    console.error("Update profile failed", error);
  }

  if (response?.status === 200) {
    yield put(setProfiles(response.data));
    payload?.callbacks?.onSuccess();
    toast.success("Profile saved");

  }
}



export function* watchProfileActions() {
  yield takeLatest("profile/requestProfile", handleGetProfile);
  yield takeLatest("profile/requestDeleteProfile", handleDeleteProfile);
  yield takeLatest("profile/requestUpdateProfile", handleUpdateProfile);
}