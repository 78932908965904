import React, { SetStateAction, useState } from "react";

import { Button } from "../Button";

import { ReactComponent as MetricValueIcon } from "../../assets/dimensionIcons/metric-value.svg";
import { ReactComponent as MetricValueGrayIcon } from "../../assets/dimensionIcons/metric-valueGray.svg";
import { ReactComponent as CategoryIcon } from "../../assets/dimensionIcons/category.svg";
import { ReactComponent as CategoryGrayIcon } from "../../assets/dimensionIcons/categoryGray.svg";
import { ReactComponent as DateTimeIcon } from "../../assets/dimensionIcons/date-time.svg";
import { ReactComponent as DateTimeGrayIcon } from "../../assets/dimensionIcons/date-timeGray.svg";
import { ReactComponent as LocationIcon } from "../../assets/dimensionIcons/location.svg";
import { ReactComponent as LocationGrayIcon } from "../../assets/dimensionIcons/locationGray.svg";
import { ReactComponent as TextGrayIcon } from "../../assets/dimensionIcons/textGray.svg";
import { ReactComponent as TextIcon } from "../../assets/dimensionIcons/text.svg";
import { ReactComponent as MetaIcon } from "../../assets/dimensionIcons/meta.svg";
import { ReactComponent as MetaGrayIcon } from "../../assets/dimensionIcons/metaGray.svg";

import {
  ActionButton,
  ActionsPage,
  BackButton,
  DimensionSettingsFooterWrapper,
  DimensionSettingsIconsItemsWrapper,
  DimensionSettingsInput,
  DimensionSettingsInputLabel,
  DimensionSettingsInputWrapper,
  DimensionSettingsTypeLabel,
  DimensionSettingsTypeWrapper,
  DimensionSettingsWrapper,
  EditPage,
  Separator,
} from "./styles";
import { useDispatch, useSelector } from "react-redux";
import {
  requestUpdateDatasetById,
  updateDatasetData,
  updateDatasetHeader,
} from "../../store/slices/datasets";
import { updateFileData } from "../../store/slices/files";
import { getActiveModal } from "../../store/selectors/modals";
import {
  getSelectedDataset,
  getSelectedFileView,
} from "../../store/selectors/datasets";
import { toast } from "react-toastify";
import { RadioButtonComponent } from "./components/RadioButtonComponent";
import {
  ArrowDown,
  ArrowLeft,
  ArrowRepeat,
  Arrows,
  ArrowUp,
  Pencil,
  PinAngle,
  PinAngleFill,
  Star,
} from "react-bootstrap-icons";
import { ILastAction } from "../../pages/Resources/pages/Datasets/pages/DatasetPreview";
import {
  sortTableAscending,
  sortTableDescending,
  tablePinColumn,
} from "./utils/tableActions";

interface IShowDimensionSettings {
  header: string;
  top: number;
  left: number;
  suggestedType?: string;
  key: string;
  errorsDetected: boolean;
  subtype: string;
}

interface IDimensionSettings {
  edit?: boolean;
  filePath: string;
  setShowDimensionSettings: SetStateAction<any>;
  showDimensionSettings: IShowDimensionSettings;
  topSpace?: number;
  lastAction: ILastAction;
  setLastAction: React.Dispatch<React.SetStateAction<ILastAction>>;
  selectedDatasetContents: any;
  setSelectedDatasetContents: React.Dispatch<any>;
  pinedColumns: string[];
  setPinedColumns: React.Dispatch<React.SetStateAction<string[]>>;
}

export const DimensionSettings = ({
  setShowDimensionSettings,
  showDimensionSettings: {
    header,
    top,
    left,
    suggestedType,
    key,
    errorsDetected,
    subtype,
  },
  setLastAction,
  pinedColumns,
  lastAction,
  filePath,
  edit,
  selectedDatasetContents,
  setSelectedDatasetContents,
  setPinedColumns,
  topSpace,
}: IDimensionSettings) => {
  const dispatch = useDispatch();
  const activeModal = useSelector(getActiveModal);
  const [columnName, setColumnName] = useState<string>(header);
  const [selectedCategory, setSelectedCategory] = useState<string>(
    suggestedType || ""
  );
  const [isEdit, setIsEdit] = useState(false);
  const fileSelected = useSelector(getSelectedFileView);
  const selectedDataset = useSelector(getSelectedDataset);
  const handleCheckboxChange = (category: string) => {
    setSelectedCategory(category);
  };

  const settingsItemsData = [
    {
      type: "categorical",
      label: "Category",
      icon: <CategoryIcon />,
      grayIcon: <CategoryGrayIcon />,
    },
    {
      type: "location",
      label: "Location",
      icon: <LocationIcon />,
      grayIcon: <LocationGrayIcon />,
    },
    {
      type: "datetime",
      label: "Date / Time",
      icon: <DateTimeIcon />,
      grayIcon: <DateTimeGrayIcon />,
    },
    {
      type: "metric",
      label: "Metric / Value",
      icon: <MetricValueIcon />,
      grayIcon: <MetricValueGrayIcon />,
    },
    {
      type: "text",
      label: "Text",
      icon: <TextIcon />,
      grayIcon: <TextGrayIcon />,
    },
    {
      type: "meta",
      label: "Meta",
      icon: <MetaIcon />,
      grayIcon: <MetaGrayIcon />,
    },
  ];

  const handleSave = () => {
    if (activeModal.includes("datasetLibraryModal")) {
      if (selectedDataset !== null && selectedDataset.id) {
        const updatedFields = selectedDataset?.fields?.map((field) => {
          if (field.key === key) {
            return {
              ...field,
              label: columnName,
              type: selectedCategory,
              subtype: selectedCategory,
              icon: selectedCategory,
            };
          }
          return field;
        });

        dispatch(
          updateDatasetData({
            filePath,
            key,
            label: columnName,
            category: selectedCategory,
          })
        );

        dispatch(
          requestUpdateDatasetById({
            id: selectedDataset.id,
            name: selectedDataset.name,
            fields: updatedFields!,
          })
        );
      }
    } else {
      if (
        fileSelected.parsedSuggestions
          .map((suggestion) => suggestion.label)
          ?.filter((label: string) => label !== header)
          .includes(columnName)
      ) {
        toast.error("Column name already exists!");
      } else {
        dispatch(
          updateDatasetHeader({
            label: columnName,
            selectedType: selectedCategory,
            selectedSubType: selectedCategory,
            objectKey: key,
          })
        );
        dispatch(
          updateFileData({
            key,
            newHeader: columnName,
            filePath: filePath,
            type: selectedCategory,
          })
        );
      }
    }

    setShowDimensionSettings(null);
  };

  const handleLastAction = (action: "sortA" | "sortD", key: string) => {
    if (["sortA", "sortD"].includes(action)) {
      setLastAction({
        key,
        subtype,
        ascending: action === "sortA",
        descending: action === "sortD",
      });
    }
  };

  const isPinned = pinedColumns.includes(key);

  return (
    <DimensionSettingsWrapper $top={top - (topSpace || 0)} $left={left}>
      {!isEdit ? (
        <ActionsPage>
          <ActionButton
            $active={lastAction.key === key && lastAction.ascending}
            onClick={() => {
              sortTableAscending({
                key,
                subtype,
                selectedDatasetContents,
                setSelectedDatasetContents,
              });
              setShowDimensionSettings(null);
              handleLastAction("sortA", key);
            }}
          >
            <ArrowUp />
            Sort Ascending
          </ActionButton>
          <ActionButton
            $active={lastAction.key === key && lastAction.descending}
            onClick={() => {
              sortTableDescending({
                key,
                subtype,
                selectedDatasetContents,
                setSelectedDatasetContents,
              });
              setShowDimensionSettings(null);
              handleLastAction("sortD", key);
            }}
          >
            <ArrowDown />
            Sort Descending
          </ActionButton>
          <Separator />
          <ActionButton
            $active={isPinned}
            onClick={() => {
              tablePinColumn({ key, pinedColumns, setPinedColumns });
              setShowDimensionSettings(null);
            }}
          >
            {isPinned ? <PinAngleFill /> : <PinAngle />}
            {isPinned ? "No Pin" : "Pin Column"}
          </ActionButton>
          <Separator />
          <ActionButton>
            <Arrows />
            Autosize This Column
          </ActionButton>
          <ActionButton>
            <Arrows />
            Autosize All Column
          </ActionButton>
          <Separator />
          <ActionButton>
            <ArrowRepeat />
            Reset All Columns
          </ActionButton>
          <ActionButton>
            <Star />
            Set as primary
          </ActionButton>
          {edit && (
            <>
              <Separator />
              <ActionButton onClick={() => setIsEdit(true)}>
                <Pencil />
                Edit
              </ActionButton>
            </>
          )}
        </ActionsPage>
      ) : (
        <EditPage>
          <BackButton onClick={() => setIsEdit(false)}>
            <ArrowLeft /> Back
          </BackButton>

          <DimensionSettingsInputWrapper>
            <DimensionSettingsInputLabel>Name</DimensionSettingsInputLabel>
            <DimensionSettingsInput
              defaultValue={columnName}
              onChange={(e) => setColumnName(e.target.value)}
            />
          </DimensionSettingsInputWrapper>

          <Separator />

          <DimensionSettingsTypeWrapper>
            <DimensionSettingsTypeLabel>Type</DimensionSettingsTypeLabel>
            <DimensionSettingsIconsItemsWrapper>
              {settingsItemsData.map(({ type, label, icon, grayIcon }) => (
                <RadioButtonComponent
                  {...{
                    selectedCategory,
                    type,
                    handleCheckboxChange,
                    label,
                    icon,
                    grayIcon,
                  }}
                />
              ))}
            </DimensionSettingsIconsItemsWrapper>
          </DimensionSettingsTypeWrapper>

          <Separator />

          <DimensionSettingsFooterWrapper>
            <Button
              onClick={handleSave}
              fullWidth
              variant={
                !errorsDetected &&
                header === columnName &&
                suggestedType === selectedCategory
                  ? "disabled"
                  : "secondary"
              }
              size="full"
              name="Save"
              disabled={
                !errorsDetected &&
                header === columnName &&
                suggestedType === selectedCategory
              }
            />
          </DimensionSettingsFooterWrapper>
        </EditPage>
      )}
    </DimensionSettingsWrapper>
  );
};
