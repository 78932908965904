import { styled } from "styled-components";

export const FeatureContainer = styled.div`
  margin-bottom: 12px;
`;

export const FeatureHeader = styled.h6`
  font-size: 10px;
  line-height: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.neutralTextWeak};
  margin-bottom: 8px;
  text-transform: uppercase;
`;

export const FeatureRow = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 0;
`;

export const FeatureLabel = styled.div<{ $isDark: boolean }>`
  display: flex;
  align-items: center;
  color: ${({ theme, $isDark }) =>
    $isDark ? theme.neutralText : theme.neutralTextWeak};
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
`;

export const FeatureArrowIcon = styled.span`
  margin-right: 8px;
  font-size: 9px;
  margin-top: 2px;
  color: ${({ theme }) => theme.neutralTextWeak};
`;

export const FeatureCount = styled.span`
  font-weight: 500;
  color: ${({ theme }) => theme.neutralText};
  margin-left: 8px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
`;
