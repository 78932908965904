import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentTopic, getSelectedSubTopics } from '../../../store/selectors/topics';
import { setSelectedSubTopics } from '../../../store/slices/topics';
import { Dataset, Variable } from '../../../pages/Resources/pages/PublicResources/pages/SearchDataById/interfaces/companies.interface';
import {
  CloseIcon,
  HeaderTitle,
  ModalWrapper,
  TemporarBackButton,
  TopicsBody,
  TopicsHeader,
  TopicsHeaderTop,
  TopicsSelectWrapper,
  TopicsWrapper,
  SeparatorLine,
  TemporarBackButtonText
} from './styles';
import { TopicSelect } from '../../TopicSelect';
import { ModalOverlay } from '../styles';

interface ModalProps {
  closeModal: () => void;
}

export const Topics = ({ closeModal }: ModalProps) => {
  const dispatch = useDispatch();
  const topic = useSelector(getCurrentTopic);
  const selectedSubTopics = useSelector(getSelectedSubTopics);
  const [isClosing, setIsClosing] = useState(false);
  const [secondStep, setSecondStep] = useState<Dataset>();

  const handleOnClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      closeModal();
    }, 400);
  };

  const handleSelectAll = () => {
    if (topic?.id) {
      const updatedSubTopics = { ...selectedSubTopics };
      if (updatedSubTopics[topic.id]) {
        // If the topic has selected datasets, delete the topic entry
        delete updatedSubTopics[topic.id];
      } else {
        // If the topic does not have selected datasets, set it to all datasets
        updatedSubTopics[topic.id] = topic.datasets;
      }
      dispatch(setSelectedSubTopics(updatedSubTopics));
    }
  };
  
  const handleDatasetChange = (dataset: Dataset, isSelected: string) => {
    if (!topic?.id) return;
    const updatedSubTopics = structuredClone(selectedSubTopics || {});

    // Check if the dataset is being selected (checked) or is in an intermediate state
    if (isSelected === 'checked' || isSelected === 'indeterminate') {
      const filtered = updatedSubTopics?.[topic.id]?.filter((el: Dataset) => el.id !== dataset.id) || [];
      const newSelected = filtered.length ? { ...updatedSubTopics, [topic.id]: filtered } : { ...updatedSubTopics };
      // If no datasets remain for the topic, delete the topic key from the object
      if (filtered.length === 0) delete newSelected[topic.id]; // Remove the topic key if no datasets are left
      dispatch(setSelectedSubTopics(newSelected));
    } else {
      // If the dataset is being added (not checked or intermediate), add it to the selected subtopics
      dispatch(
        setSelectedSubTopics({
          ...updatedSubTopics,
          [topic.id]: [...(updatedSubTopics?.[topic.id] || []), dataset],
        })
      );
    }
  };

  const handleVariableChange = (dataset: Dataset, variable: Variable, isSelected: string) => {
    if (!topic?.id) return;
    const updatedSubTopics = structuredClone(selectedSubTopics || {});
    if (!updatedSubTopics[topic.id]) {
      updatedSubTopics[topic.id] = [];
    }
    if (isSelected === 'checked') {
      // Find the dataset and remove the variable from its variables list
      const datasetIndex = updatedSubTopics[topic.id].findIndex((subTopic: Dataset) => subTopic.id === dataset.id);
      if (datasetIndex !== -1) {
        // Check if the dataset has variables before trying to filter
        if (updatedSubTopics[topic.id][datasetIndex]?.variables) {
          // Remove the variable from the dataset's variables list
          updatedSubTopics[topic.id][datasetIndex].variables = updatedSubTopics[topic.id][datasetIndex].variables.filter((varItem: Variable) => varItem?.name !== variable?.name);
          // If no variables are left in the dataset, remove the dataset itself from the selection
          if (updatedSubTopics[topic.id][datasetIndex].variables.length === 0) {
            updatedSubTopics[topic.id].splice(datasetIndex, 1); // Remove dataset if no variables are left
          }
        }
      }
    } else {
      const existingDataset = updatedSubTopics[topic.id].find((subTopic: Dataset) => subTopic.id === dataset.id);
      if (existingDataset) {
        // If the dataset already exists, add the variable to its variables list
        existingDataset.variables.push({ ...variable });
      } else {
        // If the dataset does not exist, create a new entry for it with the variable
        updatedSubTopics[topic.id].push({ ...dataset, variables: [{ ...variable }] });
      }
    }
    dispatch(setSelectedSubTopics(updatedSubTopics));
  };

  return (
    <ModalOverlay onClick={handleOnClose} $isClosing={isClosing}>
      <ModalWrapper onClick={(e) => e.stopPropagation()}>
        {!secondStep ? (
          <TopicsWrapper>
            <CloseIcon onClick={handleOnClose} />
            <TopicsHeader>
              <TopicsHeaderTop>
                <TemporarBackButton>
                  <TemporarBackButtonText onClick={handleOnClose}>Topics</TemporarBackButtonText>
                </TemporarBackButton>
                <HeaderTitle>{topic?.name}</HeaderTitle>
              </TopicsHeaderTop>
            </TopicsHeader>
            <TopicsBody>
              <TopicSelect
                selected={
                  topic?.id
                    ? selectedSubTopics?.[topic.id]?.length === topic.datasets.length
                      ? 'checked'
                      : selectedSubTopics?.[topic.id]?.length
                        ? 'indeterminate'
                        : 'none'
                    : 'none'
                }
                disablePadding
                onChange={handleSelectAll}
                name="All"
                disableIcon
              />
              <SeparatorLine />
              <TopicsSelectWrapper>
                {topic?.datasets?.map((dataset) => {
                  const selectedVars = selectedSubTopics?.[topic.id]?.find((subTopic: Dataset) => subTopic?.id === dataset.id)?.variables || [];
                  const isIntermediate = selectedVars.length > 0 && selectedVars.length < dataset.variables.length;
                  const isSelected = selectedVars.length === dataset.variables.length ? 'checked' : isIntermediate ? 'indeterminate' : 'none';

                  return (
                    <TopicSelect
                      key={dataset.title + dataset.id + dataset.topicId}
                      selected={isSelected}
                      disablePadding
                      onChange={() => handleDatasetChange(dataset, isSelected)}
                      onClick={() => setSecondStep(dataset)}
                      disableIcon
                      name={dataset.title}
                    />
                  );
                })}
              </TopicsSelectWrapper>
            </TopicsBody>
          </TopicsWrapper>
        ) : (
          <TopicsWrapper>
            <CloseIcon onClick={handleOnClose} />
            <TopicsHeader>
              <TopicsHeaderTop>
                <TemporarBackButton>
                  <TemporarBackButtonText onClick={handleOnClose}>Topics</TemporarBackButtonText>
                  {'/'}
                  <TemporarBackButtonText onClick={() => setSecondStep(undefined)}>{topic?.name}</TemporarBackButtonText>
                </TemporarBackButton>
                <HeaderTitle>{secondStep?.title}</HeaderTitle>
              </TopicsHeaderTop>
            </TopicsHeader>
            <TopicsBody>
              <TopicsSelectWrapper>
                {secondStep?.variables?.map((variable) => {
                  const isSelected = topic?.id &&
                    selectedSubTopics?.[topic.id]?.find((subTopic) => subTopic.id === secondStep.id)?.variables?.some((varItem) => varItem?.name === variable?.name)
                    ? 'checked'
                    : 'none';

                  return (
                    <TopicSelect
                      key={variable.title + variable.id + variable.type}
                      selected={isSelected}
                      disablePadding
                      onChange={() => handleVariableChange(secondStep, variable, isSelected)}
                      disableIcon
                      name={variable.title}
                    />
                  );
                })}
              </TopicsSelectWrapper>
            </TopicsBody>
          </TopicsWrapper>
        )}
      </ModalWrapper>
    </ModalOverlay>
  );
};
