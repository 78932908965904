import { styled } from "styled-components";

export const LayoutRealEstateContainer = styled.div`  
    height: calc(100vh - 64px - 68px);
`;

export const WidgetWrapper = styled.div<{
    $storytelling?: boolean;
    $map?: boolean;
    $selected?: boolean;
    $recommended?: boolean;
  }>`
    display: flex;
    position: relative;
    flex-direction: column;
    padding: ${({ $storytelling, $map }) =>
      $map ? "" : $storytelling ? "" : "16px"};
    border: ${({ theme, $storytelling, $selected, $recommended }) =>
      $selected && !$recommended
        ? `1px solid transparent`
        : !$storytelling && !$selected
          ? `1px solid ${theme.neutralBorder}`
          : `1px solid transparent`};
    border-radius: 8px;
    height: ${({ $storytelling }) => ($storytelling ? "360px" : "100%")};
    width: 100%;
    min-width: 307px;
    min-height: 220px;
    background: ${({ theme, $selected, $recommended }) =>
      $selected && $recommended ? theme.primaryBackgroundPublish : ""};
  
    & .barchartAxisLine {
      stroke: ${({ theme }) => theme.neutralBorderMedium} !important;
    }
  `;
  