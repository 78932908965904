import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dataset, Topic } from "../../pages/Resources/pages/PublicResources/pages/SearchDataById/interfaces/companies.interface";

interface Props {
  currentTopic?: Topic;
  selectedSubTopics?: Record<string, Dataset[]>;
}

const initialState: Props = {
  currentTopic: undefined,
  selectedSubTopics: {}
};

const topicsSlices = createSlice({
  name: "topics",
  initialState,
  reducers: {
    setCurrentTopic: (
      state: Props,
      action: PayloadAction<Topic | undefined>
    ) => {
      return {
        ...state,
        currentTopic: action.payload,
      };
    },
    setSelectedSubTopics: (
      state: Props,
      action: PayloadAction<Record<string, Dataset[]> | undefined>
    ) => {
      return {
        ...state,
        selectedSubTopics: action.payload,
      };
    },
  }
})

export const {
  setCurrentTopic,
  setSelectedSubTopics,
} = topicsSlices.actions;

export default topicsSlices.reducer;