import { styled } from "styled-components";

export const FLContainer = styled.div`
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid #dfe1e4;
`;

export const FLTitle = styled.h6`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.neutralText};
  font-weight: 600;
  margin-bottom: 14px;
`;

export const FLLabel = styled.div`
  color: ${({ theme }) => theme.neutralTextWeak};
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
`;

export const FLLabelDetails = styled.div`
  color: ${({ theme }) => theme.neutralText};
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
`;

export const FLRow = styled.div`
  display: flex;
  gap: 8px;
`;

export const FLContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;
