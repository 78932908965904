import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Search } from "react-bootstrap-icons";

import { requestGetAllDatasets } from "../../../../store/slices/datasets";
import { Loader } from "../../../../components/Loader";
import { getAllDatasets } from "../../../../store/selectors/datasets";
import { ResourcesList } from "../../../../components/ResourcesPagesComponents/PublicResources/ResourceList";
import { SearchDataById } from "./pages/SearchDataById";
import { SearchData } from "./pages/SearchData";
import { ResourcesNoData } from "../NoData";
import { ReactComponent as EmptyIcon } from "../../../../assets/resourcesPagesIcons/NoPublic.svg";

interface Props { }

const PublicResources: React.FC<Props> = () => {
  const allDatasets = useSelector(getAllDatasets);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestGetAllDatasets({}));
  }, [dispatch]);

  return (
    <>
      {allDatasets.isLoading ? (
        <Loader />
      ) : !allDatasets.items.length ? (
        <ResourcesNoData
          Icon={EmptyIcon}
          title="No datasets were discovered"
          subTitle={`Start searching and adding datasets by pressing button below.`}
          IconButton={Search}
          name={"Search Public Data"}
          onClick={() => { }}
        />
      ) : (
        <ResourcesList data={allDatasets} />
      )}
    </>
  );
};

export { PublicResources as Index, SearchData, SearchDataById }
