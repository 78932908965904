import { styled } from "styled-components";
import { GeoAlt } from "react-bootstrap-icons";

export const SearchContainer = styled.div`
  position: relative;
`;

export const SearchResultContainer = styled.ul`
  padding: 8px 12px;
  border: 1px solid #dfe1e4;
  margin-top: 8px;
  border-radius: 8px;
  background-color: #ffffff;
  position: absolute;
  z-index: 11;
  list-style: none;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SearchResultItem = styled.li`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding-top: 6px;
  padding-bottom: 6px;
  color: ${({ theme }) => theme.neutralText};
  display: inline-flex;
  gap: 8px;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

export const LocationIcon = styled(GeoAlt)`
  width: 16px;
  height: 16px;
`;
