import styled from "styled-components";
import { ThreeDots } from "react-bootstrap-icons";

export const RowWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.neutralBorder};
  padding: 8px 0;
  &:last-child {
    border-bottom: none;
  }
`;

export const FileName = styled.div`
  color: ${({ theme }) => theme.neutralText};
  word-wrap: break-word;
  font-weight: 500;
  max-width: 1150px;
  font-size: 14px;
  cursor: pointer;
  margin: 0 16px;
  width: 100%;
`;

export const Format = styled.div`
  display: flex;
  gap: 3px;
  color: ${({ theme }) => theme.neutralText};
  font-size: 14px;
  font-weight: 400;
  max-width: 160px;
  width: 100%;
  padding: 0 16px;
  margin-left: auto;
`

export const UsedInProjects = styled.div`
  gap: 3px;
  display: flex;
  padding: 0 16px;
  font-size: 14px;
  max-width: 160px;
  width: 100%;
  font-weight: 400;
  color: ${({ theme }) => theme.neutralText};
`

export const ProjectUsed = styled.span`
  background-color: ${({ theme }) => theme.neutralBackground};
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  padding: 0 4px;
  border-radius: 50%;
`

export const UploadedDate = styled.div`
  display: flex;
  gap: 3px;
  color: ${({ theme }) => theme.neutralText};
  font-size: 14px;
  font-weight: 400;
  max-width: 160px;
  width: 100%;
  padding: 0 16px;
`

export const Owner = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 3px;
  max-width: 128px;
  margin: 0 16px;
  color: ${({theme}) => theme.neutralTextWeak};
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`

export const More = styled(ThreeDots)`
  color: ${({ theme }) => theme.neutralWeak};
  cursor: pointer;
  width: 20px;
  margin: 0 12px
`;