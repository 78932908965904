import { StyledHousePerformance } from "./styles";

interface Props {
  className?: string;
  price: number;
  priceEstimatedMonthly: number;
  beds: number;
  baths: number;
  squareFeet: number;
  address: string;
  priceCut: number;
}

const formatPrice = (price: number): string => {
  return price?.toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });
};

const formatSquareFeet = (sqft: number): string => {
  return sqft?.toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });
};

const HousePerformance = ({
  className,
  price,
  priceCut,
  priceEstimatedMonthly,
  baths,
  beds,
  squareFeet,
  address,
}: Props) => {
  return (
    <StyledHousePerformance className={className}>
      <h5 className="house-performance-title">Performance</h5>
      <div className="house-performance-numbers">
        <div className="house-performance-number-container">
          <div className="house-performance-number">
            <span className="house-performance-curr">$</span>
            {formatPrice(price)}
          </div>
          <div className="house-performance-info">
            Price, est. {priceEstimatedMonthly}/mo
          </div>
        </div>
        <div className="house-performance-number-container">
          <div className="house-performance-number">{beds}</div>
          <div className="house-performance-info">Beds</div>
        </div>

        <div className="house-performance-number-container">
          <div className="house-performance-number">{baths}</div>
          <div className="house-performance-info">Bath</div>
        </div>

        <div className="house-performance-number-container">
          <div className="house-performance-number">{formatSquareFeet(squareFeet)}</div>
          <div className="house-performance-info">Sq. Ft.</div>
        </div>
      </div>

      <div className="house-performace-footer">
        <div className="house-performance-adderss">
          <p>{address}</p>
          <div className="house-performance-info">Address</div>
        </div>

        <div className="house-performance-price">
          <p>Price cut: {priceCut} (10/12)</p>
          <div className="house-performance-info">Status</div>
        </div>
      </div>
    </StyledHousePerformance>
  );
};

export default HousePerformance;
