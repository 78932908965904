import styled from "styled-components";
import { SquareFill, ChevronExpand } from "react-bootstrap-icons";

export const DatasetsTable = styled.div<{ $disableBorder?: boolean; $disableRoundingTop?: boolean }>`
  width: 100%;
  border: ${({ $disableBorder }) => $disableBorder ? 0 : "1px"} solid ${({ theme }) => theme.neutralBorder};
  border-radius: ${({ $disableRoundingTop }) => $disableRoundingTop ? 0 : "8px"} ${({ $disableRoundingTop }) => $disableRoundingTop ? 0 : "8px"} 8px 8px;
`;

export const HeadingWrapper = styled.div<{$disableRoundingTop?: boolean}>`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.neutralTextWeak};
  height: 44px;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.neutralBorder};
  background-color: #9FA4AD14;
  border-radius: ${({ $disableRoundingTop }) => $disableRoundingTop ? 0 : "7px"} ${({ $disableRoundingTop }) => $disableRoundingTop ? 0 : "7px"} 0 0;
`;

export const CheckBoxWrapper = styled.div`
  display: flex;
  margin: 0 11px 0 16px;
`;

export const Name = styled.div`
  min-width: 220px;
  padding-left: 8px;
  position: relative;
`;

export const SelectAll = styled(SquareFill)`
  width: 100%;
  max-width: 16px;
  margin: 0 16px;
  cursor: pointer;
`;

export const Dimensions = styled.div`
  min-width: 250px;
  width: 100%;
  display: flex;
  position: relative;
  margin-right: auto;
`;

export const Provider = styled.div`
  min-width: 170px;
  position: relative;
  margin-right: auto;
`;

export const UploadedOn = styled.div`
  min-width: 216px;
  padding-right: 12px;
  position: relative;
`;

export const Details = styled.div`
  width: 30px;
`;

export const UploadedOnSort = styled(ChevronExpand)`
  margin-left: 4px;
  cursor: pointer;
`;
