import { styled } from "styled-components";

export const StyledHousePerformance = styled.section`
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid #dfe1e4;

  .house-performance-info {
    color: ${({ theme }) => theme.neutralTextWeak};
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
  }

  > .house-performance-title {
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.neutralText};
    font-weight: 600;
    margin-bottom: 12px;
  }

  > .house-performance-numbers {
    display: flex;
    gap: 24px;
    margin-bottom: 12px;

    > .house-performance-number-container {
      display: flex;
      flex-direction: column;
      gap: 2px;

      > .house-performance-number {
        color: ${({ theme }) => theme.neutralText};
        font-size: 30px;
        line-height: 36px;
        font-weight: 600;

        > .house-performance-curr {
          color: ${({ theme }) => theme.neutralText};
          font-size: 14px;
          line-height: 16px;
          font-weight: 400;
        }
      }
    }
  }

  > .house-performace-footer {
    display: flex;
    gap: 28px;
    > .house-performance-adderss {
      font-size: 16px;
      line-height: 24px;
      color: ${({ theme }) => theme.neutralText};
      font-weight: 600;
    }

    > .house-performance-price {
      > p {
        font-size: 14px;
        line-height: 20px;
        color: #940000;
        font-weight: 400;
        background-color: #dd2e431f;
        border-radius: 32px;
        padding: 2px 4px;
      }
    }
  }
`;
