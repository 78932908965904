import { styled } from "styled-components";

export const Container = styled.div`
  width: 100%;
  background-color: #9FA4AD14;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-top: 1px dashed #DFE1E4;
`