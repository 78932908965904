import styled from "styled-components";

export const ApisTable = styled.div`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  border-radius: 8px;
`;

export const HeadingWrapper = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.neutralTextWeak};
  height: 44px;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.neutralBorder};
  background-color: #9FA4AD14;
  border-radius: 7px 7px 0 0;
`;

export const APIName = styled.div`
  max-width: 248px;
  width:100%;
  margin: 0 16px;
  position: relative;
`;

export const Dimensions = styled.div`
  max-width: 516px;
  width: 100%;
  margin: 0 16px;
  display: flex;
  position: relative;
`;

export const RequestPerMinutes = styled.div`
  width: 160px;
  padding: 0 16px;
  position: relative;
  margin-left: auto;
`

export const ProviderURL = styled.div`
  width: 160px;
  padding: 0 16px;
  position: relative;
`
export const LastExecution = styled.div`
  width: 160px;
  padding: 0 16px;
  position: relative;
`
export const Status = styled.div`
  min-width: 120px;
  padding: 0 16px;
  position: relative;
`

export const Details = styled.div`
  width: 30px;
`;

