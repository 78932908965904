import React from "react";
import { ProfileSideBarWrapper, TermsOfUse } from "./styles";
import { AboutSection } from "./AboutSection";
import { ContactsSection } from "./ContactsSection";
import { SecuritySection } from "./SecuritySection";
import { NotificationsSection } from "./NotificationsSection";
import { IProfile } from "../../models/Widgets";

type Props = {
  currentProfile: IProfile;
};

export const ProfileSideBar = ({ currentProfile }: Props) => {
  return (
    <ProfileSideBarWrapper>
      <AboutSection currentProfile={currentProfile} />
      <ContactsSection currentProfile={currentProfile} />
      <SecuritySection />
      <NotificationsSection currentProfile={currentProfile} />
      <TermsOfUse>Privacy Policy and Terms of use</TermsOfUse>
    </ProfileSideBarWrapper>
  );
};
