import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllDatasets } from "../../../../store/selectors/datasets";
import { requestGetAllDatasets } from "../../../../store/slices/datasets";
import { Loader } from "../../../../components/Loader";
import { FilesList } from "../../../../components/ResourcesPagesComponents/Files/FilesList";
import { ResourcesNoData } from "../NoData";

import { Plus } from "react-bootstrap-icons";
import { ReactComponent as EmptyIcon } from "../../../../assets/resourcesPagesIcons/NoFile.svg";

interface Props {}

export const Files: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const allDatasets = useSelector(getAllDatasets);

  useEffect(() => {
    dispatch(requestGetAllDatasets({}));
  }, [dispatch]);

  return (
    <>
      {allDatasets.isLoading ? (
        <Loader />
      ) : !allDatasets.items.length ? (
        <ResourcesNoData
          Icon={EmptyIcon}
          title="No file uploaded"
          subTitle={`Feel free to add files by uploading OBJ, GLTF or STL files. Just click the "Add files" button below! `}
          IconButton={Plus}
          name={"Add files"}
          onClick={() => {
            // dispatch(setActiveModal({ id: "uploadFilesModal" }));
          }}
        />
      ) : (
        <FilesList items={allDatasets.items} />
      )}
    </>
  );
};
