import { Icon } from "react-bootstrap-icons"
import { BreadCrumb, BreadcrumbsWrapper } from "./styles"
interface Breadcrumb {
  name: string,
  link: string,
  Icon?: Icon,
  iconSide?: 'left' | 'right',
}
interface Props {
  breadcrumbs: Breadcrumb[],
}

export const BreadCrumbs: React.FC<Props> = ({ breadcrumbs }) => {
  return (
    <BreadcrumbsWrapper>
      {breadcrumbs.map(({ name, link, Icon, iconSide = 'left' }, i) => {
        return (
          <>
            {i !== 0 ? '/' : ''}
            <BreadCrumb to={link}>
              {Icon && iconSide === 'left'
                ? <Icon />
                : ''
              }
              {name}
              {Icon && iconSide === 'right'
                ? <Icon />
                : ''
              }
            </BreadCrumb>
          </>
        )
      })}
    </BreadcrumbsWrapper>
  )
}