import React from "react";
import {
  EssentialsBlock,
  EssentialsWrapper,
  FullNameBlock,
  FullNameBlockWrapper,
  SubTitleText,
  TitleText,
  TitleWrapper,
  SlashButton,
} from "../styles";
import { FormikErrors, FormikTouched } from "formik";
import { ArrowRight } from "react-bootstrap-icons";
import { Slash } from "../../Share/styles";
import { ChangePasswordDTO } from "../../../../models/Authentication";
import { FormGroup } from "../../../../pages/Auth/FormGroup";

type SecurityProps = {
  values: ChangePasswordDTO;
  setValues: React.Dispatch<React.SetStateAction<ChangePasswordDTO>>;
  errors: {
    [key: string]:
      | string
      | FormikErrors<any>
      | string[]
      | FormikErrors<any>[]
      | undefined;
  };
  touched: {
    [key: string]:
      | boolean
      | FormikTouched<any>
      | FormikTouched<any>[]
      | undefined;
  };
  handleBlur: React.ChangeEventHandler;
  changePass: boolean;
};

export const Security: React.FC<SecurityProps> = ({
  values,
  setValues,
  errors,
  touched,
  handleBlur,
  changePass,
}) => {
  const message = !changePass ? undefined : "Current password is incorect";
  return (
    <EssentialsBlock>
      <EssentialsWrapper>
        <FullNameBlockWrapper>
          <TitleWrapper>
            <TitleText>Change your password</TitleText>
            <SubTitleText>
              When you change your password, we keep you logged in to this
              device but may log you out from your other devices.
            </SubTitleText>
          </TitleWrapper>
          <FullNameBlock>
            <FormGroup
              id="currentPassword"
              name="currentPassword"
              type="password"
              placeholder="Current Password"
              errors={message}
              values={values.currentPassword}
              handleChange={(e) =>
                setValues({ ...values, currentPassword: e.target.value })
              }
              touched={touched.currentPassword as boolean}
              handleBlur={handleBlur}
              width="100%"
              setValues={() => setValues({ ...values, currentPassword: "" })}
            />
            <FormGroup
              id="newPassword"
              name="newPassword"
              type="password"
              placeholder="Enter New Password"
              errors={errors.newPassword as string}
              values={values.newPassword}
              handleChange={(e) =>
                setValues({ ...values, newPassword: e.target.value })
              }
              touched={touched.newPassword as boolean}
              handleBlur={handleBlur}
              width="100%"
              setValues={() => setValues({ ...values, newPassword: "" })}
            />
          </FullNameBlock>
          <TitleWrapper $mt={"16px"}>
            <TitleText>Two-factor verification</TitleText>
            <SubTitleText>
              Keep your account extra secure with a second login step.
            </SubTitleText>
            <SlashButton>
              <Slash />
              Manage two-step verification
              <ArrowRight />
              <Slash />
            </SlashButton>
          </TitleWrapper>
        </FullNameBlockWrapper>
      </EssentialsWrapper>
    </EssentialsBlock>
  );
};
