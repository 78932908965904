import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GearWideConnected,
  Person,
  BoxArrowRight,
} from "react-bootstrap-icons";

import {
  Account,
  AccountAvatar,
  AccountBlock,
  AccountDetailsBlock,
  AccountEmail,
  AccountFullName,
  AccountWrapper,
  DropDownIcon,
  Theme,
  ThemeBlock,
  ThemeLabel,
  UserDetailsWrapper,
  UserMenu,
  UserMenuWrapper,
  UserName,
  UserRole,
} from "./styles";
import { signOut } from "../../store/slices/auth";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import { useNavigate } from "react-router-dom";
import { getTheme } from "../../store/selectors/main";
import { setTheme } from "../../store/slices/appConfig";
import { getProfiles } from "../../store/selectors/profiles";
import { IProfile } from "../../models/Widgets";

export const UserDetails: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef<any>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [account, setAccount] = useState<IProfile>();
  const currentTheme = useSelector(getTheme);
  const [theme, setLocalTheme] = useState<string | undefined>(currentTheme);
  const darkModeQuery = window.matchMedia("(prefers-color-scheme: dark)");
  const currentProfile = useSelector(getProfiles);

  useOutsideClick(menuRef, () => {
    setMenuOpen(false);
  });
  useEffect(() => {
    setAccount(currentProfile);
  }, [currentProfile]);

  const fullName = useMemo(() => {
    const name = [account?.firstName, account?.lastName]?.join(" ")?.trim();
    if (name?.length) {
      return name;
    }
    return "Robert Robertson";
  }, [account?.firstName, account?.lastName]);

  const initials = useMemo(() => {
    let initials = account?.email?.charAt(0);
    if (account?.firstName?.length || account?.lastName?.length) {
      initials =
        (account?.firstName?.charAt(0) || "") +
        (account?.lastName?.charAt(0) || "");
    }
    return initials?.toUpperCase();
  }, [account?.email, account?.firstName, account?.lastName]);

  const setCurentTheme = (localTheme: string) => {
    setLocalTheme(localTheme);
    let theme = localTheme;
    if (localTheme === "system") {
      theme = darkModeQuery.matches ? "dark" : "light";
    }
    dispatch(setTheme(theme === "dark" ? "dark" : "light"));
  };

  return (
    <UserMenuWrapper ref={menuRef}>
      <AccountAvatar>{initials}</AccountAvatar>
      <UserDetailsWrapper>
        <UserName onClick={() => setMenuOpen(!menuOpen)}>
          {fullName}
          <DropDownIcon $open={menuOpen} />
        </UserName>
        {!!account?.isAdmin && <UserRole>Admin</UserRole>}
      </UserDetailsWrapper>
      {menuOpen && (
        <UserMenu $open={menuOpen}>
          <ul>
            <li>
              <AccountWrapper>
                <Account>Account</Account>
                <AccountBlock>
                  <AccountAvatar>{initials}</AccountAvatar>
                  <AccountDetailsBlock>
                    <AccountFullName>{fullName}</AccountFullName>
                    <AccountEmail>{account?.email}</AccountEmail>
                  </AccountDetailsBlock>
                </AccountBlock>
              </AccountWrapper>
            </li>
            <li>
              <AccountWrapper>
                <Theme>Theme</Theme>
                <ThemeBlock>
                  <ThemeLabel
                    $selected={theme === "light"}
                    onClick={() => {
                      setCurentTheme("light");
                    }}
                  >
                    Light
                  </ThemeLabel>
                  <ThemeLabel
                    $selected={theme === "dark"}
                    onClick={() => {
                      setCurentTheme("dark");
                    }}
                  >
                    Dark
                  </ThemeLabel>
                  <ThemeLabel
                    $selected={theme === "system"}
                    onClick={() => {
                      setCurentTheme("system");
                    }}
                  >
                    System
                  </ThemeLabel>
                </ThemeBlock>
              </AccountWrapper>
            </li>
            <li
              onClick={() => {
                navigate("/profile");
                setMenuOpen(false);
              }}
            >
              <Person />
              Profile
            </li>
            <li
              onClick={() => {
                navigate("/settings");
                setMenuOpen(false);
              }}
            >
              <GearWideConnected />
              Settings
            </li>
            <li
              onClick={() => {
                dispatch(signOut());
              }}
            >
              <BoxArrowRight />
              Sign Out
            </li>
          </ul>
        </UserMenu>
      )}
    </UserMenuWrapper>
  );
};
