import React from "react";
import {
  ProfileSideBarAboutCardDescription,
  ProfileSideBarAboutCardDetailsWrapper,
  ProfileSideBarAboutCardIcon,
  ProfileSideBarAboutCardTitle,
  ProfileSideBarAboutCardWrapper,
  ProfileSideBarHeading,
  ProfileSideBarSectionWrapper,
} from "./styles";
import { Envelope } from "react-bootstrap-icons";
import { IProfile } from "../../models/Widgets";

type Props = {
  currentProfile: IProfile;
};
export const ContactsSection = ({ currentProfile }: Props) => {
  return (
    <ProfileSideBarSectionWrapper>
      <ProfileSideBarHeading>Contacts</ProfileSideBarHeading>
      <ProfileSideBarAboutCardWrapper>
        <ProfileSideBarAboutCardIcon>
          <Envelope />
        </ProfileSideBarAboutCardIcon>
        <ProfileSideBarAboutCardDetailsWrapper>
          <ProfileSideBarAboutCardTitle>Email</ProfileSideBarAboutCardTitle>
          <ProfileSideBarAboutCardDescription>
            {currentProfile.email}
          </ProfileSideBarAboutCardDescription>
        </ProfileSideBarAboutCardDetailsWrapper>
      </ProfileSideBarAboutCardWrapper>
    </ProfileSideBarSectionWrapper>
  );
};
