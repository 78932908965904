import React, { useEffect, useRef, useState } from "react";
import { ArrowLeft } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import {
  DatasetPreviewPageContent,
  DatasetPreviewPageHeader,
  DatasetPreviewPageHeaderTitle,
  DatasetPreviewPageHeaderWrapper,
  DatasetPreviewPageWrapper,
  TemporarBackButton,
} from "./styles";
import {
  getAllDatasets,
  getDatasetContents,
  getDatasetLoading,
  getSelectedDataset,
  getSelectedFileView,
} from "../../../../../../store/selectors/datasets";
import {
  requestGetAllDatasets,
  requestGetDatasetContents,
  setDatasetPreview,
} from "../../../../../../store/slices/datasets";
import {
  DatasetOverviewLoaderWrapper,
  HeadingIcon,
  Primary,
  HeadingText,
  Icon,
  Table,
  TableHeading,
  StarIcon,
  HeaderRow,
  TableRowDataSet,
  TableRowCell,
  ButtonsWrapper,
  More,
  TablesWrapper,
} from "../../../../../../components/Modals/DatasetsOverview/styles";
import { Loader } from "../../../../../../components/Loader";
import { getIconSrc } from "../../../../../../helpers/getIconType";
import { Button } from "../../../../../../components/Button";
import { ButtonWrapper } from "../../../../../../components/Modals/UploadedFilePreview/styles";
import { DimensionSettingsInterface } from "../../../../../../models/Files";
import { handleColumnClick } from "../../../../../../helpers/handleColumnClick";
import { DimensionSettings } from "../../../../../../components/DimensionSettings";
import { useOutsideClick } from "../../../../../../hooks/useOutsideClick";
import {
  sortTableAscending,
  sortTableDescending,
} from "../../../../../../components/DimensionSettings/utils/tableActions";

interface Props {
  disableHeader?: boolean;
}

export interface ILastAction {
  key?: string;
  subtype?: string;
  ascending?: boolean;
  descending?: boolean;
  pined?: boolean;
}

export const DatasetPreviewPage: React.FC<Props> = ({ disableHeader }) => {
  const ref = useRef(null);
  const { id } = useParams();
  const dispatch = useDispatch();
  const rowsRef = useRef<Array<HTMLDivElement | null>>([]);

  const selectedDataset = useSelector(getSelectedDataset);
  const datasetContents = useSelector(getDatasetContents);
  const fileSelected = useSelector(getSelectedFileView);
  const allDatasets = useSelector(getAllDatasets);
  const isLoading = useSelector(getDatasetLoading);
  const { pathname } = useLocation();

  const [pinedColumns, setPinedColumns] = useState<string[]>([]);
  const [maxRowHeight, setMaxRowHeight] = useState(0);
  const [lastAction, setLastAction] = useState<ILastAction>({});
  const [limit, setLimit] = useState(30);
  const [showDimensionSettings, setShowDimensionSettings] =
    useState<DimensionSettingsInterface | null>(null);
  const [selectedDatasetContents, setSelectedDatasetContents] = useState<any>(
    []
  );

  const headers = selectedDataset?.fields;

  const getDatasetContentsR = (plus?: number) => {
    const count = datasetContents?.count;
    const plusLimit = limit + (plus || 0);
    const newLimit = plusLimit > count ? count : plusLimit;
    dispatch(
      requestGetDatasetContents({
        id: selectedDataset?.id || selectedDataset?.sourceId || "",
        limit: newLimit,
        skip: 0,
      })
    );
    setLimit(newLimit);
  };

  useEffect(() => {
    if (!datasetContents) return;

    const newData = datasetContents?.items?.map(
      (contentItem: any) => contentItem
    );

    if (!lastAction || !selectedDatasetContents) {
      return setSelectedDatasetContents(newData);
    }

    if (lastAction.ascending) {
      sortTableAscending({
        key: lastAction.key!,
        subtype: lastAction.subtype!,
        selectedDatasetContents,
        setSelectedDatasetContents,
        data: newData,
      });
    } else if (lastAction.descending) {
      sortTableDescending({
        key: lastAction.key!,
        subtype: lastAction.subtype!,
        selectedDatasetContents,
        setSelectedDatasetContents,
        data: newData,
      });
    } else {
      setSelectedDatasetContents(newData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datasetContents]);

  useEffect(() => {
    const heights = rowsRef.current.map((row) => row?.offsetHeight || 0);
    setMaxRowHeight(Math.max(...heights));
  }, [selectedDatasetContents, headers, pinedColumns]);

  useEffect(() => {
    dispatch(requestGetAllDatasets({}));
  }, [dispatch]);

  useEffect(() => {
    if (!selectedDataset?.id && allDatasets?.items?.length && id) {
      const dataset = allDatasets.items?.find((el) => el.id === id);
      dispatch(setDatasetPreview(dataset));
    }
  }, [selectedDataset, allDatasets, id, dispatch]);

  useEffect(() => {
    if (selectedDataset && (selectedDataset.id || selectedDataset.sourceId))
      getDatasetContentsR();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedDataset]);

  useOutsideClick(ref, () => {
    setShowDimensionSettings(null);
  });

  return (
    <DatasetPreviewPageWrapper ref={ref}>
      {!disableHeader && (
        <DatasetPreviewPageHeader>
          <TemporarBackButton
            to={
              pathname.includes("public-resources")
                ? "/resources/public-resources"
                : "/resources/datasets"
            }
          >
            <ArrowLeft />
            {pathname.includes("public-resources")
              ? "Public Resources"
              : "My Datasets"}
          </TemporarBackButton>
          <DatasetPreviewPageHeaderWrapper>
            <DatasetPreviewPageHeaderTitle>
              {selectedDataset.name || "-"}
            </DatasetPreviewPageHeaderTitle>
          </DatasetPreviewPageHeaderWrapper>
        </DatasetPreviewPageHeader>
      )}

      {/* key: previewTable - Resources Preview Dataset */}
      <DatasetPreviewPageContent>
        {isLoading ? (
          <DatasetOverviewLoaderWrapper>
            <Loader />
          </DatasetOverviewLoaderWrapper>
        ) : (
          <TablesWrapper>
            {!!pinedColumns.length && (
              <Table $pined={true}>
                {
                  <HeaderRow>
                    {headers
                      ?.filter((item) => pinedColumns.includes(item.key))
                      .map((datasetItem: any) => (
                        <TableHeading>
                          <HeadingIcon>
                            <Icon
                              src={getIconSrc({
                                type: datasetItem.type,
                                subtype: datasetItem.subtype,
                              })}
                            />
                          </HeadingIcon>
                          <HeadingText>
                            {datasetItem.label?.charAt(0)?.toUpperCase()! +
                              datasetItem.label?.slice(1)}
                          </HeadingText>
                          <ButtonsWrapper>
                            <More
                              onClick={(e) => {
                                setShowDimensionSettings(
                                  handleColumnClick({
                                    header: datasetItem.label,
                                    event: e,
                                    key: datasetItem.key,
                                    errorsDetected: false,
                                    suggestedType: datasetItem.type,
                                    subtype: datasetItem.subtype,
                                  })
                                );
                              }}
                            />
                          </ButtonsWrapper>
                        </TableHeading>
                      ))}
                  </HeaderRow>
                }
                {selectedDatasetContents?.map((item: any, idx: number) => {
                  return (
                    <TableRowDataSet
                      key={idx}
                      ref={(el) => (rowsRef.current[idx] = el)}
                      style={{
                        height: maxRowHeight ? `${maxRowHeight}px` : "auto",
                      }}
                    >
                      {headers
                        ?.filter((item) => pinedColumns.includes(item.key))
                        .map((header) => (
                          <TableRowCell>
                            {item[header.key] || "No data"}
                          </TableRowCell>
                        ))}
                    </TableRowDataSet>
                  );
                })}
              </Table>
            )}
            <Table>
              {
                <HeaderRow>
                  {headers
                    ?.filter((item) => !pinedColumns.includes(item.key))
                    .map((datasetItem: any, idx) => (
                      <TableHeading>
                        {idx === 2 && (
                          <Primary>
                            <StarIcon></StarIcon>
                          </Primary>
                        )}
                        <HeadingIcon>
                          <Icon
                            src={getIconSrc({
                              type: datasetItem.type,
                              subtype: datasetItem.subtype,
                            })}
                          />
                        </HeadingIcon>
                        <HeadingText>
                          {datasetItem.label?.charAt(0)?.toUpperCase()! +
                            datasetItem.label?.slice(1)}
                        </HeadingText>
                        <ButtonsWrapper>
                          <More
                            onClick={(e) => {
                              setShowDimensionSettings(
                                handleColumnClick({
                                  header: datasetItem.label,
                                  event: e,
                                  key: datasetItem.key,
                                  errorsDetected: false,
                                  suggestedType: datasetItem.type,
                                  subtype: datasetItem.subtype,
                                })
                              );
                            }}
                          />
                        </ButtonsWrapper>
                      </TableHeading>
                    ))}
                </HeaderRow>
              }
              {selectedDatasetContents?.map((item: any, idx: number) => {
                return (
                  <TableRowDataSet
                    key={idx}
                    ref={(el) => (rowsRef.current[idx] = el)}
                    style={{
                      height: maxRowHeight ? `${maxRowHeight}px` : "auto",
                    }}
                  >
                    {headers
                      ?.filter((item) => !pinedColumns.includes(item.key))
                      .map((header) => (
                        <TableRowCell>
                          {item[header.key] || "No data"}
                        </TableRowCell>
                      ))}
                  </TableRowDataSet>
                );
              })}
            </Table>
          </TablesWrapper>
        )}
        <ButtonWrapper>
          <Button
            onClick={() => {
              getDatasetContentsR(30);
            }}
            size="small"
            variant="primary"
            name="Load More"
            disabled={datasetContents?.count <= limit}
          />
        </ButtonWrapper>
      </DatasetPreviewPageContent>
      {showDimensionSettings && (
        <div ref={ref}>
          <DimensionSettings
            filePath={fileSelected.path}
            showDimensionSettings={showDimensionSettings}
            lastAction={lastAction}
            pinedColumns={pinedColumns}
            selectedDatasetContents={selectedDatasetContents}
            setShowDimensionSettings={setShowDimensionSettings}
            setLastAction={setLastAction}
            setPinedColumns={setPinedColumns}
            setSelectedDatasetContents={setSelectedDatasetContents}
          />
        </div>
      )}
    </DatasetPreviewPageWrapper>
  );
};
