import { styled } from "styled-components";

export const FFContainer = styled.div`
  padding: 12px 0;
  border-radius: 8px;
  border: 1px solid #dfe1e4;
`;

export const FFTitle = styled.h5`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.neutralText};
  font-weight: 600;
  padding-bottom: 12px;
  padding-left: 16px;
  padding-right: 16px;
  border-bottom: 1px solid #dfe1e4;
`;
export const FFSubtitle = styled.h6`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.neutralText};
  font-weight: 600;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 14px;
  margin-top: 12px;
`;

export const FFContent = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
`;

export const FFLine = styled.hr`
  border: 1px solid #dfe1e4;
    `;
