import * as Yup from "yup";

import { darkTheme } from "../themes/dark";
import { lightTheme } from "../themes/light";

export const Themes = {
  dark: darkTheme,
  light: lightTheme,
};

// --------------------> Validations <--------------------

const getCharacterValidationError = (str: string) => {
  return `Your password must have at least 1 ${str} character`;
};

export const SignInValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("Please enter your Email"),

  password: Yup.string().required("Please enter a password"),
});

export const GetEmailForResetPassValidationSchema = Yup.object().shape({
  email: Yup.string()
    .required("Please enter your Email or UserName")
    .min(1, "Please enter your Email or UserName"),
});

export const ResetPassValidationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required("Please enter your password")
    .min(8, "Password must be at least 8 characters long"),

  confirmNewPassword: Yup.string()
    .required("Please confirm your password")
    .oneOf([Yup.ref("newPassword"), ""], "Passwords must match"),
});

export const SignUpValidationSchema = (selectedOption: string) => {
  return Yup.object().shape({
    teamName:
      selectedOption === "team"
        ? Yup.string()
            .required("Team name is required")
            .min(2, "Too Short!")
            .max(50, "Too Long!")
            .matches(/^[a-zA-Z]+$/, "Team name should only contain letters")
        : Yup.string(),

    firstName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Please enter your First Name")
      .matches(/^[a-zA-Z]+$/, "First Name should only contain letters"),

    lastName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Please enter your Last Name")
      .matches(/^[a-zA-Z]+$/, "Last Name should only contain letters"),

    email: Yup.string()
      .email("Invalid email")
      .required("Please enter your Email"),

    password: Yup.string()
      .required("Please enter a password")
      .min(8, "Password must have at least 8 characters")
      .matches(/[0-9]/, getCharacterValidationError("digit"))
      .matches(/[a-z]/, getCharacterValidationError("lowercase"))
      .matches(/[A-Z]/, getCharacterValidationError("uppercase")),

    confirmPassword: Yup.string()
      .required("Please re-type your password")
      .oneOf([Yup.ref("password")], "Passwords does not match"),

    terms: Yup.boolean()
      .oneOf([true], "You must accept the terms and conditions to sign up")
      .required("You must accept the terms and conditions to sign up"),
  });
};

interface Colors {
  mainColor: string;
  variations: string[];
}
interface ColorPaletteInterface {
  id: string;
  label: string;
  colors: Colors[];
}
export const colorsPalettes: ColorPaletteInterface[] = [
  {
    id: "default",
    label: "Brand (Default)",
    colors: [
      {
        mainColor: "#473DD9",
        variations: [
          "#3C33B5FF",
          "#473DD9",
          "#5448FFFF",
          "#7369FFFF",
          "#938CFFFF",
          "#B5B0FFFF",
          "#D9D3FFFF",
        ],
      },
      {
        mainColor: "#938CFF",
        variations: [
          "#938CFF",
          "#89A3F7FF",
          "#88B9F6FF",
          "#8CCBF1FF",
          "#98DAEBFF",
          "#A8E7E7FF",
          "#ADF4E5FF",
        ],
      },
      {
        mainColor: "#36C4A5",
        variations: [
          "#36C4A5",
          "#238F7D",
          "#2A8A70",
          "#49A588",
          "#6BC3A2",
          "#8FDDBF",
          "#B3E8D8",
        ],
      },
      {
        mainColor: "#ADF4E5",
        variations: [
          "#89E8D4",
          "#ADF4E5",
          "#94FFD3",
          "#A9FFDC",
          "#BEFFE4",
          "#D4FFED",
          "#E9FFF6",
        ],
      },
      {
        mainColor: "#BDBCC8",
        variations: [
          "#BDBCC8",
          "#A09FA8",
          "#91909A",
          "#A6A5AB",
          "#BBB9C1",
          "#D0CFD6",
          "#E5E4EB",
        ],
      },
      {
        mainColor: "#56546D",
        variations: [
          "#56546D",
          "#43425C",
          "#36355E",
          "#48476E",
          "#5B5980",
          "#6F6D8A",
          "#84829A",
        ],
      },
      {
        mainColor: "#FF8C47",
        variations: [
          "#FF8C47",
          "#FFA36CFF",
          "#FFBA91FF",
          "#FFD1B5FF",
          "#FFE8DAFF",
        ],
      },
    ],
  },
  {
    id: "palette1",
    label: "Palette 1",
    colors: [
      {
        mainColor: "#213B34",
        variations: [
          "#659681",
          "#4B7F6C",
          "#347655",
          "#1F6440",
          "#095E2E",
          "#213B34",
          "#0A4D49",
        ],
      },
      {
        mainColor: "#2F584F",
        variations: [
          "#789F92",
          "#649684",
          "#508775",
          "#3D7666",
          "#296657",
          "#2F584F",
          "#194F46",
        ],
      },
      {
        mainColor: "#BFD3C5",
        variations: [
          "#E5F1EC",
          "#D0E9E2",
          "#BBE0D9",
          "#A7D8D0",
          "#92CFCA",
          "#BFD3C5",
          "#9FC8BD",
        ],
      },
      {
        mainColor: "#C6ED62",
        variations: [
          "#F1FFB5",
          "#DEFF8F",
          "#CBFF6A",
          "#B8FF43",
          "#A5FF1F",
          "#C6ED62",
          "#A9FF29",
        ],
      },
      {
        mainColor: "#B59869",
        variations: [
          "#D9C3A1",
          "#C5B98E",
          "#B2AF7A",
          "#9FA667",
          "#8C9D54",
          "#B59869",
          "#948640",
        ],
      },
      {
        mainColor: "#D6CDB6",
        variations: [
          "#F2EAD7",
          "#DFE0C4",
          "#CCE0B2",
          "#B9DFA0",
          "#A7DF8E",
          "#D6CDB6",
          "#B8D094",
        ],
      },
      {
        mainColor: "#EAE3D1",
        variations: [
          "#FFFFFF",
          "#F6F6F6",
          "#ECECEC",
          "#E1E1E1",
          "#D7D7D7",
          "#EAE3D1",
          "#D0CFC0",
        ],
      },
    ],
  },
  {
    id: "palette2",
    label: "Palette 2",
    colors: [
      {
        mainColor: "#F64844",
        variations: [
          "#FFB5B3",
          "#FF9A98",
          "#FF7F7C",
          "#FF6460",
          "#FF4945",
          "#F64844",
          "#FF2E2A",
        ],
      },
      {
        mainColor: "#012938",
        variations: [
          "#3C5E55",
          "#2A5148",
          "#19433A",
          "#07362D",
          "#002920",
          "#012938",
          "#001C2B",
        ],
      },
      {
        mainColor: "#00465E",
        variations: [
          "#3C6673",
          "#2A5966",
          "#194D5A",
          "#073F4D",
          "#004142",
          "#00465E",
          "#00364F",
        ],
      },
      {
        mainColor: "#047474",
        variations: [
          "#4B8C8C",
          "#3A7E7E",
          "#297171",
          "#186464",
          "#075757",
          "#047474",
          "#035757",
        ],
      },
      {
        mainColor: "#01A085",
        variations: [
          "#4BC2B3",
          "#3AAFA0",
          "#299A91",
          "#178584",
          "#067076",
          "#01A085",
          "#007E70",
        ],
      },
      {
        mainColor: "#41CC97",
        variations: [
          "#8EF6CF",
          "#7DE7BE",
          "#6CD8AD",
          "#5BC99C",
          "#4BBB8C",
          "#41CC97",
          "#35B884",
        ],
      },
      {
        mainColor: "#84EEA7",
        variations: [
          "#D4FFDF",
          "#C1FFCE",
          "#AEFFBD",
          "#9DFFAC",
          "#8BFF9B",
          "#84EEA7",
          "#73DB96",
        ],
      },
    ],
  },
  {
    id: "palette3",
    label: "Palette 3",
    colors: [
      {
        mainColor: "#CFAF65",
        variations: [
          "#F7E6B8",
          "#EFDFA7",
          "#E7D996",
          "#DFD285",
          "#D7CB75",
          "#CFAF65",
          "#C79A54",
        ],
      },
      {
        mainColor: "#7B9579",
        variations: [
          "#AFC2A3",
          "#9EB592",
          "#8CAE81",
          "#7BA770",
          "#6A9F60",
          "#7B9579",
          "#68856B",
        ],
      },
      {
        mainColor: "#2E509A",
        variations: [
          "#587CB3",
          "#476E9F",
          "#365F8A",
          "#254F76",
          "#133F61",
          "#2E509A",
          "#1B456F",
        ],
      },
      {
        mainColor: "#F0BEBA",
        variations: [
          "#FFEBE9",
          "#FFDFDB",
          "#FFD2CD",
          "#FFC5BF",
          "#FFB9B1",
          "#F0BEBA",
          "#E5AFAA",
        ],
      },
      {
        mainColor: "#E25828",
        variations: [
          "#FF9260",
          "#FF8050",
          "#FF6F40",
          "#FF5D30",
          "#FF4C21",
          "#E25828",
          "#C8451E",
        ],
      },
      {
        mainColor: "#322F36",
        variations: [
          "#5D5A62",
          "#4C4952",
          "#3B3843",
          "#2B282F",
          "#1A171F",
          "#322F36",
          "#211E28",
        ],
      },
      {
        mainColor: "#B8D7D2",
        variations: [
          "#E7F5F2",
          "#D4EBE6",
          "#C1E1DA",
          "#ADE7CE",
          "#9AEFC2",
          "#B8D7D2",
          "#A3CDC1",
        ],
      },
    ],
  },
  {
    id: "palette4",
    label: "Palette 4",
    colors: [
      {
        mainColor: "#F56600",
        variations: [
          "#FFA645",
          "#FF9632",
          "#FF861F",
          "#FF750C",
          "#FF6500",
          "#F56600",
          "#E35400",
        ],
      },
      {
        mainColor: "#F69A02",
        variations: [
          "#FFD03F",
          "#FFC02F",
          "#FFB01E",
          "#FF9F0E",
          "#FF8E00",
          "#F69A02",
          "#E78900",
        ],
      },
      {
        mainColor: "#1C0F49",
        variations: [
          "#4D3C72",
          "#3B2F61",
          "#2A2251",
          "#191641",
          "#080932",
          "#1C0F49",
          "#0E0840",
        ],
      },
      {
        mainColor: "#222359",
        variations: [
          "#4D4B73",
          "#3C3A63",
          "#2B2953",
          "#1A1843",
          "#090733",
          "#222359",
          "#13104C",
        ],
      },
      {
        mainColor: "#7BA3C9",
        variations: [
          "#A8C9E0",
          "#97B9D5",
          "#86A8CA",
          "#7597BF",
          "#6486B4",
          "#7BA3C9",
          "#688FAF",
        ],
      },
      {
        mainColor: "#01A2A6",
        variations: [
          "#4BCED2",
          "#3ABCC1",
          "#29AAB0",
          "#1898A0",
          "#078690",
          "#01A2A6",
          "#007C80",
        ],
      },
      {
        mainColor: "#E9E8E1",
        variations: [
          "#FFFFFF",
          "#F6F6F6",
          "#ECECEC",
          "#E1E1E1",
          "#D7D7D7",
          "#E9E8E1",
          "#D0CFC0",
        ],
      },
    ],
  },
];

export const LOCAL_STORAGE_KEYS = {
  activeProjects: "active_projects",
  order: "order",
  sort: "sort",
};

export const YAER_FILTER_STORAGE_KEY = 'yearFilteringSelected';
