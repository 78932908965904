import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  CreateAndUpdateWidgetsDTO,
  CreateWidgetsDTO,
  ExploreDataDTO,
  UpdateSingleWidgetDTO,
  UpdateWidgetsDTO,
} from "../../models/Widgets";
import { PageStoriesQuery, WidgetsSuggestionsQuery } from "../../api/widgets";

const initialState = {
  aiSuggestions: null,
  aiKpis: null,
  isLoadingAiSuggestions: false,
};

const widgetsSlice = createSlice({
  name: "widgets",
  initialState,
  reducers: {
    requestCreateWidgets: (
      state,
      action: PayloadAction<CreateWidgetsDTO>
    ) => {},

    requestCreateWidgetSection: (
      state,
      action: PayloadAction<CreateWidgetsDTO>
    ) => {},

    requestWidgetsSuggestions: (
      state,
      action: PayloadAction<WidgetsSuggestionsQuery>
    ) => {
      state.isLoadingAiSuggestions = true;
    },

    requestPageStories: (state, action: PayloadAction<PageStoriesQuery>) => {},
    requestPageBanner: (state, action: PayloadAction<PageStoriesQuery>) => {},

    setStorytellingSuggestions: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        storytellingSuggestions: action.payload,
      };
    },

    setAiSuggestions: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        aiSuggestions: action.payload,
        isLoadingAiSuggestions: false,
      };
    },

    setAiKpis: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        aiKpis: action.payload,
      };
    },

    requestDeleteWidget: (state, action: PayloadAction<string>) => {},
    
    requestUpdateWidgets: (
      state,
      action: PayloadAction<UpdateWidgetsDTO>
    ) => {},

    requestCreateAndUpdateWidgets: (
      state,
      action: PayloadAction<CreateAndUpdateWidgetsDTO>
    ) => {},

    requestUpdateWidget: (
      state,
      action: PayloadAction<UpdateSingleWidgetDTO>
    ) => {},

    requestExploreData: (
      state,
      action: PayloadAction<ExploreDataDTO>
    ) => {},

    setExploreData: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        exploreData: action.payload,
      };
    },

    setAiBannerImage: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        aiBannerImage: action.payload,
      };
    },
  },
});

export const {
  requestWidgetsSuggestions,
  setAiSuggestions,
  setAiKpis,
  setStorytellingSuggestions,
  requestCreateWidgets,
  requestUpdateWidgets,
  requestUpdateWidget,
  requestDeleteWidget,
  requestCreateWidgetSection,
  requestPageStories,
  requestPageBanner,
  requestCreateAndUpdateWidgets,
  requestExploreData,
  setExploreData,
  setAiBannerImage,
} = widgetsSlice.actions;

export default widgetsSlice.reducer;