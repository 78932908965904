import { styled } from "styled-components";

export const PageHeroContainer = styled.div`
    max-width: 720px;
    padding: 16px 10px;
    margin: 0 auto;
`;

export const PageTitle = styled.h1`
    color: ${({ theme }) => theme.neutralTextWeak};
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    margin-bottom: 8px;
    text-align: center;
`;

export const PageDescription = styled.p`
    color: ${({ theme }) => theme.neutralTextWeak};
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 16px;
    text-align: center;
`;
