import { StyledMapContainer } from "./styles";
import { useEffect, useRef, useState } from "react";
import Map, { NavigationControl, MapRef } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { RealEstateMapLegend } from "./components/Legend";
import { Threebox } from "threebox-plugin";
import {
  REAL_ESTATE_LEGEND_IDS_ARR,
  REAL_ESTATE_LEGEND_MAPP_IDS,
} from "./components/Legend/options";
import { HouseSettingsProps } from "../../../../components/Modals/PositionHouseModal/components/HouseSettings";

declare global {
  interface Window {
    tb: any;
  }
}

const mapStyles = {
  DEFAULT: "mapbox://styles/andyk1987/clv3kwhbo00ei01qp815c1w24",
};

export interface IMarker {
  latitude: number;
  longitude: number;
  imgSrc: string;
  price: number;
  streetAddress: string;
}

interface HouseSettings extends Omit<HouseSettingsProps, "zpId" | "address"> {}

interface Props {
  houses: HouseSettings[];
}

const DEFAULT_LONGITUDE = -77.10189;
const DEFAULT_LATITUDE = 38.92582;
const DEFAULT_ZOOM = 15;
const DEFAULT_X = 0;
const DEFAULT_Y = 0;
const DEFAULT_Z = 241;
const DEFAULT_SCALE = 3.2;

export const SearchRealEstateMap = ({ houses }: Props) => {
  const mapRef = useRef<MapRef | null>(null);
  const modelRef = useRef<any>(null);
  const [isMapLoaded, setIsMapLoaded] = useState(false);

  const add3DModel = (
    modelPath: string,
    long = DEFAULT_LONGITUDE,
    lat = DEFAULT_LATITUDE,
    rotateX = DEFAULT_X,
    rotateY = DEFAULT_Y,
    rotateZ = DEFAULT_Z,
    scale = DEFAULT_SCALE
  ) => {
    if (modelRef.current) {
      window.tb.remove(modelRef.current);
      modelRef.current = null;
    }

    const options = {
      // this is a static test model
      obj: modelPath,
      type: "gltf",
      scale: { x: scale, y: scale, z: 2.7 },
      units: "meters",
      rotation: { x: 90, y: -10, z: 0 },
    };

    window.tb.loadObj(options, (model: any) => {
      model.setCoords([long, lat]);
      model.setRotation({ x: rotateX, y: rotateY, z: rotateZ });
      window.tb.add(model);
      modelRef.current = model;
    });
  };

  const onMapLoad = () => {
    if (mapRef.current) {
      const map = mapRef.current.getMap();

      setIsMapLoaded(true);

      map.addLayer({
        id: "custom-threebox-model",
        type: "custom",
        renderingMode: "3d",
        onAdd: function () {
          window.tb = new Threebox(map, map.getCanvas().getContext("webgl"), {
            defaultLights: true,
          });
        },
        render: function () {
          window.tb.update();
        },
      });
    }
  };

  // add 3D models to the map dynamically
  useEffect(() => {
    if (mapRef.current) {
      const map = mapRef.current.getMap();
      // Ensure Threebox is added after the map style is fully loaded
      houses.forEach((house) => {
        map.on("load", () => {
          // Add the 3D model to the map
          add3DModel(
            // TODO: FIX to read the model path from API
            "https://docs.mapbox.com/mapbox-gl-js/assets/metlife-building.gltf",
            house.longitude,
            house.latitude,
            house.rotateX,
            house.rotateY,
            house.rotateZ,
            house.scale
          );
        });
      });

      // Jump to the first house
      if (houses.length > 0) {
        const firstHouse = houses[0];
        map.jumpTo({
          center: [firstHouse.longitude, firstHouse.latitude],
          zoom: DEFAULT_ZOOM,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef.current]);

  const applyFilter = (selectedItems: string[]) => {
    // get all building features
    const allBuildingFeatures =
      mapRef.current
        ?.getMap()
        .querySourceFeatures("composite", { sourceLayer: "building" }) || [];
    const uniqueBuildingTypes = [
      ...new Set(
        allBuildingFeatures
          .map((feature) => feature.properties?.type)
          .filter((type) => type !== undefined)
      ),
    ];

    // types that relates to the filters (update when adding new filters)
    const involvedTypes = [
      "school",
      "college",
      "university",
      "hospital",
      "supermarket",
      "shop",
      "warehouse",
      "commercial",
      "office",
    ];

    // the types that will be visible by default (even with selected filters)
    const defaultBuildingTypes = uniqueBuildingTypes.filter(
      (type) => !involvedTypes.includes(type)
    );

    const buildingTypes: string[] = [...defaultBuildingTypes];

    const poiTypes: string[] = [];

    if (selectedItems.includes(REAL_ESTATE_LEGEND_MAPP_IDS.school)) {
      buildingTypes.push("school", "college", "university");
      poiTypes.push("School", "College");
    }
    if (selectedItems.includes(REAL_ESTATE_LEGEND_MAPP_IDS.hospital)) {
      buildingTypes.push("hospital");
      poiTypes.push("Hospital");
    }
    if (selectedItems.includes(REAL_ESTATE_LEGEND_MAPP_IDS.store)) {
      buildingTypes.push("supermarket", "shop", "warehouse", "commercial");
      poiTypes.push("Supermarket", "Marketplace");
    }
    if (selectedItems.includes(REAL_ESTATE_LEGEND_MAPP_IDS.office)) {
      buildingTypes.push("office");
      poiTypes.push("Office");
    }
    // Continue for other checkboxes...

    const buildingFilter =
      buildingTypes.length > 0
        ? ["match", ["get", "type"], buildingTypes, true, false]
        : null;

    const poiFilter =
      poiTypes.length > 0
        ? ["match", ["get", "type"], poiTypes, true, false]
        : null;

    if (mapRef.current) {
      const map = mapRef.current.getMap();
      // Apply the filter to the building layer
      map.setFilter("building (1)", buildingFilter);
      // Apply the filter to the POI layer
      map.setFilter("poi-label", poiFilter);
    }
  };

  const onSelectionChange = (selectedItems: string[]) => {
    applyFilter(selectedItems);
  };

  return (
    <StyledMapContainer>
      <Map
        ref={mapRef}
        initialViewState={{
          latitude: DEFAULT_LATITUDE,
          longitude: DEFAULT_LONGITUDE,
          zoom: DEFAULT_ZOOM,
          pitch: 37.9,
          bearing: 172.5,
        }}
        mapStyle={mapStyles.DEFAULT}
        styleDiffing
        onLoad={onMapLoad}
        style={{
          display: "flex",
          width: "100%",
          height: "unset",
          borderRadius: "8px",
        }}
        mapboxAccessToken={
          "pk.eyJ1IjoiYW5keWsxOTg3IiwiYSI6ImNqeHJtdGJjNTA5bWwzbW1mcXA0cTZuMmkifQ.qAhmQzT0m6-KuvZt7-C83A"
        }
      >
        <NavigationControl showZoom={true} position="top-right" />
      </Map>
      {isMapLoaded && (
        <RealEstateMapLegend
          defaultSelectedItems={REAL_ESTATE_LEGEND_IDS_ARR}
          onSelectionChange={onSelectionChange}
        />
      )}
    </StyledMapContainer>
  );
};
