import React from "react";
import { ReactComponent as EmptyIcon } from "../../../../../assets/resourcesPagesIcons/NoPublic.svg";
import { Plus } from "react-bootstrap-icons";
import { ResourcesNoData } from "../../../../../pages/Resources/pages/NoData";
import { DatasetsResponseListDto } from "../../../../../models/Datasets";
import ApisList from "./ApisList";

type Props = {
  setLibraryDatasetOverviewOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setDimensionsSelected: React.Dispatch<any>;
  allDatasets: DatasetsResponseListDto;
  libraryDatasetOverviewOpen: boolean;
  libraryDatasetsIds: string[];
  searchInput: string;
};

const APIs = ({
  allDatasets,
  setLibraryDatasetOverviewOpen,
  searchInput,
  setDimensionsSelected,
  libraryDatasetsIds,
  libraryDatasetOverviewOpen,
}: Props) => {
  return (
    <>
      {!allDatasets.items.length && (
        <ResourcesNoData
          Icon={EmptyIcon}
          title="No API connected"
          subTitle={`Use APIs to transform unstructured documents into business insights.`}
          IconButton={Plus}
          name={"Connect API"}
          onClick={() => {}}
        />
      )}

      <ApisList
        setFileOverviewOpen={setLibraryDatasetOverviewOpen}
        searchInput={searchInput}
        setDimensionsSelected={setDimensionsSelected}
        libraryDatasetsIds={libraryDatasetsIds}
        libraryDatasetOverviewOpen={libraryDatasetOverviewOpen}
      />
    </>
  );
};

export default APIs;
