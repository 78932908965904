import React from "react";
import {
  ProfileSideBarAboutCardDescription,
  ProfileSideBarAboutCardDetailsWrapper,
  ProfileSideBarAboutCardIcon,
  ProfileSideBarAboutCardTitle,
  ProfileSideBarAboutCardWrapper,
  ProfileSideBarSectionWrapper,
  ProfileSideBarHeading,
} from "./styles";
import { Briefcase, People, Person, Compass } from "react-bootstrap-icons";
import { IProfile } from "../../models/Widgets";

type Props = {
  currentProfile: IProfile;
};

export const AboutSection = ({ currentProfile }: Props) => {
  const aboutSection = [
    {
      icon: <Person />,
      type: "Full Name",
      data:
        currentProfile.firstName &&
        currentProfile.lastName &&
        `${currentProfile.firstName} ${currentProfile.lastName}`,
    },
    {
      icon: <Briefcase />,
      type: "Job Position",
      data: currentProfile.jobTitle,
    },
    {
      icon: <People />,
      type: "Organization",
      data: currentProfile.organization,
    },
    {
      icon: <Compass />,
      type: "Location",
      data: currentProfile.location,
    },
  ];
  return (
    <ProfileSideBarSectionWrapper>
      <ProfileSideBarHeading>About</ProfileSideBarHeading>
      <ProfileSideBarSectionWrapper>
        {aboutSection.map((section, idx) => (
          <ProfileSideBarAboutCardWrapper key={idx}>
            <ProfileSideBarAboutCardIcon>
              {section.icon}
            </ProfileSideBarAboutCardIcon>
            <ProfileSideBarAboutCardDetailsWrapper>
              <ProfileSideBarAboutCardTitle>
                {section.type}
              </ProfileSideBarAboutCardTitle>
              <ProfileSideBarAboutCardDescription $noData={!section.data}>
                {section.data ? section.data : "No data"}
              </ProfileSideBarAboutCardDescription>
            </ProfileSideBarAboutCardDetailsWrapper>
          </ProfileSideBarAboutCardWrapper>
        ))}
      </ProfileSideBarSectionWrapper>
    </ProfileSideBarSectionWrapper>
  );
};
