import { styled } from "styled-components";
import { ChevronUp } from "react-bootstrap-icons";

export const Container = styled.div`
  width: 100%;
  background-color: #9FA4AD14;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-top: 1px dashed #DFE1E4;
`

export const Info = styled.p`
  color: #5F6877;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
`

export const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
`

export const Text = styled.p`
  color: #2E3849;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`

export const Advanced = styled.div`
  color: #000417;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  display: flex;
  gap: 8px;
  align-items: center;
`

export const AdvancedInfo = styled.p`
  color: #5F6877;
  font-size: 12px;
  font-weight: 400;
  margin-top: -16px;
  line-height: 16px; 
`

export const Arrow = styled(ChevronUp)<{
  $active: boolean;
}>`
  cursor: pointer;
  transition: 0.3s;
  width: 16px;
  height: 16px;
  transform: ${({$active}) => $active ? "rotateZ(180deg)" : "rotateZ(0deg)" } 
`