import styled from "styled-components";
import { CloseIcon } from "../styles";

export const ExploreModalHeader = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  gap: 4px;
`;
export const ExploreModalHeaderTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: ${({ theme }) => theme.neutralText};
`;
export const ExploreModalHeaderSubTitle = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.neutralTextWeak};
`;
export const CloseModal = styled(CloseIcon)`
  position: absolute;
  right: 28px;
  top: 24px;
  width: 20px;
  height: 20px;
`;

export const ExploreModalContent = styled.div`
  padding: 16px 0;
  height: calc(100% - 230px - 72px);
  width: 100%;
  overflow-y: auto;
`;

export const ExploreModalScroll = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  height: max-content;
  gap: 16px;
`;

export const Divider = styled.div`
  background: ${({ theme }) => theme.primaryBorderColor};
  border-radius: 10px;
  width: 100%;
  height: 1px;
`;

export const ExploreModalWidgetWrapper = styled.div`
  padding: 0 16px;
`;

export const ExploreModalMap = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  min-height: 230px;
`;
