import React from "react";
import {
  ProfileSideBarAboutCardDescription,
  ProfileSideBarAboutCardDetailsWrapper,
  ProfileSideBarAboutCardIcon,
  ProfileSideBarAboutCardTitle,
  ProfileSideBarAboutCardWrapper,
  ProfileSideBarHeading,
  ProfileSideBarSectionWrapper,
} from "./styles";
import { Laptop, EnvelopeExclamation } from "react-bootstrap-icons";
import { IProfile } from "../../models/Widgets";
import SwitchComponent from "../Inputs/CustomSwitch/Switch";
import { useDispatch } from "react-redux";
import { setActiveModal } from "../../store/slices/modals";

type DesktopNotifications = keyof IProfile["desktopNotifications"];
type EmailNotifications = keyof IProfile["emailNotifications"];

type Props = {
  currentProfile: IProfile;
};

export const NotificationsSection = ({ currentProfile }: Props) => {
  const dispatch = useDispatch();

  const DesktopNotifications =
    currentProfile.desktopNotifications &&
    Object.keys(currentProfile.desktopNotifications).find(
      (key) =>
        key && currentProfile.desktopNotifications[key as DesktopNotifications]
    );

  const EmailNotifications =
    currentProfile.emailNotifications &&
    Object.keys(currentProfile.emailNotifications).find(
      (key) =>
        key && currentProfile.emailNotifications[key as EmailNotifications]
    );

  const openModal = () => {
    dispatch(setActiveModal({ id: "uploadProfileModal", firstItem: 5 }));
  };

  return (
    <ProfileSideBarSectionWrapper>
      <ProfileSideBarHeading>Notifications</ProfileSideBarHeading>
      <ProfileSideBarAboutCardWrapper>
        <ProfileSideBarAboutCardIcon>
          <EnvelopeExclamation />
        </ProfileSideBarAboutCardIcon>
        <ProfileSideBarAboutCardDetailsWrapper>
          <ProfileSideBarAboutCardTitle>
            Email notifications
          </ProfileSideBarAboutCardTitle>
          <ProfileSideBarAboutCardDescription>
            <SwitchComponent
              onChange={openModal}
              checked={!!EmailNotifications}
              label="Send Notifications by email"
              labelSide="left"
            />
          </ProfileSideBarAboutCardDescription>
        </ProfileSideBarAboutCardDetailsWrapper>
      </ProfileSideBarAboutCardWrapper>

      <ProfileSideBarAboutCardWrapper>
        <ProfileSideBarAboutCardIcon>
          <Laptop />
        </ProfileSideBarAboutCardIcon>
        <ProfileSideBarAboutCardDetailsWrapper>
          <ProfileSideBarAboutCardTitle>
            Desktop notifications
          </ProfileSideBarAboutCardTitle>
          <ProfileSideBarAboutCardDescription>
            <SwitchComponent
              onChange={openModal}
              checked={!!DesktopNotifications}
              label="Send Notifications to my computer"
              labelSide="left"
            />
          </ProfileSideBarAboutCardDescription>
        </ProfileSideBarAboutCardDetailsWrapper>
      </ProfileSideBarAboutCardWrapper>
    </ProfileSideBarSectionWrapper>
  );
};
