import axios from "./axios";
import { AxiosError, AxiosResponse } from "axios";

import { GET_DATA_PROVIDER, GET_DATA_PROVIDER_DATA_CONTENT, GET_DATA_PROVIDERS } from "./constants";

export interface GetDataBody {
  topicId: string
  datasetId: string
  years: string[]
  variables: string[]
  location: string
  rowsToSkip?: number
  numberOfRows?: number
}

export const getDataProviders = async () =>
  axios
    .get(GET_DATA_PROVIDERS)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));

export const getDataProvider = async (id: string) =>
  axios
    .get(GET_DATA_PROVIDER.replace('{id}', id))
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));

export const getDataProviderDatasetContent = async (company: string, body: GetDataBody) =>
  axios
    .post(GET_DATA_PROVIDER_DATA_CONTENT, {requestConfig: body, providerId: company})
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));