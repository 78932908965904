import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { ArrowLeft, CheckLg, Upload, XLg } from "react-bootstrap-icons";

import * as Styled from "./styles";

import { Button } from "../../../../../../components/Button";
// import { Search } from "../../../../../../components/SearchBar";
import {
  setCurrentTopic,
  setSelectedSubTopics,
} from "../../../../../../store/slices/topics";
import { setActiveModal } from "../../../../../../store/slices/modals";
import { TopicSelect } from "../../../../../../components/TopicSelect";
import { useSelector } from "react-redux";
import { getSelectedSubTopics } from "../../../../../../store/selectors/topics";
import { defaultFilters } from "./constants/defaultFilters";
import {
  requestGetAllDatasets,
} from "../../../../../../store/slices/datasets";
import { Search } from "../../../../../../components/SearchBar";
import SwitchComponent from "../../../../../../components/Inputs/CustomSwitch/Switch";
import { FilterDatasetList } from "../../../../../../components/ResourcesPagesComponents/FilterDatasetList/FilterDatasetList";
import { BreadCrumbs } from "../../../../../../components/Breadcrumbs";
import { getDataProvider } from "../../../../../../store/selectors/dataProviders";
import { requestGetDataProvider } from "../../../../../../store/slices/dataProviders";
import { Topic } from "./interfaces/companies.interface";
import { PublicDatasetPreview } from "./PublicDatasetPreview/PublicDatasetPreview";

interface Props { }

export const SearchDataById: React.FC<Props> = () => {
  const { id, datasetId } = useParams();
  const dispatch = useDispatch();
  const selectedSubTopics = useSelector(getSelectedSubTopics);
  const companyData = useSelector(getDataProvider);


  const [filters, setFilters] = useState(defaultFilters);
  const [searchFilters, setSearchFilters] = useState(defaultFilters);
  const [showFilters, setShowFilters] = useState(true);
  const [showDataset, setShowDataset] = useState(datasetId ? true : false);
  const [showDatasetData, setShowDatasetData] = useState(false);

  const handleSelectAll = (topic: Topic) => {
    if (topic?.id) {
      const selected = {
        ...selectedSubTopics,
        [topic?.id]: selectedSubTopics?.[topic?.id]?.length
          ? []
          : topic?.datasets,
      }
      if (selectedSubTopics?.[topic?.id]) {
        delete selected[topic?.id]
      }
      dispatch(
        setSelectedSubTopics(selected)
      );
    }
  };

  useEffect(() => {
    if (!companyData) {
      dispatch(requestGetDataProvider(String(id)))
    }
  }, [companyData, dispatch, id])

  useEffect(() => {
    dispatch(requestGetAllDatasets({}));
  }, [dispatch]);

  useEffect(() => {
    if (selectedSubTopics && !Object.keys(selectedSubTopics).length) {
      setShowDataset(false)
      setFilters(defaultFilters)
      setSearchFilters(defaultFilters)
    }
  }, [selectedSubTopics]);

  useEffect(() => {
    if (datasetId) {
      if (selectedSubTopics && !Object.values(selectedSubTopics).length) {
        const selected = companyData?.topics.find((el) => el.datasets.find((ell) => ell.id === datasetId))
        const selectedDataset = selected?.datasets?.find((el) => el.id === datasetId)
        if (selected?.id && selectedDataset) dispatch(setSelectedSubTopics({ [selected?.id]: [selectedDataset] }))
      }
      setShowDatasetData(true);
    } else {
      setShowDatasetData(false);
    }
  }, [companyData, datasetId, dispatch, selectedSubTopics]);

  const isSelectedSubTopics = useMemo(() => {
    const keys = selectedSubTopics && Object.keys(selectedSubTopics);
    const r = keys?.some((key) => {
      return selectedSubTopics?.[key]?.length;
    });
    if (!r) {
      setFilters(defaultFilters);
    }
    return r;
  }, [selectedSubTopics]);

  const years = useMemo(() => {
    const values: string[] | undefined = [];
    const keys = selectedSubTopics && Object.keys(selectedSubTopics);

    if (
      keys &&
      keys?.length &&
      keys.some((key) => {
        return selectedSubTopics?.[key]?.length;
      })
    ) {
      keys.forEach((key) => {
        const data = selectedSubTopics?.[key];
        data.forEach((dataset) => {
          if (
            dataset?.filters?.find(filter => filter.name === "Years")?.values &&
            Array.isArray(dataset?.filters?.find(filter => filter.name === "Years")?.values)
          ) {
            dataset?.filters?.find(filter => filter.name === "Years")?.values?.forEach((value) => {
              if (values.includes(value)) {
                return;
              }
              values.push(value);
            });
          }
        });
      });
    } else if (companyData) {
      companyData?.topics?.forEach((topic) => {
        topic.datasets.forEach((dataset) => {
          if (
            dataset?.filters?.find(filter => filter.name === "Years")?.values &&
            Array.isArray(dataset?.filters?.find(filter => filter.name === "Years")?.values)
          ) {
            dataset?.filters?.find(filter => filter.name === "Years")?.values?.forEach((value) => {
              if (values.includes(value)) {
                return;
              }
              values.push(value);
            });
          }
        });
      });
    }
    return values;
  }, [companyData, selectedSubTopics]);

  const locations = useMemo(() => {
    const values: string[] | undefined = [];
    const keys = selectedSubTopics && Object.keys(selectedSubTopics);

    if (
      keys &&
      keys?.length &&
      keys.some((key) => {
        return selectedSubTopics?.[key]?.length;
      })
    ) {
      keys.forEach((key) => {
        const data = selectedSubTopics?.[key];
        data.forEach((dataset) => {
          if (
            dataset?.filters?.find(filter => filter.name === "Location")?.values &&
            Array.isArray(dataset?.filters?.find(filter => filter.name === "Location")?.values)
          ) {
            dataset?.filters?.find(filter => filter.name === "Location")?.values?.forEach((value) => {
              if (values.includes(value)) {
                return;
              }
              values.push(value);
            });
          }
        });
      });
    } else if (companyData) {
      companyData?.topics?.forEach((topic) => {
        topic.datasets.forEach((dataset) => {
          if (
            dataset?.filters?.find(filter => filter.name === "Location")?.values &&
            Array.isArray(dataset?.filters?.find(filter => filter.name === "Location")?.values)
          ) {
            dataset?.filters?.find(filter => filter.name === "Location")?.values?.forEach((value) => {
              if (values.includes(value)) {
                return;
              }
              values.push(value);
            });
          }
        });
      });
    }
    return values;
  }, [companyData, selectedSubTopics]);
  const breadCrumbs = useMemo(
    () => [
      {
        name: "Public Datasets",
        link: "/resources/public-resources",
        Icon: ArrowLeft,
      },
      { name: "Providers", link: "/resources/public-resources/search" },
      ...(showDatasetData
        ? [
          {
            name: companyData?.name || "",
            link: "/resources/public-resources/search/" + companyData?.id,
          },
        ]
        : []),
    ],
    [companyData, showDatasetData]
  );

  return (
    <Styled.SearchDataPageByIdWrapper>
      <Styled.SearchDataPageHeaderByIdWrapper>
        <BreadCrumbs breadcrumbs={breadCrumbs} />
        <Styled.HeaderTitleWrapper>
          <Styled.SearchDataPageHeaderByIdTitle>
            {companyData?.name}
          </Styled.SearchDataPageHeaderByIdTitle>
          <Styled.ButtonsSearchData>
            {!showDatasetData && showDataset && (
              <SwitchComponent
                label="Filters"
                checked={showFilters}
                onChange={() => {
                  setShowFilters(!showFilters);
                }}
              />
            )}
            {showDatasetData && (
              <>
                <SwitchComponent
                  label="All datasets"
                  checked={showDataset}
                  onChange={() => {
                    setShowDataset(!showDataset);
                  }}
                />
                <Button
                  onClick={() => {
                    dispatch(setActiveModal({ id: "progressModal" }));
                  }}
                  name="Connect"
                  icon={<CheckLg />}
                  size="medium"
                  variant="primary"
                />
              </>
            )}
          </Styled.ButtonsSearchData>
        </Styled.HeaderTitleWrapper>
      </Styled.SearchDataPageHeaderByIdWrapper>
      <Styled.FullContentFlexWrapper>
        {!showDatasetData && showFilters && (
          <Styled.SearchDataPageByIdContentWrapperFullWidth
            $showDataset={showDataset}
          >
            <Styled.SearchDataPageByIdContentWrapper $showDataset={showDataset}>
              <Styled.ContentHeader>
                <Styled.ContentHeaderTitle>
                  Filter data
                </Styled.ContentHeaderTitle>
                {showDataset && (
                  <Styled.CloseIcon
                    onClick={() => {
                      setShowFilters(false);
                    }}
                  />
                )}
                <Styled.ContentHeaderSubTitle>
                  Select the appropriate filters to reduce the amount of
                  available data, refine the data selection, and speed up the
                  delivery of results.
                </Styled.ContentHeaderSubTitle>
                <Styled.ChipsWrapper>
                  {selectedSubTopics && Object.keys(selectedSubTopics).map((el) => {
                    const details = companyData?.topics.find((topic) => topic.id === el)
                    return <Styled.Chip $selected>
                      {details?.name}
                      <XLg
                        onClick={() => {
                          if (!details) {
                            return;
                          }
                          handleSelectAll(details);
                        }}
                      />
                    </Styled.Chip>
                  })}
                  {filters.locations.map((location) => {
                    return (
                      <Styled.Chip $selected>
                        {location === "US" ? "United States" : location}
                        <XLg
                          onClick={() => {
                            setFilters({
                              ...filters,
                              locations: filters?.locations?.filter(
                                (loc) => loc !== location
                              ),
                            });
                          }}
                        />
                      </Styled.Chip>
                    );
                  })}
                  {filters.years.map((year) => {
                    return (
                      <Styled.Chip $selected>
                        {year}
                        <XLg
                          onClick={() => {
                            setFilters({
                              ...filters,
                              years: filters?.years?.filter((y) => y !== year),
                            });
                          }}
                        />
                      </Styled.Chip>
                    );
                  })}
                  {(filters?.years?.some((el) => el) ||
                    filters?.locations?.some((el) => el)) && (
                      <Button
                        onClick={() => {
                          setShowDataset(false);
                          setFilters(defaultFilters);
                        }}
                        variant="danger"
                        size="small"
                        name="Clear all"
                      />
                    )}
                  {JSON.stringify(filters) !== JSON.stringify(searchFilters) ? (
                    <Button
                      onClick={() => {
                        setShowDataset(true);
                        setSearchFilters(filters)
                      }}
                      variant="primary"
                      size="small"
                      disabled={!filters?.years?.some((el) => el) || !filters?.locations?.some((el) => el)}
                      name={`Show Datasets`}
                    />
                  ) : ""}

                </Styled.ChipsWrapper>
              </Styled.ContentHeader>
              <Styled.ContentBody>
                <Styled.ContentBodyDataWrapper>
                  <Styled.ContentBodyHeader>
                    <Styled.ContentBodyHeaderTitle>
                      Topics
                    </Styled.ContentBodyHeaderTitle>
                    {/* <Search width="small" placeholder="Search" /> */}
                  </Styled.ContentBodyHeader>
                  <Styled.ContentBodyData>
                    <Styled.ContentBodyDataTopicsWrapper>
                      {companyData?.topics?.map((topic) => {
                        if (!topic.datasets.length) {
                          return '';
                        }
                        return (
                          <TopicSelect
                            key={topic.id + topic.name + topic.type}
                            selected={
                              topic?.id
                                ? selectedSubTopics?.[topic?.id]?.length ===
                                  topic?.datasets?.length
                                  ? "checked"
                                  : selectedSubTopics?.[topic?.id]?.length
                                    ? "indeterminate"
                                    : "none"
                                : "none"
                            }
                            onClick={() => {
                              dispatch(setCurrentTopic(topic));
                              dispatch(setActiveModal({ id: "topicModal" }));
                            }}
                            onChange={(e) => {
                              handleSelectAll(topic);
                            }}
                            name={topic?.name}
                            count={selectedSubTopics?.[topic?.id]?.length ? selectedSubTopics?.[topic?.id]?.length : selectedSubTopics?.[topic?.id] ? 1 : undefined}
                          />
                        );
                      })}
                    </Styled.ContentBodyDataTopicsWrapper>
                  </Styled.ContentBodyData>
                </Styled.ContentBodyDataWrapper>
                <Styled.ContentBodyDataWrapper>
                  <Styled.ContentBodyHeader>
                    <Styled.ContentBodyHeaderTitle>
                      Years
                    </Styled.ContentBodyHeaderTitle>
                    {/* <Search width="small" placeholder="Search" /> */}
                  </Styled.ContentBodyHeader>
                  <Styled.ContentBodyData>
                    <Styled.YearChipsWrapper>
                      {years?.map((year) => {
                        if (filters.years.find((y) => y === year)) {
                          return '';
                        }
                        const onClick = () => {
                          if (!isSelectedSubTopics) {
                            return;
                          }
                          const newFiltersYears = filters?.years?.includes(year)
                            ? filters?.years?.filter((y) => y !== year)
                            : [...filters?.years, year];
                          const newFilters = {
                            ...filters,
                            years: newFiltersYears,
                          };
                          setFilters(newFilters);
                        };

                        return (
                          <Styled.YearChip $disabled={Boolean(selectedSubTopics && !Object.keys(selectedSubTopics))} onClick={onClick}>
                            {year}
                          </Styled.YearChip>
                        );
                      })}
                    </Styled.YearChipsWrapper>
                  </Styled.ContentBodyData>
                </Styled.ContentBodyDataWrapper>
                <Styled.ContentBodyDataWrapper>
                  <Styled.ContentBodyHeader>
                    <Styled.ContentBodyHeaderTitle>
                      Location
                    </Styled.ContentBodyHeaderTitle>
                    {/* <Search width="small" placeholder="Search" /> */}
                  </Styled.ContentBodyHeader>
                  <Styled.ContentBodyData>
                    {locations?.map((location) => {
                      if (filters.locations.find((loc) => loc === location)) {
                        return '';
                      }
                      const onChange = () => {
                        if (!isSelectedSubTopics) {
                          return;
                        }
                        const newFiltersLocations =
                          filters?.locations?.includes(location)
                            ? filters?.locations?.filter(
                              (loc) => loc !== location
                            )
                            : [...filters?.locations, location];
                        const newFilters = {
                          ...filters,
                          locations: newFiltersLocations,
                        };
                        setFilters(newFilters);
                      };

                      const isSelected = filters.locations.find(
                        (el) => el === location
                      )
                        ? "checked"
                        : "none";

                      return (
                        <TopicSelect
                          name={location === "US" ? "United States" : location}
                          onChange={onChange}
                          disableIcon
                          selected={isSelected}
                        />
                      );
                    })}
                  </Styled.ContentBodyData>
                </Styled.ContentBodyDataWrapper>
              </Styled.ContentBody>
            </Styled.SearchDataPageByIdContentWrapper>
          </Styled.SearchDataPageByIdContentWrapperFullWidth>
        )}
        <div></div>
        {showDataset ? (
          <Styled.DatasetListWrapper $showDatasetData={showDatasetData}>
            <Styled.DatasetHeader $showDatasetData={showDatasetData}>
              <Styled.DatasetHeaderTitle>Datasets</Styled.DatasetHeaderTitle>
              <Search width="xs" />
              {showDatasetData && (
                <Styled.CloseIcon
                  onClick={() => {
                    setShowDataset(false);
                  }}
                />
              )}
            </Styled.DatasetHeader>
            <FilterDatasetList
              setShowDatasetData={setShowDatasetData}
              showDatasetData={showDatasetData}
              data={Object.values(selectedSubTopics!).map((el) => {
                return el
              }).flat(1)}
              disableBorder
              disableRoundingTop
            />
          </Styled.DatasetListWrapper>
        ) : (
          ""
        )}
        {showDatasetData && selectedSubTopics ? (
          <Styled.DatasetDataPreviewWrapper>
            <PublicDatasetPreview filters={filters} />
          </Styled.DatasetDataPreviewWrapper>
        ) : (
          ""
        )}
      </Styled.FullContentFlexWrapper>
    </Styled.SearchDataPageByIdWrapper>
  );
};
