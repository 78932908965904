import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getAllDatasets } from "../../../../store/selectors/datasets";
import { requestGetAllDatasets } from "../../../../store/slices/datasets";
import { Loader } from "../../../../components/Loader";
import { ApisList } from "../../../../components/ResourcesPagesComponents/Apis/ApisList";
import { ReactComponent as EmptyIcon } from "../../../../assets/resourcesPagesIcons/NoApi.svg";
import { Plus } from "react-bootstrap-icons";
import { ResourcesNoData } from "../NoData";

interface Props {}

export const Apis: React.FC<Props> = () => {
  const allDatasets = useSelector(getAllDatasets);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestGetAllDatasets({}));
  }, [dispatch]);

  return (
    <>
      {allDatasets.isLoading ? (
        <Loader />
      ) : !allDatasets.items.length ? (
        <ResourcesNoData
          Icon={EmptyIcon}
          title="No API connected"
          subTitle={`Use APIs to transform unstructured documents into business insights.`}
          IconButton={Plus}
          name={"Connect API"}
          onClick={() => {}}
        />
      ) : (
        <ApisList items={allDatasets.items} />
      )}
    </>
  );
};
