import styled from "styled-components"

export const TopicSelectWrapper = styled.div<{ $disablePadding?: boolean, $selected: boolean }>`
  cursor: pointer;
  display: flex;
  padding: 6px ${({ $disablePadding }) => $disablePadding ? "2px" : "12px"};
  background: ${({ $selected }) => $selected ? "#6F6FE93D" : "transparent"};
  gap: 8px;
  align-items: center;
  transition: .3s all;
  &:hover{
    background: #6F6FE929;
  }
`

export const TopicButtonWrapper = styled.div`
  cursor: pointer;
  display: flex;
  gap: 8px;
  width: 100%;
  align-items: center;
`

export const TopicSelectTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  width: 100%;
  color: ${({ theme }) => theme.neutralText};
  /* overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */
`

export const CheckBoxWrapper = styled.div`
  display: flex;
  padding: 2.5px;
`

export const Count = styled.div`
  text-align: center;
  min-width: 20px;
  min-height: 20px;
  aspect-ratio: 1/1;
  background: #5D63F6;
  color: #fff;
  border-radius: 100%;
  font-size: 12px;
  line-height: 20px;

`