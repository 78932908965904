interface ISorting {
  key: string;
  subtype: string;
  setSelectedDatasetContents: React.Dispatch<any>;
  selectedDatasetContents: any;
  data?: any
}

interface IPinColumn {
  key: string, 
  pinedColumns: string[], 
  setPinedColumns: React.Dispatch<React.SetStateAction<string[]>>;
}

export const sortTableDescending = ({key, subtype, selectedDatasetContents, setSelectedDatasetContents, data} : ISorting) => {
  setSelectedDatasetContents(() => {
    switch (subtype) {
      case "percentage":
      case "float":
      case "integer":
        return (data || selectedDatasetContents).sort(
          (a: any, b: any) => Number(a[key]) - Number(b[key])
        );
      case "date":
        return (data || selectedDatasetContents).sort((a: any, b: any) => {
          const first = Math.floor(new Date(a[key]).getTime() / 1000);
          const second = Math.floor(new Date(b[key]).getTime() / 1000);
          return first - second;
        });
      default:
        return (data || selectedDatasetContents).sort((a: any, b: any) =>
          a[key].localeCompare(b[key])
        );
    }
  });
};

export const sortTableAscending = ({key, subtype, selectedDatasetContents, setSelectedDatasetContents, data} : ISorting) => {
  setSelectedDatasetContents(() => {
    switch (subtype) {
      case "percentage":
      case "float":
      case "integer":
        return (data || selectedDatasetContents).sort(
          (a: any, b: any) => Number(b[key]) - Number(a[key])
        );
      case "date":
        return (data || selectedDatasetContents).sort((a: any, b: any) => {
          const first = Math.floor(new Date(a[key]).getTime() / 1000);
          const second = Math.floor(new Date(b[key]).getTime() / 1000);
          return second - first;
        });
      default:
        return (data || selectedDatasetContents).sort((a: any, b: any) =>
          b[key].localeCompare(a[key])
        );
    }
  });
};

export const tablePinColumn = ({key, pinedColumns, setPinedColumns} : IPinColumn ) => {
  if (pinedColumns.includes(key)) {
    setPinedColumns(pinedColumns.filter((item) => item !== key));
  } else if (pinedColumns.length < 6) {
    setPinedColumns([...pinedColumns, key]);
  }
};