import React, { useRef, useState } from "react";
import {
  Advanced,
  AdvancedInfo,
  Arrow,
  CheckBoxWrapper,
  Container,
  Text,
  TextArea,
  TextAreaLabel,
} from "./styles";
import { FormConnectAPIGroup } from "../FormGroup";
import { CheckBox } from "../../../../Inputs/CustomCheckbox/CheckBox";
import { Select } from "../../../../Inputs/CustomSelect/Select";
import useAutosizeTextArea from "../../utils/useAutoSizeArea";

type Props = {
  handleChange: any;
  handleBlur: any;
  values: any;
  setFieldValue: any;
};

const JWTBearer = ({
  handleChange,
  handleBlur,
  values,
  setFieldValue,
}: Props) => {
  const [advancedConfig, setAdvancedConfig] = useState<boolean>(false);
  const payloadRef = useRef<HTMLTextAreaElement>(null);
  const JWTHeadersRef = useRef<HTMLTextAreaElement>(null);

  useAutosizeTextArea(payloadRef.current, values.payload);
  useAutosizeTextArea(JWTHeadersRef.current, values.JWT_Headers);

  return (
    <Container>
      <Select
        background="white"
        options={[
          {
            value: "requestHeader",
            option: "Request Header",
          },
        ]}
        value={values.addJWTToken}
        handleChange={(value) => setFieldValue("addJWTToken", value)}
        label="Add JWT token to"
        withOutClose
      />
      <Select
        background="white"
        options={[
          {
            value: "HS256",
            option: "HS256",
          },
        ]}
        value={values.algorithm}
        handleChange={(value) => setFieldValue("algorithm", value)}
        label="Algorithm"
        withOutClose
      />

      <FormConnectAPIGroup
        handleChange={handleChange}
        handleBlur={handleBlur}
        placeholder={"Enter secret"}
        type={"text"}
        name={"secret"}
        values={values.secret}
        label={"Secret"}
        id={"secret"}
      />
      <CheckBoxWrapper>
        <CheckBox
          onChange={(e) =>
            setFieldValue("secretBase64Encoded", e.target.checked)
          }
          checked={values.secretBase64Encoded}
        />
        <Text>Secret Base64 encoded</Text>
      </CheckBoxWrapper>

      <TextAreaLabel>Payload</TextAreaLabel>
      <TextArea
        ref={payloadRef}
        placeholder="Payload"
        value={values.payload}
        onChange={(e) => setFieldValue("payload", e.target.value)}
      ></TextArea>

      <Advanced>
        Advanced configuration
        <Arrow
          $active={advancedConfig}
          onClick={() => setAdvancedConfig((prev) => !prev)}
        />
      </Advanced>

      {advancedConfig && (
        <>
          <AdvancedInfo>
            Postman auto-generates default values for some of these fields
            unless a value is specified.
          </AdvancedInfo>
          <FormConnectAPIGroup
            type={"text"}
            handleBlur={handleBlur}
            id={"requestHeaderPrefix"}
            handleChange={handleChange}
            name={"requestHeaderPrefix"}
            label={"Request header prefix"}
            placeholder={"Enter Header Prefix"}
            values={values.requestHeaderPrefix}
          />
          <TextAreaLabel>JWT headers</TextAreaLabel>
          <TextArea
            ref={JWTHeadersRef}
            placeholder="JWT headers"
            value={values.JWT_Headers}
            onChange={(e) => setFieldValue("JWT_Headers", e.target.value)}
          ></TextArea>
        </>
      )}
    </Container>
  );
};

export default JWTBearer;
