import {ChangeEvent, ReactNode} from "react";
import {CheckboxContainer, CheckboxLabel, HiddenCheckbox, StyledCheckbox, Checkmark} from "./styles";

interface Props {
    checked: boolean;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    children: ReactNode;
    icon?: ReactNode;
}

export const MapCheckBox = ({ checked, onChange, children, icon }: Props) => {
    return (
        <CheckboxContainer checked={checked}>
            <HiddenCheckbox
                checked={checked}
                onChange={onChange}
            />
            <StyledCheckbox checked={checked}>
                {checked && <Checkmark />}
            </StyledCheckbox>
            <CheckboxLabel checked={checked}>{children}</CheckboxLabel> <div style={{marginLeft: 'auto'}}>{icon}</div>
        </CheckboxContainer>
    );
};