import styled from "styled-components";

export const UploadedItemsHeader = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({theme}) => theme.neutralBorder};
  padding: 8px 22px;
  display: flex;

`

export const FileName = styled.p`
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  color: ${({theme}) => theme.neutralWeak};
`

export const FilesContentWrapper = styled.div`
  display: flex;
  width: 100%;
`
