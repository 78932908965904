import { call, put, takeLatest, select } from "redux-saga/effects";
import { ServerResponse } from "../../models";
import { PayloadAction } from "@reduxjs/toolkit";
import { DatasetDTO, UpdateDatasetDTO } from "../../models/Datasets";
import {
  createDataset,
  DatasetQueryContent,
  DatasetsQuery,
  getDatasetContents,
  getDatasets,
  removeDataset,
  updateDatasetById,
} from "../../api/datasets";
import {
  handleRemoveDataset,
  setAllDatasets,
  setDatasetContents,
  setIsLoading,
  setNewDataset,
} from "../slices/datasets";
import { resetActiveModal, setActiveModal } from "../slices/modals";
import { toast } from "react-toastify";
import { isDraftNewProject } from "../selectors/datasets";
import { hasBackOptions } from "../selectors/modals";

export function* handleCreateDataset(action: PayloadAction<DatasetDTO>): any {
  const { response }: ServerResponse = yield call(
    createDataset,
    action.payload
  );

  if (response?.status === 201) {
    const isNewProject = yield select(isDraftNewProject);
    const backOptions = yield select(hasBackOptions);

    yield put(setNewDataset(response.data));
    yield put(resetActiveModal());
    if (!isNewProject && backOptions === 0) {
      yield action.payload.navigate!("/resources/datasets");
    } else {
      yield put(setActiveModal({ id: "datasetLibraryModal" }));
    }
    toast.success("Dataset created");
  }
}

export function* handleGetAllDatasets(
  action: PayloadAction<Partial<DatasetsQuery>>
) {
  const { limit, skip, name, orderBy, callbacks } = action.payload;
  yield put(setIsLoading(true));

  const { response }: ServerResponse = yield call(getDatasets, {
    limit,
    name,
    skip,
    orderBy,
  });
  if (response) {
    yield put(setIsLoading(false));
  }
  if (response?.status === 200) {
    yield put(setAllDatasets(response?.data));
    callbacks?.onSuccess(response?.data);
  }
}

export function* handleGetDatasetContents(
  action: PayloadAction<DatasetQueryContent>
) {
  yield put(setIsLoading(true));
  const { limit, skip, id, callbacks, search } = action.payload;

  const { response }: ServerResponse = yield call(getDatasetContents, {
    limit,
    skip,
    id,
    callbacks,
    search,
  });
  if (response) {
    yield put(setIsLoading(false));
  }
  if (response?.status === 200) {
    callbacks?.onSuccess(response.data);
    yield put(setDatasetContents(response.data));
  }
}

export function* handleRemoveDatasetById(action: PayloadAction<string>) {
  const loading = toast.loading("Please wait...");
  const { response }: ServerResponse = yield call(
    removeDataset,
    action.payload
  );

  toast.update(loading, {
    render: "Removing...",
    type: "warning",
    isLoading: false,
    autoClose: 800,
  });
  if (response?.status === 200) {
    yield put(handleRemoveDataset(action.payload));

    toast.success("Dataset removed successfully", { delay: 500 });
  }
}

export function* handleUpdateDataset({
  payload,
}: PayloadAction<UpdateDatasetDTO>) {
  const loading = toast.loading("Updating dataset...");
  const { id, ...data } = payload;
  const { response }: ServerResponse = yield call(updateDatasetById, {
    id,
    patchData: data,
  });

  toast.update(loading, {
    render: "Updating...",
    type: "info",
    isLoading: false,
    autoClose: 800,
  });

  if (response?.status === 200) {
    payload?.callbacks?.onSuccess();
    toast.success("Dataset updated successfully", { delay: 500 });
  } else {
    toast.error("Failed to update dataset");
  }
}

export function* watchDatasetActions() {
  yield takeLatest("dataSetData/requestCreateDataset", handleCreateDataset);
  yield takeLatest("dataSetData/requestGetAllDatasets", handleGetAllDatasets);
  yield takeLatest(
    "dataSetData/requestGetDatasetContents",
    handleGetDatasetContents
  );
  yield takeLatest("dataSetData/requestDatasetRemove", handleRemoveDatasetById);
  yield takeLatest("dataSetData/requestUpdateDatasetById", handleUpdateDataset);
}
