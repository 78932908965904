import React, { useState } from "react";

import {
  DatasetDimensionsCount,
  DatasetNameSeparator,
  Dimension,
  Dimensions,
  FileName,
  LabelText,
  RowWrapper,
  UploadedOn,
} from "./styles";
import {
  Category,
  DateTime,
  Location,
  Meta,
  MetricValue,
  Text,
} from "../../../../assets";
import { useNavigate, useParams } from "react-router-dom";
import SwitchComponent from "../../../Inputs/CustomSwitch/Switch";
import { setDatasetPreview } from "../../../../store/slices/datasets";
import { useDispatch } from "react-redux";
import { Dataset } from "../../../../pages/Resources/pages/PublicResources/pages/SearchDataById/interfaces/companies.interface";

interface NewDataSetDtoCustom extends Dataset {
  showDatasetData?: boolean
  setShowDatasetData?: React.Dispatch<React.SetStateAction<boolean>>
}

export const ResourceRow = (dataset: NewDataSetDtoCustom) => {
  const { datasetId } = useParams();
  const { id, setShowDatasetData, showDatasetData, title, variables } = dataset;
  const [showAllDimensions, setShowAllDimensions] = useState<boolean>(false);
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const onClick = () => {
    dispatch(setDatasetPreview(dataset));
    setShowDatasetData && setShowDatasetData(true)
    navigate(`${id}`)
  };

  return (
    <RowWrapper $active={datasetId === id} onMouseLeave={() => setShowAllDimensions(false)}>
      <FileName onClick={onClick}>{title}</FileName>
      {!showDatasetData && (
        <>
          <Dimensions>
            {variables?.map((dimension, idx) => {
              const iconSrc = (d: any) => {
                if (d) {
                  switch (true) {
                    case d.dataType === "text":
                      return Text;
                    case d.dataType === "metric":
                      return MetricValue;
                    case d.dataType === "location":
                      return Location;
                    case d.dataType === "datetime":
                      return DateTime;
                    case d.dataType === "categorical":
                      return Category;
                    case d.dataType === "meta":
                      return Meta;
                    default:
                      return Category;
                  }
                }
              };
              return (
                <Dimension key={idx}>
                  <img src={iconSrc(dimension)} alt="dimension icon" />
                  <DatasetNameSeparator />
                  <LabelText>{dimension.title}</LabelText>
                </Dimension>
              );
            })}
            {
              !showAllDimensions ? (
                <DatasetDimensionsCount onClick={() => setShowAllDimensions(true)}>
                  +6
                </DatasetDimensionsCount>
              ) : (
                ""
              )}
          </Dimensions>
          <UploadedOn>
            <SwitchComponent disabled onChange={() => { }} />
          </UploadedOn>
        </>
      )}
    </RowWrapper>
  );
};
