import React, { useState } from "react";
import { useDispatch } from "react-redux";

import {
  FileName,
  Format,
  More,
  Owner,
  ProjectUsed,
  RowWrapper,
  UploadedDate,
  UsedInProjects,
} from "./styles";
import { requestDatasetRemove } from "../../../../store/slices/datasets";
import { NewDatasetDTO } from "../../../../models/Datasets";
import { Submenu } from "../../../Submenu";
import { ReactComponent as OwnerIcon } from "../../../../assets/OwnerIcon.svg";

export const FilesRow: React.FC<NewDatasetDTO> = (dataset: NewDatasetDTO) => {
  const dispatch = useDispatch();
  const [menuOpen, setMenuOpen] = useState(false);

  const date = new Date();

  const formattedDate = date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });

  return (
    <RowWrapper>
      <FileName>{`Shelton Hotel Free 3D print model`}</FileName>
      <Format>{`.obj`}</Format>
      <UsedInProjects>
        <ProjectUsed>3</ProjectUsed>
      </UsedInProjects>
      <UploadedDate>{formattedDate}</UploadedDate>
      <Owner>
        <OwnerIcon /> {`My data`}
      </Owner>

      <More onClick={() => setMenuOpen((prevState) => !prevState)} />
      {menuOpen && (
        <Submenu
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
          datasetItemMenu
          onRemove={() => dispatch(requestDatasetRemove(""))}
          preview
          share
          download
        />
      )}
    </RowWrapper>
  );
};
