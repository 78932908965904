import { ISelect } from "../../../Inputs/CustomSelect/types"
import { ConnectApiValues } from "../types/types"

export const initialValues: ConnectApiValues = {
  url: "",
  authType: "basicAuth",
  username: "",
  password: "",
  token: "",
  addJWTToken: "",
  algorithm: "",
  secret: "",
  secretBase64Encoded: false,
  payload: "{}",
  JWT_Headers: "{}",
  requestHeaderPrefix: "",
  disableRetrying: "",
  realm: "",
  nonce: "",
  qop: "",
  nonceCount: "",
  clientNonce: "",
  opaque: ""
}

export const AuthTypes: ISelect[] = [
  {
    value: "basicAuth",
    option: "Basic Auth"
  },
  {
    value: "noAuth",
    option: "No Auth"
  },
  {
    value: "bearerToken",
    option: "Bearer Token"
  },
  {
    value: "JWTBearer",
    option: "JWT Bearer"
  },
  {
    value: "digestAuth",
    option: "Digest Auth"
  },
]