import { useState } from "react";
import HouseDetails from "../../HouseDetails";
import {
  ModalWrapper,
  ModalOverlay,
  ModalContainer,
  ModalContent,
  ModalHeadingWrapper,
  ModalHeading,
  CloseIconWrap,
  CloseIcon,
  OverviewContent,
  ModalFooterButtons,
  ModalFooterWrapper,
} from "../styles";
import { HouseDetailsStatus, HouseDetailsZipCode } from "./styles";
import { Button } from "../../Button";
import { useSelector } from "react-redux";
import { getPropertyData } from "../../../store/selectors/realEstate";

interface Props {
  onClose: () => void;
}

const HouseDetailsModal = ({ onClose }: Props) => {
  const [isClosing, setIsClosing] = useState(false);
  const propertyData = useSelector(getPropertyData);
  const handleOnClose = () => {
    setIsClosing(true);
  };

  return (
    <ModalOverlay onClick={handleOnClose} $isClosing={isClosing}>
      <ModalWrapper onClick={(e) => e.stopPropagation()} $isClosing={isClosing}>
        <ModalContainer>
          <ModalContent>
            <ModalHeadingWrapper>
              <ModalHeading>
                <div>
                  {propertyData?.address?.streetAddress}
                  <HouseDetailsStatus>Status</HouseDetailsStatus>
                </div>
                <HouseDetailsZipCode>
                  ZIP {propertyData?.address?.zipcode}
                </HouseDetailsZipCode>
              </ModalHeading>
              <CloseIconWrap onClick={handleOnClose}>
                <CloseIcon />
              </CloseIconWrap>
            </ModalHeadingWrapper>
            <OverviewContent>
              <HouseDetails />
            </OverviewContent>
          </ModalContent>
        </ModalContainer>
        <ModalFooterWrapper>
          <Button
            name="Cancel"
            onClick={handleOnClose}
            variant="neutral"
            size="medium"
          />
          <ModalFooterButtons>
            <Button
              name={"Create Dashboard"}
              onClick={() => {}}
              type="submit"
              variant={"secondary"}
              disabled={false}
              size="medium"
            />
          </ModalFooterButtons>
        </ModalFooterWrapper>
      </ModalWrapper>
    </ModalOverlay>
  );
};

export default HouseDetailsModal;
