import { styled } from "styled-components";

export const DataResourcesContainer = styled.div`
  background-color: #fff;
  padding: 19px 0;
  height: 100%;
  display: flex;
    flex-direction: column;
`;

export const DataResourcesHeader = styled.div`
  background-color: #fff;
  padding: 0 20px 20px 20px;
  border-bottom: 1px solid #dfe1e4;
`;

export const DataResourcesItem = styled.div`
  color: #5f6877;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  padding: 8px 20px;
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const DataResourcesSubItem = styled.div<{ active?: boolean }>`
  color: #000417;
  font-size: 14px;
  width: 100%;
  line-height: 20px;
  font-weight: 400;
  display: inline-flex;
  align-items: center;
  padding: 12px 20px;
  background-color: ${({ active }) => (active ? "#6F6FE929" : "transparent")};

  &:hover {
    cursor: pointer; 
  }
  
  &::before {
    content: "▶";
    margin-right: 8px;
    font-size: 11px;
    color: #5f6877;
  }
`;

export const DataResourcesHeading = styled.h4`
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  color: #000417;
`;

export const DataResourcesButtonsContainer = styled.div`
  display: flex;
    justify-content: space-between;
    padding: 20px 20px 0 20px;
    border-top: 1px solid #D3DBE3;
    margin-top: auto;
`;
