import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const BreadcrumbsWrapper = styled.div`
  display: flex;
  gap: 4px;
  color: ${({ theme }) => theme.neutralTextWeak};
  margin-right: auto;
`

export const BreadCrumb = styled(NavLink)`
display: flex;
align-items: center;
gap: 4px;
color: #5D63F6;  
font-size: 14px;
font-weight: 400;
line-height: 20px;
`