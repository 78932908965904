import { IFormatting, IMarkersType } from "../../../../models/Widgets";

export const ScatterPlotDefaultMarkers = (data: any, groupByValue?: string): IMarkersType[] => {
  const markersTypes = [
    "circle",
    "cross",
    "donut",
    "rhombus",
    "square",
    "triangle",
  ];
  const defaultMarkerType = "circle";

  
  if(!data) return [{ key: "default", shape: defaultMarkerType }];
  
  const groupBy = data.groupBy?.[0] || groupByValue;

  if (!groupBy) {
    return [{ key: "default", shape: defaultMarkerType }];
  }

  const keys = data.uniqueValues[groupBy] || [];

  return keys.map(
    (item: string, index: number) =>
      ({
        key: item,
        shape: markersTypes[index] || defaultMarkerType,
      } as IMarkersType)
  );
};

export const getScatterPlotDefaultColors = (data: any, groupByValue?: string): IFormatting[] => {
  
  if(!data) {
    return [{ key: "default", color: "1" }]
  };

  const groupBy = data.groupBy?.[0] || data.arangeBy?.[0] || groupByValue;

  if (!groupBy) {
    return [{ key: "default", color: "1" }];
  }

  const keys = data.uniqueValues[groupBy] || [];
    
  return keys.map((item: string, index: number) => ({
    key: item,
    color: String(index + 1),
  }));
};
