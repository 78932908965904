import styled from "styled-components";

import { CaretDownFill, Image, XLg } from "react-bootstrap-icons";

export const AlignmentOptionsWrapper = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  gap: 4px;
  position: absolute;
  padding: 2px;
  left: 50%;
  top: 18px;
  width: 60px;
  height: 20px;
  background-color: ${({ theme }) => theme.neutralBackgroundBase};
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  border-radius: 4px;
`;

export const UploadIconBlock = styled.div`
  height: 56px;
  width: 56px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.neutralBackgroundActive};
  border-radius: 40px;
  margin-bottom: 8px;
`;

export const UploadIcon = styled(Image)`
  color: ${({ theme }) => theme.neutralTextWeak};
  height: 18px;
  width: 20px;
`;

export const SectionMenuArrowButton = styled(CaretDownFill) <{
  $active: boolean;
}>`
  display: ${({ $active }) => ($active ? "block" : "none")};
  position: absolute;
  top: 8px;
  right: ${({ $active }) => ($active ? "35px" : "8px")};
  cursor: pointer;
  padding: 2px 4px;
  height: 20px;
  width: 20px;
  color: ${({ theme }) => theme.neutralTextWeak};
  border: 1px solid #d3dbe3;
  border-radius: 4px;
  background: ${({ theme }) => theme.neutralBackgroundBase};
`;

export const SectionRemove = styled(XLg)`
  display: none;
  position: absolute;
  top: 14px;
  right: 30px;
  cursor: pointer;
  filter: invert(56%) sepia(23%) saturate(181%) hue-rotate(179deg)
    brightness(89%) contrast(86%);
  color: ${({ theme }) => theme.neutralTextWeak};
`;

export const SectionMenuClose = styled(XLg)`
  display: block;
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  padding: 2px 4px;
  height: 20px;
  width: 20px;
  color: ${({ theme }) => theme.neutralTextWeak};
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  border-radius: 4px;
  background: ${({ theme }) => theme.neutralBackgroundBase};
`;

export const SectionWrapperBlock = styled.div<{
  $draggable?: boolean;
  $isEditMode?: boolean;
}>`
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 24px 32px;
  border: ${({ $draggable, theme }) =>
    `1px ${$draggable ? `dashed ${theme.primaryBorder}` : "solid transparent"
    }`};
  border-radius: 8px;
  &:hover {
    border: ${({ theme }) => `1px solid  ${theme.primaryBorder}`};
    ${SectionRemove} {
      display: block !important;
    }
  }
  ${({ $isEditMode }) => !$isEditMode && ` pointer-events: none; `}
`;

export const SectionWrapper = styled.div`
  width: 1224px;
  height: 481px;
  border: 1px solid transparent;
  border-radius: 8px;
  position: relative;
  background: transparent;
  background-size: cover;

  display: flex;
`;

export const SettingsWrapper = styled.div<{ $hover?: boolean }>`
  display: ${({ $hover }) => ($hover ? "flex" : "none")};
  position: absolute;
  left: 50%;
  top: 14px;
  transform: translateX(-50%);
  gap: 2px;
  align-items: center;
  transition: 300ms all linear;
`;

export const TextAndImageHeadingContent = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  width: calc(100% - 24px);
`;

export const TextAndImageHeading = styled.textarea.attrs(() => ({
  role: "textbox",
  suppressContentEditableWarning: true,
  placeholderTextColor: "#939BA7",
})) <{ $isEditMode: boolean }>`
  width: 100%;
  height: 36px;
  margin-bottom: 24px;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: -0.03em;
  line-height: 36px;
  color: ${({ theme }) => theme.neutralText};
  border: none;
  resize: none;
  overflow: hidden;
  background-color: transparent;
  border-radius: 8px;
  cursor: ${({ $isEditMode }) => ($isEditMode ? "text" : "default")};
  &:hover {
    background-color: ${({ theme, $isEditMode }) =>
    $isEditMode ? theme.neutralBackgroundHover : "none"};
  }
  &:focus {
    background-color: ${({ theme, $isEditMode }) =>
    $isEditMode ? "#5448FF0F" : "none"};
  }

  &:focus-visible {
    outline: none;
    border-radius: 5px;
  }
`;

export const TextAndImageHighlights = styled.textarea.attrs(() => ({
  role: "textbox",
  suppressContentEditableWarning: true,
  placeholderTextColor: "#939BA7",
})) <{ $alignment?: string; $isEditMode: boolean }>`
  padding: 2px;
  width: 100%;
  height: 100px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: #fff;
  letter-spacing: -0.01em;
  color: ${({ theme }) => theme.neutralText};
  border: none;
  resize: none;
  overflow: hidden;
  background-color: transparent;
  border-radius: 8px;
  cursor: ${({ $isEditMode }) => ($isEditMode ? "text" : "default")};
  &:hover {
    background-color: ${({ theme, $isEditMode }) =>
    $isEditMode ? theme.neutralBackgroundHover : "none"};
  }
  &:focus {
    background-color: ${({ theme, $isEditMode }) =>
    $isEditMode ? theme.activeBackground : "none"};
  }

  &:focus-visible {
    outline: none;
    border-radius: 5px;
  }
`;

export const AlignBlock = styled.div<{
  $alignment?: string;
}>`
  display: flex;
  flex-direction: ${({ $alignment }) => $alignment};
  width: 100%;
  gap: 24px;
`;

export const AlignBlockContent = styled.div<{
  $alignment?: string;
}>`
  display: flex;
  flex-direction: ${({ $alignment }) => $alignment};
  width: 100%;
  gap: 24px;
`;

export const SkiBlock = styled.div<{
  $alignment?: string;
}>`
  display: flex;
  width: 100%;
  gap: 24px;
`;

export const SkiItem = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SkiItemTitleBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
`;

export const SkiItemTag = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.neutralTextDisabled};
  margin-right: 2px;
`;

export const SkiItemTitle = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: ${({ theme }) => theme.neutralTextDisabled};
`;

export const SkiItemDescription = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  color: ${({ theme }) => theme.neutralTextWeak};
`;

export const SkiItemDescriptionPoint = styled.div`
  display: flex;
  flex-direction: row;
  height: 9px;
  width: 9px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.neutralTextWeak};
  margin-left: 3px;
  margin-right: 7px;
`;

export const UploadBlockWrapper = styled.div`
  width: 100%;
`;

export const UploadBlock = styled.div<{
  $isCenter?: boolean;
  $imageUrl?: string;
}>`
  display: flex;
  background-color: ${({ theme }) => theme.neutralBackground};
  border: 1px #d3dbe3 solid;
  border-radius: 4px;
  height: ${({ $isCenter }) => ($isCenter ? "193px" : "317px")};
  width: 100%;
  background-image: ${({ $imageUrl }) =>
    $imageUrl ? `url(${$imageUrl})` : ""};
  background-size: cover;
  position: relative;
  &:hover {
    ${AlignmentOptionsWrapper} {
      display: flex;
    }
    ${SectionMenuArrowButton} {
      display: block !important;
    }
  }
`;

export const UploadLabelBlock = styled.label<{
  $isCenter?: boolean;
}>`
  transition: 0.2s;
  background-color: ${({ theme }) => theme.neutralBackground};
  color: ${({ theme }) => theme.neutralTextWeak};
  width: 100%;
  height: 100%;
  border-radius: 4px;
  display: flex;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  padding: ${({ $isCenter }) => ($isCenter ? "30px" : "60px")};
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &:hover {
    background-color: ${({ theme }) => theme.neutralBackgroundHover};
  }

  &:active {
    background-color: ${({ theme }) => theme.neutralBackgroundHover};
  }
`;

export const UploadTitle = styled.div`
  display: flex;
  color: ${({ theme }) => theme.neutralTextWeak};
  line-height: 20px;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
`;

export const UploadDescription = styled.div`
  display: flex;
  color: ${({ theme }) => theme.neutralTextWeak};
  line-height: 16px;
  font-size: 14px;
  font-weight: 400;
  margin-top: 12px;
`;
