import { DefaultTheme } from "styled-components";

export const autoWidth = (size: string | undefined) => {
  switch (size) {
    case "xs":
      return "24px";
    case "small":
      return "32px";
    case "medium":
      return "36px";
    case "large":
      return "38px";
    default:
      return "32px";
  }
};


export const buttonLine = (theme: DefaultTheme, type: string | undefined) => {
  switch (type) {
    case "primary":
      return theme.primaryBorder;
    case "secondary":
      return theme.secondaryBorder;
    case "neutral":
      return theme.neutralBorderMedium;
    case "neutral-color":
      return theme.neutralBorder;
    case "disabled":
      return theme.neutralBorder;
    case "danger":
      return theme.alertBorder;
    default:
      return theme.primaryBorder;
  }
};

export const buttonColors = (theme: DefaultTheme, type: string | undefined) => {
  switch (type) {
    case "primary":
      return theme.primaryButton;
    case "secondary":
      return theme.secondaryButton;
    case "neutral":
      return theme.neutralButton;
    case "neutral-color":
      return theme.neutralButtonColored;
    case "disabled":
      return theme.neutralButton;
    case "danger":
      return theme.alertBackground;
    default:
      return theme.primaryButton;
  }
};

export const buttonHoverColors = (
  theme: DefaultTheme,
  type: string | undefined
) => {
  switch (type) {
    case "primary":
      return theme.primaryBackgroundMedium;
    case "secondary":
      return theme.secondaryButtonHover;
    case "neutral":
      return theme.neutralBackgroundHover;
    case "neutral-color":
      return theme.neutralButtonColoredHover;
    case "disabled":
      return theme.neutralButton;
    case "danger":
      return theme.alertBackgroundMedium;
    default:
      return theme.primaryButton;
  }
};

export const buttonActiveColors = (
  theme: DefaultTheme,
  type: string | undefined
) => {
  switch (type) {
    case "primary":
      return theme.primaryBackgroundActive;
    case "secondary":
      return theme.secondaryButtonActive;
    case "neutral":
      return theme.neutralBackgroundActive;
    case "neutral-color":
      return theme.neutralButtonColoredHover;
    case "disabled":
      return theme.neutralButton;
    case "danger":
      return theme.alertBackgroundMedium;
    default:
      return theme.primaryButton;
  }
};

export const textColor = (theme: DefaultTheme, type: string | undefined) => {
  switch (type) {
    case "primary":
      return theme.primaryText;
    case "secondary":
      return theme.secondaryText;
    case "neutral":
      return theme.neutralText;
    case "disabled":
      return theme.disabledText;
    case "danger":
      return theme.alertText;
    default:
      return theme.primaryAction;
  }
};

export const searchBarWidth = (size: string | undefined) => {
  switch (size) {
    case "xs":
      return "200px";
    case "small":
      return "240px";
    case "medium":
      return "280px";
    case "large":
      return "320px";
    default:
      return "100%";
  }
};
