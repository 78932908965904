import React from "react";
import { Container } from "./styles";
import { FormConnectAPIGroup } from "../FormGroup";

type Props = {
  handleChange: any;
  handleBlur: any;
  values: any;
};

const BearerToken = ({ handleChange, handleBlur, values }: Props) => {
  return (
    <Container>
      <FormConnectAPIGroup
        handleChange={handleChange}
        handleBlur={handleBlur}
        placeholder={"Enter token"}
        type={"token"}
        name={"token"}
        values={values.token}
        label={"Token"}
        id={"token"}
      ></FormConnectAPIGroup>
    </Container>
  );
};

export default BearerToken;
